import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { apiErrorToast } from "../../../assets/Utils/errors";

import NftController from "../../../assets/Controllers/NftController";
import { iNft } from "../../../assets/models/iNft";
import { CheckoutBuy, CheckoutOffer } from "../../../_Web/components/Checkout/Checkout";
import { useSelector } from "react-redux";
import {
  selectNftInCheckout,
  selectNftInOffer
} from "../../../redux/slices/appSlice";

interface ActionsRouterProps {
  nft: iNft;
}

function ActionsRouter({ nft }: ActionsRouterProps) {
  return (
    <Routes>
      <Route path="checkout/offer" element={<CheckoutOffer nft={nft} />} />
      <Route path="checkout/buy" element={<CheckoutBuy nft={nft} />} />
    </Routes>
  );
}

interface Props {
  nftId: string;
}

export default function Actions({ nftId }: Props) {
  const [, setIsLoading] = useState(false);
  const [nft, setNft] = useState<iNft>();
  const nftIdCheckout = useSelector(selectNftInCheckout);
  const nftIdOffer = useSelector(selectNftInOffer);

  const loadNft = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getNft(nftId);
      setNft(data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNft();
  }, []);

  return <>{nft ? <ActionsRouter nft={nft} /> : null}</>;
}
