import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BID_MODE } from "../../../assets/models/iBid";
import { selectUser } from "../../../redux/slices/userSlice";
import { useEffect, useState } from "react";
import OfferController from "../../../assets/Controllers/OfferController";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { apiErrorToast } from "../../../assets/Utils/errors";
import {
  openWallet,
  openWalletPage,
  shakeWallet,
} from "../../../assets/Utils/wallet";
import { selectPendingOrders } from "../../../redux/slices/orderSlice";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { iNft } from "../../../assets/models/iNft";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { DEFAULT_OVERPRICE } from "../../../config/Fees";
import UserController from "../../../assets/Controllers/UserController";
import { WALLET_NAV_ELEMENT } from "../../../assets/TakyonDesignSystem/components/Wallet/WalletNav";
import { iBuyOrder } from "../../../assets/models/iOrder";
import { WalletCheckout } from "./WalletCheckout";
import { useTranslation } from "react-i18next";

import { closeNftCheckout } from "../../../assets/Utils/nftcheckout";
import { closeNftOffer } from "../../../assets/Utils/nftoffer";
import { closeNftPage } from "../../../assets/Utils/nftpage";

interface Props {
  nft: iNft;
}

export function CheckoutBuy({ nft }: Props) {
  const user = useSelector(selectUser);
  const orders = useSelector(selectPendingOrders);

  const [isLoading, setIsLoading] = useState(false);

  const [isPaymentIntent, setIsPaymentIntent] = useState(false);
  const [order, setOrder] = useState<iBuyOrder>();

  const [credits, setCredits] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const order = orders.find((o) => o.nftId === nft._id);
    if (order) {
      setOrder(order);
      setIsPaymentIntent(true);
    }
  }, [orders]);

  const loadCredits = async () => {
    setIsLoading(true);
    try {
      const c = await UserController.getCreditsBalance();
      setCredits(c);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCredits();
  }, [user]);

  return (
    <section
      style={{
        background: `url(${nft.images?.[0]})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      className="h100 w100 d-flex flex-column align-items-center justify-content-center px-3"
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: "rgba(0, 0, 0, 0.7)",
        }}
      ></div>

      <div
        className="px-md-4 d-flex flex-column h100 w100"
        style={{ maxWidth: "668px", zIndex: 2 }}
      >
        <div className="px-4 d-flex flex-column h100 overflow-scroll d-flex flex-column align-items-center justify-content-center">
          <p className="m-0 h1-large regular white-color">
            {t("app.checkout.buy")}
          </p>

          <div className="height-32"></div>

          <div className="w100">
            <div className="height-16"></div>
            <div
              style={{ padding: "12px 24px" }}
              className="white-background rounded"
            >
              <p className="m-0 bodytext-sm medium">
                {t("app.checkout.checkout")}
              </p>
              <div className="height-12"></div>
              <div className="d-flex justify-content-between">
                <p className="m-0 bodytext-sm light">
                  {t("app.checkout.price")}
                </p>
                <p className="m-0 bodytext-sm light">
                  €{(order?.amount ?? nft.currentPrice)!.toFixed(2)}
                </p>
              </div>
              <div className="height-12"></div>
              <div className="d-flex justify-content-between">
                <p className="m-0 bodytext-sm light">
                  {t("app.checkout.servicefee")}
                </p>
                <p className="m-0 bodytext-sm light">
                  €
                  {(
                    (order?.amount ?? nft.currentPrice)! * DEFAULT_OVERPRICE
                  ).toFixed(2)}
                </p>
              </div>
              <div className="height-12"></div>
              {credits > 0 ? (
                <div className="d-flex justify-content-between">
                  <p className="m-0 bodytext-sm light">
                    {t("app.checkout.creditsdiscount")}
                  </p>
                  <p className="m-0 bodytext-sm light">
                    - €
                    {(credits > (order?.amount ?? nft.currentPrice)!
                      ? (order?.amount ?? nft.currentPrice)!
                      : credits
                    ).toFixed(2)}
                  </p>
                </div>
              ) : null}
              <div className="height-12"></div>
              <div style={{ margin: "0" }} className="horizontal-divider"></div>
              <div className="height-12"></div>
              <div className="d-flex justify-content-between">
                <p className="m-0 bodytext-sm medium">
                  {t("app.checkout.checkout")}
                </p>
                <p className="m-0 bodytext-sm medium">
                  €
                  {(
                    order?.amountPlusFees ??
                    nft.currentPrice! * (1 + DEFAULT_OVERPRICE) -
                      Math.min(credits, nft.currentPrice!)
                  ).toFixed(2)}
                </p>
              </div>
              <div className="height-12"></div>
              <p className="m-0 text-center bodytext-xs light dark-grey-color">
                {t("app.checkout.paymentsecure")}
              </p>
            </div>

            <div className="height-40"></div>

            {orders.length && order?.nftId !== nft._id ? (
              <p className="danger-color text-center">
                {t("app.checkout.otherorders")}
              </p>
            ) : (
              <Button
                className="w100"
                loading={isLoading}
                variant={isPaymentIntent ? "secondary" : ""}
                onClick={() => {
                  if (!isPaymentIntent)
                    fireTagManagerEvent("create_payment_intent", {
                      mode: "buy",
                      item_id: nft._id,
                    });

                  setIsPaymentIntent(true);
                }}
                text={
                  isPaymentIntent
                    ? t("app.checkout.proceedwallet")
                    : t("app.checkout.proceed")
                }
              />
            )}
            <div className="height-16"></div>

            <Button
              variant="blank"
              text={t("app.goback")}
              className="white-color w100"
              onClick={() => closeNftCheckout()}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export function CheckoutOffer({ nft }: Props) {
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [offerAmount, setOfferAmount] = useState("");
  const [offerAmountText, setOfferAmountText] = useState("");
  const [offerMode, setOfferMode] = useState(BID_MODE.MONEY);

  const [isOfferSummary, setIsOfferSummary] = useState(false);

  const { t } = useTranslation();

  const makeAnOffer = async () => {
    if (!offerAmount) return;

    setIsLoading(true);
    try {
      const offerDate = new Date(nft.lockDate!);
      offerDate.setHours(offerDate.getHours() - 4);

      const offer = await OfferController.makeOffer({
        nftId: nft._id,
        expireDate: offerDate.toISOString(),
        amount: Number(offerAmount),
        mode: offerMode,
      });

      fireTagManagerEvent("create_offer", {
        item_id: nft._id,
        price: Number(nft.currentPrice ?? -1),
        amount: offer.amount,
      });

      setIsOfferSummary(true);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section
      className="h100 w100 d-flex flex-column align-items-center justify-content-center px-3"
      style={{
        background: `url(${nft.images?.[0]})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: "rgba(0, 0, 0, 0.7)",
        }}
      ></div>

      {!isOfferSummary ? (
        <div
          style={{ zIndex: 2, overflow: "scroll" }}
          className="d-flex flex-column align-items-center py-5"
        >
          <span className="h1-large regular white-color text-center">
            {t("app.checkout.offeramountph")}
          </span>

          <div className="height-32"></div>

          {/* <span className="h4 regular white-color">EUR</span> */}
          <div className="d-flex">
            <Input
              autoFocus
              placeholder={"€    "}
              className="input-blank"
              value={offerAmountText}
              onChange={(e) => {
                const amount = e.target.value.replace("€", "");

                if (isNaN(Number(amount))) {
                  setOfferAmount("");
                  setOfferAmountText("");
                  return;
                }

                setOfferAmount(amount);
                setOfferAmountText("€" + amount);
              }}
            />
          </div>

          <div className="height-20"></div>

          <span className="bodytext-sm white-color medium mb-1">
            {t("app.checkout.reccomendedoffer")} €
            {(
              Math.min(
                Number(nft.currentPrice ?? Infinity),
                Number(nft.marketPrice ?? Infinity),
                Number(nft.originalPrice ?? Infinity)
              ) * 0.8
            ).toFixed(0)}
          </span>
          <span className="bodytext-sm white-color regular">
            {t("app.nft.currentprice")} €{Number(nft.currentPrice).toFixed(0)}
          </span>
          <span className="bodytext-sm white-color regular">
            {t("app.nft.originalprice")} €{Number(nft.originalPrice).toFixed(0)}
          </span>

          <div className="height-60"></div>

          <Button
            loading={isLoading}
            onClick={makeAnOffer}
            disabled={!offerAmount}
            text={t("app.checkout.makeanoffer")}
            style={{ width: "100%", maxWidth: "668px" }}
          />
          <div className="height-16"></div>

          <Button
            variant="blank"
            text={t("app.goback")}
            className="white-color"
            onClick={() => closeNftOffer()}
          />
        </div>
      ) : null}

      {isOfferSummary ? (
        <div
          style={{ zIndex: 2 }}
          className="d-flex flex-column align-items-center"
        >
          <p className="m-0 h1 regular white-color text-center">
            {t("app.checkout.offersuccesstitle")}
          </p>

          <div className="height-12"></div>

          <p className="m-0 mid-grey-color bodytext regular text-center">
            {t("app.checkout.offersuccesstext")}
          </p>

          <div className="height-32"></div>

          <div className="d-flex w100 justify-content-center">
            <Button
              variant="light"
              text={t("app.checkout.explore")}
              onClick={() => {
                closeNftPage();
                closeNftOffer();
                navigate(getLink(LINK_TYPE.APP_EXPLORE));
              }}
              className="w100"
            />
            <Button
              text={t("app.checkout.viewoffers")}
              onClick={() => {
                closeNftPage();
                closeNftOffer();
                shakeWallet();
                openWallet(WALLET_NAV_ELEMENT.offers);
              }}
              className="w100"
            />
          </div>
        </div>
      ) : null}
    </section>
  );
}
