import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  selectPendingOrders,
  setPendingOrders,
} from "../../../redux/slices/orderSlice";
import OrderController from "../../../assets/Controllers/OrderController";
import { useAppDispatch } from "../../../redux/redux";
import { apiErrorToast } from "../../../assets/Utils/errors";
import { secondsToFormat } from "../../../assets/Utils/generic";
import {
  closeWalletPages,
  openWallet,
  openWalletPage,
} from "../../../assets/Utils/wallet";
import getLink, { LINK_TYPE } from "../../../config/Links";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { WalletCheckout } from "../../../_Web/components/Checkout/WalletCheckout";

export default function PendingOrders() {
  const orders = useSelector(selectPendingOrders);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [times, setTimes] = useState<Array<number>>([]);
  const [intervalId, setIntervalId] = useState<any>();

  const [isLoading, setIsLoading] = useState(false);

  const deletePendingOrderClick = async (id: string) => {
    setIsLoading(true);
    try {
      closeWalletPages();
      await OrderController.deletePendingOrder({ orderId: id });
      await OrderController.initPendingOrders();
    } catch (error) {
      apiErrorToast(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    clearInterval(intervalId);

    const tid = setInterval(() => {
      orders.forEach((o, key) => {
        setTimes((times) => {
          const t =
            (new Date(o.expiresAt).getTime() - new Date().getTime()) / 1000;
          times[key] = t;
          return [...times];
        });
      });
    }, 1000);

    setIntervalId(tid);

    return () => {
      clearInterval(tid);
    };
  }, [orders]);

  useEffect(() => {
    const filteredOrders = orders.filter((o) => {
      const t = new Date(o.expiresAt).getTime() - new Date().getTime();
      if (t <= 0) {
        navigate(getLink(LINK_TYPE.APP_EXPLORE));
        closeWalletPages();

        fireTagManagerEvent("timeout_payment_intent", { item_id: o.nftId });
      }
      return t >= 0;
    });
    dispatch(setPendingOrders(filteredOrders));
  }, [times]);

  if (!orders || !orders.length) return null;

  return (
    <>
      {orders.map((order, key) => (
        <div
          // style={{ padding: "12px 40px" }}
          className="d-flex py-2 px-3 py-md-3 px-md-4 justify-content-between align-items-center primary-background"
          key={"order_" + key}
        >
          <div>
            <p className="m-0 bodytext-sm medium">{t("app.order.yourorder")}</p>
            <p className="bodytext-sm regular m-0 only-mobile">
              {t("app.order.timeleft")}: {secondsToFormat(times[key])}
            </p>
          </div>

          <div className="d-flex align-items-center gap-3">
            <p className="bodytext-sm regular m-0 only-desktop">
              {t("app.order.timeleft")}: {secondsToFormat(times[key])}
            </p>

            <Button
              onClick={() => {
                deletePendingOrderClick(order._id);
                closeWalletPages();
                navigate(getLink(LINK_TYPE.APP_EXPLORE));

                fireTagManagerEvent("cancel_payment_intent", {
                  item_id: order.nftId,
                  time: times[key],
                });
              }}
              text={t("app.order.cancel")}
              loading={isLoading}
              variant="secondary"
              size="sm"
            />
            <Button
              onClick={() => {
                openWallet();
                openWalletPage({
                  id: "wallet_page_checkout" + order._nft!._id,
                  content: <WalletCheckout nft={order._nft!} />,
                });

                fireTagManagerEvent("resume_payment_intent", {
                  item_id: order.nftId,
                });
              }}
              text={t("app.order.pay")}
              loading={isLoading}
              size="sm"
              variant="dark"
            />
          </div>
        </div>
      ))}
    </>
  );
}
