import { useEffect, useState } from "react";
import {
  NFT_ACCESS_STATUS,
  NFT_SELL_STATUS,
  iNft,
} from "../../../../models/iNft";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { apiErrorToast } from "../../../../Utils/errors";
import NftController from "../../../../Controllers/NftController";
import { DEFAULT_PAGINATION_SIZE } from "../../../../models/iPagination";
import {
  closeWalletPages,
  openWallet,
  openWalletPage,
} from "../../../../Utils/wallet";
// import NftCard from "../../NftCard/NftCard";
import Button from "../../Button/Button";
import { DEFAULT_FEE_SPLIT } from "../../../../../config/Fees";
import Icon from "../../Icon/Icon";
import { Form } from "react-bootstrap";
import Input from "../../Input/Input";
import { WALLET_NAV_ELEMENT } from "../WalletNav";
import { validate } from "email-validator";
import { RegisterBankAccountPage } from "./WalletBalance";
import NothingHere from "../Components/NothingHere";
import WalletSkeletonLoader from "../Components/WalletSkeletonLoader";
import BookingHeader from "./BookingHeader";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../../Services/tagmanager";

function PutForSaleSuccessPage({ nft }: { nft: iNft }) {
  const { t } = useTranslation();
  return (
    <div className="p-3">
      <BookingHeader nft={nft} />
      <p className="m-0 bodytext regular">
        {t("wallet.bookings.salesuccesstitle")}
      </p>
      <div className="height-4"></div>
      <p className="m-0 bodytext regular dark-grey-color">
        {t("wallet.bookings.salesuccesstext")}
      </p>
      <div className="height-24"></div>
      <Button
        text={t("wallet.bookings.viewtak")}
        onClick={() => {
          closeWalletPages();
          openWallet(WALLET_NAV_ELEMENT.bookings);
        }}
      />
    </div>
  );
}

function ChangePriceSuccessPage({ nft }: { nft: iNft }) {
  const { t } = useTranslation();

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />
      <p className="m-0 bodytext regular">
        {t("wallet.bookings.changepricesuccesstext")}
      </p>
      <div className="height-24"></div>
      <Button
        text={t("wallet.bookings.viewtak")}
        onClick={() => {
          closeWalletPages();
          openWallet(WALLET_NAV_ELEMENT.bookings);
        }}
      />
    </div>
  );
}

function RemoveFromSaleSuccessPage({ nft }: { nft: iNft }) {
  const { t } = useTranslation();

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />
      <p className="m-0 bodytext regular">
        {t("wallet.bookings.removesalesuccesstext")}
      </p>
      <div className="height-24"></div>
      <Button
        text={t("wallet.bookings.viewtak")}
        onClick={() => {
          closeWalletPages();
          openWallet(WALLET_NAV_ELEMENT.bookings);
        }}
      />
    </div>
  );
}

function TransferSuccessPage({ nft }: { nft: iNft }) {
  const { t } = useTranslation();

  return (
    <div className="px-4">
      <BookingHeader nft={nft} />
      <p className="m-0 bodytext regular">
        {t("wallet.bookings.transfersuccesstext")}
      </p>
      <div className="height-24"></div>
      <Button
        text={t("wallet.bookings.viewtak")}
        onClick={() => {
          closeWalletPages();
          openWallet(WALLET_NAV_ELEMENT.bookings);
        }}
      />
    </div>
  );
}

export function WalletBookingPutOnSale({ nft }: { nft: iNft }) {
  const [selected, setSelected] = useState(-1);
  const [mode, setMode] = useState<"suggested" | "manual">("suggested");
  const [price, setPrice] = useState<number | string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [target, setTarget] = useState<string>("manual");

  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const sellModes = [
    {
      name: t("wallet.bookings.careful"),
      discount: 0.5,
      target: "careful",
    },
    {
      name: t("wallet.bookings.reccomended"),
      discount: 0.6,
      target: "reccomended",
    },
    {
      name: t("wallet.bookings.bold"),
      discount: 0.8,
      target: "bold",
    },
  ];

  const currPrice = (nft.marketPrice ?? nft.originalPrice)!;

  const putOnSale = async () => {
    setIsLoading(true);
    try {
      await NftController.putNftForSale({
        nftId: nft._id,
        price: Number(price),
      });

      openWalletPage({
        id: "PutForSaleSuccessPage:" + nft._id,
        content: <PutForSaleSuccessPage nft={nft} />,
        hideGoBack: true,
      });

      closeWalletPages("WalletBookingPutOnSale:" + nft._id);

      fireTagManagerEvent("put_on_sale", {
        item_id: nft._id,
        amount: Number(price),
        originalPrice: nft.originalPrice,
        mode: target,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />

      {!user?.stripeDetailsSubmitted || !user?.stripeChargesEnabled ? (
        <>
          <p className="m-0 bodytext medium">
            {t("wallet.bookings.nobankaccount")}
          </p>
          <div className="height-4"></div>
          <p className="m-0 bodytext regular dark-grey-color">
            {t("wallet.bookings.nobankaccounttext")}
          </p>
          <div className="height-16"></div>
          <Button
            onClick={() =>
              openWalletPage({
                id: "RegisterBankAccountPage",
                content: <RegisterBankAccountPage />,
              })
            }
            text={t("wallet.bookings.nobankaccountaction")}
          />
          <div
            className="horizontal-divider"
            style={{ margin: "24px 0" }}
          ></div>
        </>
      ) : null}

      <div
        style={
          !user?.stripeDetailsSubmitted || !user?.stripeChargesEnabled
            ? {
                opacity: 0.5,
                pointerEvents: "none",
              }
            : {}
        }
      >
        {mode === "suggested" ? (
          <>
            <div className="d-flex flex-column gap-2">
              {nft.marketPrice || nft.originalPrice ? (
                <>
                  {sellModes.map((mode, key) => {
                    const suggestedPrice = currPrice * mode.discount;

                    return (
                      <div
                        key={"mode_" + key}
                        className={`
                          cursor-pointer p-3 
                          rounded w100
                          ${
                            selected === key
                              ? "black-border"
                              : "mid-grey-border"
                          }
                          `}
                        onClick={() => {
                          setSelected(key);
                          setPrice(suggestedPrice);
                          setTarget(mode.target);
                        }}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <Icon
                            icon="circle"
                            type="symbol"
                            fill={selected === key}
                          />
                          <p className="m-0 bodytext medium light-balck-color">
                            {mode.name}
                          </p>
                          <p className="text-end w100 m-0 bodytext-lg medium">
                            €{suggestedPrice.toFixed(2)}
                          </p>
                        </div>
                        <div className="height-16"></div>
                        <div className="d-flex justify-content-between gap-2">
                          <p className="m-0 bodytext-sm regular dark-grey-color text-nowrap">
                            {t("wallet.bookings.price")}
                          </p>
                          <p className="m-0 bodytext-sm regular dark-grey-color">
                            €{suggestedPrice.toFixed(2)}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between gap-2">
                          <p className="m-0 bodytext-sm regular dark-grey-color text-nowrap">
                            {Math.ceil((1 - DEFAULT_FEE_SPLIT.seller) * 100)}%{" "}
                            {t("wallet.bookings.servicefee")}
                          </p>
                          <p className="m-0 bodytext-sm regular dark-grey-color">
                            -€
                            {(
                              suggestedPrice *
                              (1 - DEFAULT_FEE_SPLIT.seller)
                            ).toFixed(2)}
                          </p>
                        </div>
                        <div
                          className="horizontal-divider"
                          style={{ margin: "8px 0" }}
                        ></div>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 bodytext-sm regular">
                            {t("wallet.bookings.totalget")}
                          </p>
                          <p className="m-0 bodytext-sm regular">
                            €
                            {(
                              suggestedPrice * DEFAULT_FEE_SPLIT.seller
                            ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>

            <div className="height-16"></div>

            <div className="d-flex gap-1">
              <p className="bodytext regular m-0 dark-grey-color">
                {t("wallet.bookings.or")}
              </p>
              <p className="bodytext regular m-0">
                <a onClick={() => setMode("manual")}>
                  <u>{t("wallet.bookings.setprice")}</u>
                </a>
              </p>
            </div>
          </>
        ) : null}
        {mode === "manual" ? (
          <>
            <div className="d-flex gap-2">
              <Form.Group className="w100">
                <Form.Label>{t("wallet.bookings.choosenprice")}</Form.Label>
                <Input
                  status={Number(price) > 0 ? "success" : "normal"}
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder={t("wallet.bookings.insertprice")}
                />
              </Form.Group>
              <Form.Group className="w100">
                <Form.Label>{t("wallet.bookings.totalget")}</Form.Label>
                <Input
                  value={(Number(price) * DEFAULT_FEE_SPLIT.seller).toFixed(2)}
                  disabled
                />
                <Form.Text>
                  €{Number(price).toFixed(2)} {t("wallet.bookings.minus")} 10%{" "}
                  {t("wallet.bookings.servicefee")}
                </Form.Text>
              </Form.Group>
            </div>
            <div className="height-16"></div>

            <div className="d-flex gap-1">
              <p className="bodytext regular m-0 dark-grey-color">
                {t("wallet.bookings.or")}
              </p>
              <p className="bodytext regular m-0">
                <a onClick={() => setMode("suggested")}>
                  <u>{t("wallet.bookings.chooseprice")}</u>
                </a>
              </p>
            </div>
          </>
        ) : null}
        <div className="height-16"></div>
        <div className="d-flex justify-content-end">
          <Button
            disabled={Number(price) < 1}
            onClick={() => {
              if (user?.stripeDetailsSubmitted && user?.stripeChargesEnabled)
                putOnSale();
            }}
            loading={isLoading}
            text={t("wallet.bookings.putsale")}
          />
        </div>
        <div className="height-8"></div>
        <p className="m-0 bodytext-xs regular dark-grey-color text-end">
          {t("wallet.bookings.offerdisclaimer")}
        </p>
      </div>
    </div>
  );
}

export function WalletBookingChangePrice({ nft }: { nft: iNft }) {
  const [price, setPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const changePrice = async () => {
    setIsLoading(true);
    try {
      const prevAmount = nft.currentPrice;

      await NftController.changeNftPrice({
        nftId: nft._id,
        price: Number(price),
      });

      openWalletPage({
        id: "ChangePriceSuccessPage:" + nft._id,
        content: <ChangePriceSuccessPage nft={nft} />,
        hideGoBack: true,
      });

      closeWalletPages("WalletBookingChangePrice:" + nft._id);

      fireTagManagerEvent("change_price", {
        item_id: nft._id,
        amount: Number(price),
        originalPrice: nft.originalPrice,
        previousPrice: prevAmount,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />

      <div className="d-flex gap-2">
        <Form.Group className="w100">
          <Form.Label>{t("wallet.bookings.currentprice")}</Form.Label>
          <Input
            type="number"
            value={Number(Number(nft.currentPrice).toFixed(2))}
            disabled
          />
        </Form.Group>
        <Form.Group className="w100">
          <Form.Label>{t("wallet.bookings.newprice")}</Form.Label>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder={t("wallet.bookings.newpriceph")}
          />
        </Form.Group>
      </div>
      <div className="height-20"></div>
      <div className="d-flex flex-column flex-md-row gap-2">
        <Form.Group className="w100">
          <Form.Label>
            {t("wallet.bookings.servicefee")}{" "}
            {((1 - DEFAULT_FEE_SPLIT.seller) * 100).toFixed(0)}%
          </Form.Label>
          <Input
            type="number"
            value={(Number(price) * (1 - DEFAULT_FEE_SPLIT.seller)).toFixed(2)}
            disabled
          />
        </Form.Group>
        <Form.Group className="w100">
          <Form.Label>{t("wallet.bookings.totalget")}</Form.Label>
          <Input
            type="number"
            value={Number(price) * DEFAULT_FEE_SPLIT.seller}
            disabled
          />
        </Form.Group>
      </div>
      <div className="height-20"></div>

      <div className="d-flex justify-content-end">
        <Button
          disabled={!price}
          onClick={changePrice}
          loading={isLoading}
          text={t("wallet.bookings.changeprice")}
        />
      </div>
    </div>
  );
}

export function WalletBookingRemoveFromSale({ nft }: { nft: iNft }) {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const removeFromSale = async () => {
    setIsLoading(true);
    try {
      await NftController.removeNftFromSale({
        nftId: nft._id,
      });

      openWalletPage({
        id: "RemoveFromSaleSuccessPage:" + nft._id,
        content: <RemoveFromSaleSuccessPage nft={nft} />,
        hideGoBack: true,
      });

      closeWalletPages("WalletBookingRemoveFromSale:" + nft._id);

      fireTagManagerEvent("remove_from_sale", {
        item_id: nft._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />

      <p className="m-0 bodytext regular">{t("wallet.bookings.removesure")}</p>

      <div className="height-16"></div>

      <Button
        loading={isLoading}
        text={t("wallet.bookings.removesale")}
        onClick={removeFromSale}
      />
    </div>
  );
}

export function WalletBookingTransfer({ nft }: { nft: iNft }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const transfer = async () => {
    setIsLoading(true);
    try {
      await NftController.transferNft({
        nftId: nft._id,
        email: email,
      });

      openWalletPage({
        id: "TransferSuccessPage:" + nft._id,
        content: <TransferSuccessPage nft={nft} />,
        hideGoBack: true,
      });

      closeWalletPages("WalletBookingTransfer:" + nft._id);

      fireTagManagerEvent("transfer", { item_id: nft._id });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <BookingHeader nft={nft} />

      <p className="m-0 bodytext regular">
        {t("wallet.bookings.transfertitle")}
      </p>

      <div className="height-8"></div>
      <p className="m-0 bodytext regular dark-grey-color">
        {t("wallet.bookings.transfertext")}
      </p>

      <div className="height-16"></div>

      <Form.Group className="w100">
        <Form.Label>{t("wallet.bookings.email")}</Form.Label>
        <Input
          status={validate(email) ? "success" : "normal"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@email.com"
        />
      </Form.Group>

      <div className="height-16"></div>

      <div className="d-flex justify-content-end">
        <Button
          loading={isLoading}
          text={t("wallet.bookings.transfer")}
          disabled={!validate(email)}
          onClick={() => {
            if (validate(email)) transfer();
          }}
        />
      </div>
    </div>
  );
}

export function WalletBookingPageDetails({ nft }: { nft: iNft }) {
  return (
    <section className="w100 h100 d-flex justify-content-center">
      {/* <NftCard
        bg="white-background"
        responsiveHeight
        responsiveWidth
        opened
        locked
        nft={nft}
      /> */}
    </section>
  );
}

function PastBookings() {
  const [nfts, setNfts] = useState<iNft[]>([]);
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(1);
  const [size] = useState(DEFAULT_PAGINATION_SIZE);

  const { t } = useTranslation();

  const loadNfts = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated(
        {
          page,
          size,
          query: JSON.stringify({
            owner: user?.email,
            accessStatus: NFT_ACCESS_STATUS.REDEEMED,
          }),
          sort: JSON.stringify({
            _lockDate: 1,
          }),
        },
        false
      );

      setNfts(data.data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNfts();
  }, [user]);

  return (
    <section className="p-3 pb-5">
      {isLoading ? <WalletSkeletonLoader /> : null}

      {!nfts.length && !isLoading ? (
        <NothingHere
          title={t("wallet.bookings.noresults")}
          subtitle={t("wallet.bookings.noresultstextpast")}
          action={() => {
            closeWalletPages();
          }}
          actionText={t("wallet.bookings.noresultsactionpast")}
        />
      ) : null}

      <div className="d-flex flex-wrap">
        {nfts.map((nft, key) => {
          return <BookingHeader key={"booking_" + key} nft={nft} />;
        })}
      </div>
    </section>
  );
}

export default function WalletBookings() {
  const [nfts, setNfts] = useState<iNft[]>([]);
  const user = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const loadNfts = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated(
        {
          page: 1,
          size: 500,
          query: JSON.stringify({
            owner: user?.email,
            accessStatus: NFT_ACCESS_STATUS.OPEN,
          }),
          sort: JSON.stringify({
            _lockDate: 1,
          }),
        },
        false
      );
      setNfts([...data.data]);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNfts();
  }, [user]);

  return (
    <section className="p-1 p-md-3 pb-5">
      {isLoading ? <WalletSkeletonLoader /> : null}

      <div className="d-flex flex-wrap">
        {!nfts.length && !isLoading ? (
          <NothingHere
            title={t("wallet.bookings.noresults")}
            subtitle={t("wallet.bookings.noresultstext")}
            action={() => {
              openWalletPage({
                id: "PastBookings",
                content: <PastBookings />,
              });

              fireTagManagerEvent("click_view_past");
            }}
            actionText={t("wallet.bookings.noresultsaction")}
          />
        ) : null}

        {!isLoading &&
          nfts.map((nft, key) => {
            return (
              <BookingHeader
                onRefresh={loadNfts}
                showActions
                key={"booking_" + key}
                nft={nft}
                customActions={
                  <>
                    <div className="d-flex gap-2 w100">
                      {nft.sellStatus === NFT_SELL_STATUS.NOT_FOR_SALE ? (
                        <>
                          <Button
                            size="sm"
                            className="w100"
                            variant="secondary"
                            text={t("wallet.bookings.transfer")}
                            onClick={() => {
                              openWalletPage({
                                onClose: () => loadNfts(),
                                id: "WalletBookingTransfer:" + nft._id,
                                content: <WalletBookingTransfer nft={nft} />,
                              });

                              fireTagManagerEvent("click_transfer", {
                                item_id: nft._id,
                              });
                            }}
                          />
                          <Button
                            size="sm"
                            className="w100"
                            text={t("wallet.bookings.putsale")}
                            onClick={() => {
                              openWalletPage({
                                onClose: () => loadNfts(),
                                id: "WalletBookingPutOnSale:" + nft._id,
                                content: <WalletBookingPutOnSale nft={nft} />,
                              });

                              fireTagManagerEvent("click_put_on_sale", {
                                item_id: nft._id,
                              });
                            }}
                          />
                        </>
                      ) : null}
                      {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? (
                        <>
                          <Button
                            size="sm"
                            className="w100"
                            variant="secondary"
                            text={t("wallet.bookings.changeprice")}
                            onClick={() => {
                              openWalletPage({
                                onClose: () => loadNfts(),
                                id: "WalletBookingChangePrice:" + nft._id,
                                content: <WalletBookingChangePrice nft={nft} />,
                              });

                              fireTagManagerEvent("click_change_price", {
                                item_id: nft._id,
                              });
                            }}
                          />
                          <Button
                            size="sm"
                            onClick={() => {
                              openWalletPage({
                                onClose: () => loadNfts(),
                                id: "WalletBookingRemoveFromSale:" + nft._id,
                                content: (
                                  <WalletBookingRemoveFromSale nft={nft} />
                                ),
                              });

                              fireTagManagerEvent("click_remove_from_sale", {
                                item_id: nft._id,
                              });
                            }}
                            variant="secondary"
                            className="w100"
                            text={t("wallet.bookings.removesale")}
                          />
                        </>
                      ) : null}
                    </div>
                  </>
                }
              />
            );
          })}
      </div>

      <div className="d-flex justify-content-end">
        {nfts.length ? (
          <Button
            onClick={() => {
              openWalletPage({
                id: "PastBookings",
                content: <PastBookings />,
              });

              fireTagManagerEvent("click_view_past");
            }}
            size="sm"
            text={t("wallet.bookings.viewpast")}
            variant="secondary"
          />
        ) : null}
      </div>
    </section>
  );
}
