import { iNft } from "./iNft";

export enum SWIPE_OUTCOME {
  GOOD = "GOOD",
  BAD = "BAD",
  NEUTRAL = "NEUTRAL",
}

export default interface iSwipe {
  userId: string;
  nftId: string;
  outcome: SWIPE_OUTCOME;

  _createdAt: any;

  _nft: iNft;
}
