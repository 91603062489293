export function InnovativeStartup() {
  return (
    <main id="web-page">
      <section className="web-section-container">
        <div className="web-section-content">
          <div className="height-160"></div>

          <div key={"tos_" + Math.random()}>
            <div className="whitespace-pre-line">
              <div className="documents">
                <h1 className="text-center mb-4">INNOVATIVE START-UP INFO</h1>
                <p>
                  Takyon S.r.l. (P.IVA 12424180961) is an innovative start-up
                  with registered office and operational headquarters in via
                  Crema 15, 20135, Milan. The company was established on May 23,
                  2022, in Milan, in Via Francesco Petrarca 13, in front of
                  Antonio D’Amico, notary in Milan (Via Francesco Petrarca 13),
                  registered at the Notary College of Milan.
                </p>
                <p>
                  Takyon S.r.l. is registered with the Milan commercial register
                  with the number REA MI - 2660711.
                </p>
                <br />
                <p>
                  Takyon deals with the development, production and marketing of
                  protocols and platforms based on distributed ledger technology
                  (blockchain) responsible for the offer and marketing mainly,
                  but not exclusively, of non-fungible "tokens" (NFT) linked to
                  tourist, experiential or entertainment services. With these
                  products and services Takyon wants to enable travellers to
                  really own their travel bookings in order to enjoy, resell or
                  gift them as they please.
                </p>
                <br />
                <p>
                  Research and development accounts for a significant portion of
                  the activities and expenses incurred by Takyon.
                </p>
                <p>
                  R&D expenses are mainly attributable to software development
                  and updating, tech resources engagement, app, technologies and
                  webapp development, development of functional technologies for
                  the implementation of new innovative services.
                </p>
                <br />
                <p>
                  The following is the entire social object of the company as
                  per the articles of incorporation:”Takyon has as its object
                  the development, production and marketing of innovative
                  products or services with high technological value. In this
                  context, it carries out the development, production and
                  marketing of platforms, software, technologies or similar with
                  high technological value based on innovative paradigms and
                  standards such as Web3, Blockchain, NFT and the like.
                </p>
                <p>
                  The platforms, software, technologies or services provided are
                  responsible for the offer and marketing mainly, but not
                  exclusively, of NFTs related to tourist, experiential or
                  entertainment services such as stays, transports, experiences,
                  concerts, etc.
                </p>
                <p>
                  The company may also carry out the following activities,
                  secondary to the prevailing, which are listed by way of
                  example and not exhaustive:
                </p>
                <p>
                  (a) the development, production and marketing of online
                  platforms, mobile applications and web tools in general;
                </p>
                <p>
                  (b) the development, production and marketing of NFTs linked
                  to services for the enhancement and promotion of the
                  territory, also using innovative hardware and software devices
                  with high technological value;
                </p>
                <p>
                  (c) technical assistance, advice and training with regard to
                  the abovementioned products and services.
                </p>
                <p>
                  Finally, the company may carry out, provided that they do not
                  prevail over the corporate purpose, all securities, real
                  estate, commercial, industrial and financial transactions, the
                  latter not towards the public, deemed by the administration
                  necessary or useful for the achievement of the corporate
                  purpose, including the acquisition of shareholdings in other
                  companies or enterprises or participation in consortia, not
                  for the purpose of placement, having a similar or related
                  object; finally, it may take on mortgages and loans in general
                  and lend sureties and guarantees, including real ones, in
                  favor of third parties, provided that these operations are not
                  carried out towards the public or predominantly.”
                </p>
                <br />
                <p>
                  Takyon does not have professional, collaborative or commercial
                  relationships with certified incubators, institutional and
                  professional investors, universities and research centres.
                  Takyon does not own any proprietary rights in industrial or
                  intellectual property.
                </p>
                <br />
                <p>
                  The majority stake in Takyon S.r.l. is held by Antonio Picozzi
                  and Niccolò Francesco Marino. There are no trustees, holding
                  companies or investee companies.
                </p>
                <br />
                <p>
                  For information on the educational qualifications and
                  professional experiences of the members and staff who work at
                  Takyon, please refer to our LinkedIn Profiles (
                  <a
                    href="https://www.linkedin.com/in/antonio-picozzi-514429146"
                    target="_blank"
                  >
                    Antonio Picozzi
                  </a>{" "}
                  -{" "}
                  <a
                    href="https://www.linkedin.com/in/niccol%C3%B2-francesco-marino"
                    target="_blank"
                  >
                    Niccolò F. Marino
                  </a>
                  ).
                </p>
                <br />
                <p>The first financial statements will close on 31/12/2022.</p>
              </div>
            </div>
          </div>

          <div className="height-100"></div>
        </div>
      </section>
    </main>
  );
}
