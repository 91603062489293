import store from "../../redux/redux";
import { setNftInCheckout } from "../../redux/slices/appSlice";

export function openNftCheckout(nftId: string) {
  store.dispatch(setNftInCheckout(nftId));
}

export function closeNftCheckout() {
  store.dispatch(setNftInCheckout(undefined));
}
