import { Container } from "react-bootstrap";

export default function NotFound() {
  return (
    <main>
      <Container>
        <h1 className="text-center">Not Found</h1>
      </Container>
    </main>
  );
}
