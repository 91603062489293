import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Tab from "../../../../assets/TakyonDesignSystem/components/Tab/Tab";
import Icon from "../../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import MultiSelect, {
  iMultiSelectItem,
} from "../../../../assets/TakyonDesignSystem/components/MultiSelect/MultiSelect";
import Slider from "../../../../assets/TakyonDesignSystem/components/Slider/Slider";

import DatePicker from "../../../../assets/TakyonDesignSystem/components/DatePicker/DatePicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getPlaceCoordinates } from "../../../../assets/Utils/location";
import NftController from "../../../../assets/Controllers/NftController";
import { selectSearch, setSearch } from "../../../../redux/slices/appSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../redux/redux";
import { useNavigate } from "react-router-dom";
import { closeModal, setModalVariables } from "../../../../assets/Utils/modal";
import { closeSearchModal } from "./SearchBox";
import {
  datetimeToString,
  LARGE_SCREEN,
  LARGE_SHORT_SCREEN,
  SMALL_SCREEN,
} from "../../../../assets/Utils/generic";
import { useMediaQuery } from "react-responsive";

interface iSearchBoxStep1Props {
  onBackClick?: () => void;
}

interface CheckboxItem {
  label: string;
  value: string;
}

export enum LocationType {
  Sea = "sea",
  Mountain = "mountain",
  City = "city",
  Lake = "lake",
  Nature = "nature",
  Wellness = "wellness",
}

export enum DaysType {
  None = "none",
  WeekendOnly = "weekend_only",
  WeekdaysOnly = "weekdays_only",
  AllWeek = "all_week",
}

export interface Location {
  lat: number;
  lng: number;
  name?: string;
}

export interface Guests {
  min: number;
  max: number;
}

export interface DateRange {
  from: string;
  to: string;
}

export interface PriceRange {
  min: number;
  max: number;
}

export interface SearchCriteria {
  // [key: string]: any;
  locationType?: LocationType[];
  location?: Location;
  guests?: Guests[];
  dates?: DateRange[];
  prices?: PriceRange[];
  daysType?: DaysType;
}

export default function SearchBoxStay(props: iSearchBoxStep1Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<"flex" | "noflex">("flex");
  const [isLoading, setIsLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  const [isResetted, setIsResetted] = useState(false);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isMediumScreen = useMediaQuery({ query: LARGE_SHORT_SCREEN });
  const isLargeScreen = useMediaQuery({ query: LARGE_SCREEN });

  const getPaddingValue = () => {
    if (isSmallScreen) return "24px";
    if (isMediumScreen) return "24px";
    if (isLargeScreen) return "45px";
  };

  const paddingValue = getPaddingValue();

  useEffect(() => {
    setModalVariables({
      onBack: props.onBackClick,
    });
  }, []);

  const tabItems = [
    { name: t("search.flex"), value: "flex" },
    { name: t("search.noflex"), value: "noflex" },
  ];

  const [buttonResults, setButtonResults] = useState<string | undefined>();
  const [buttonResultDisabled, setButtonResultDisabled] =
    useState<boolean>(true);
  const searchGlobalCriteria = useSelector(selectSearch);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Object.keys(searchCriteria).length) updateFilter();
    setButtonResultDisabled(Object.keys(searchCriteria).length === 0);

    //console.log(searchCriteria);
  }, [searchCriteria]);

  async function updateFilter() {
    setIsLoading(true);
    try {
      const data = await NftController.searchNft(searchCriteria);
      setIsLoading(false);
      try {
        setButtonResults(
          `${t("search.show")} ${data.nfts.length} Tak | ${
            data.collections.length
          } Hotel`
        );
      } catch (error) {
        setButtonResults(undefined);
        //console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //---------------------------------------------------------------------------- FLESSIBILE
  const locationTypeRef = useRef(null) as any;

  const locationTypeItems: iMultiSelectItem[] = [
    {
      content: <p className="m-0 bodytext reuglar">{t("search.sea")}</p>,
      value: LocationType.Sea,
    },
    {
      content: <p className="m-0 bodytext reuglar">{t("search.mountain")}</p>,
      value: LocationType.Mountain,
    },
    {
      content: <p className="m-0 bodytext reuglar">{t("search.lake")}</p>,
      value: LocationType.Lake,
    },
    {
      content: <p className="m-0 bodytext reuglar">{t("search.city")}</p>,
      value: LocationType.City,
    },
    {
      content: <p className="m-0 bodytext reuglar">{t("search.nature")}</p>,
      value: LocationType.Nature,
    },
    {
      content: <p className="m-0 bodytext reuglar">{t("search.wellness")}</p>,
      value: LocationType.Wellness,
    },
  ];

  const viaggiItemsStyle = {
    width: isSmallScreen ? "85px" : "110px",
    height: isSmallScreen ? "85px" : "110px",
  };
  // ---------------------------------------------------------------------- WITH WHO GUESTS
  const viaggiRef = useRef(null) as any;
  const ViaggiItem = ({ text, icon }: { text: string; icon: string }) => (
    <div
      className="d-flex p-2 gap-2 flex-column align-items-center justify-content-center text-center"
      style={viaggiItemsStyle}
    >
      <Icon icon={icon}></Icon>
      <p className="m-0 bodytext regular">{text}</p>
    </div>
  );

  const viaggiItems: iMultiSelectItem[] = [
    {
      content: <ViaggiItem text={t("search.alone")} icon="person" />,
      value: "1",
    },
    {
      content: <ViaggiItem text={t("search.couple")} icon="group" />,
      value: "2",
    },
    {
      content: <ViaggiItem text={t("search.friends")} icon="diversity_3" />,
      value: "3-999",
    },
    {
      content: <ViaggiItem text={t("search.family")} icon="family_restroom" />,
      value: "4-5",
    },
  ];
  const handleWithWhoChange = (updatedItems: iMultiSelectItem[]) => {
    const guests: Guests[] = updatedItems
      .filter((item) => item.selected)
      .map((item) => {
        let [min, max] = item.value.split("-");
        return {
          min: parseInt(min),
          max: parseInt(max || min),
        };
      });

    if (guests.length === 0) {
      const { guests, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
      return;
    } else {
      setSearchCriteria({ ...searchCriteria, guests: guests });
    }
  };

  // ---------------------------------------------------------------------- WHERE
  const handleWhereChange = (updatedItems: iMultiSelectItem[]) => {
    const _locationType = updatedItems
      .filter((item) => item.selected)
      .map((item) => item.value as LocationType);

    if (_locationType.length === 0) {
      const { locationType, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
      return;
    }

    setSearchCriteria({ ...searchCriteria, locationType: _locationType });
  };

  // ---------------------------------------------------------------------- WHEN
  const dateRef = useRef(null) as any;

  const DateItem = ({
    icon,
    month,
    year,
  }: {
    icon: string;
    month: string;
    year: string;
  }) => (
    <div
      className="d-flex p-2 gap-2 flex-column align-items-center justify-content-center text-center"
      style={viaggiItemsStyle}
    >
      <Icon icon={"calendar_today"}></Icon>
      <p className="m-0 bodytext medium">{month}</p>
      <p className="m-0 bodytext">{year}</p>
    </div>
  );

  const dateItems: iMultiSelectItem[] = Array.from(
    { length: 13 },
    (_, index) => {
      const date = new Date();
      date.setMonth(date.getMonth() + index);
      //const monthName = date.toLocaleString("it-IT", { month: "long" });
      const monthName = datetimeToString(
        date,
        undefined,
        false,
        false,
        false,
        true
      ) as string;
      const year = date.getFullYear();
      return {
        content: (
          <DateItem icon="calendar" month={monthName} year={year.toString()} />
        ),
        value: date.toISOString().split("T")[0],
      };
    }
  );

  const handleWhenChange = (updatedItems: iMultiSelectItem[]) => {
    const _dateType = updatedItems
      .filter((item) => item.selected)
      .map((item) => item.value);

    const _dateRange: DateRange[] = [];

    _dateType.forEach((date) => {
      const dateObj = new Date(date);
      dateObj.setDate(1);
      const lastDay = new Date(
        dateObj.getFullYear(),
        dateObj.getMonth() + 1,
        1
      );
      _dateRange.push({
        from: dateObj.toISOString().split("T")[0],
        to: lastDay.toISOString().split("T")[0],
      });
    });

    if (_dateRange.length === 0) {
      const { dates, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
      return;
    }

    setSearchCriteria({ ...searchCriteria, dates: _dateRange });
  };
  // ---------------------------------------------------------------------- BEST MOMENT
  const [bestMomentState, setBestMomentState] = useState<{
    [key: string]: boolean;
  }>({});

  const bestMomentData: CheckboxItem[] = [
    { label: t("search.weekend_only"), value: DaysType.WeekendOnly },
    { label: t("search.weekdays_only"), value: DaysType.WeekdaysOnly },
  ];

  const handleBestMomentStateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = e.target;
    setBestMomentState({
      ...bestMomentState,
      [name]: checked,
    });
  };

  useEffect(() => {
    let _bestMoment: DaysType = DaysType.None;

    if (bestMomentState["weekdays_only"]) {
      _bestMoment = bestMomentState["weekdays_only"]
        ? DaysType.WeekdaysOnly
        : DaysType.WeekendOnly;
    } else if (bestMomentState["weekend_only"]) {
      _bestMoment = bestMomentState["weekend_only"]
        ? DaysType.WeekendOnly
        : DaysType.WeekdaysOnly;
    } else if (
      bestMomentState["weekdays_only"] &&
      bestMomentState["weekend_only"]
    ) {
      _bestMoment = DaysType.AllWeek;
    }

    if (_bestMoment === DaysType.None) {
      const { daysType, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
      return;
    }

    setSearchCriteria({ ...searchCriteria, daysType: _bestMoment });
  }, [bestMomentState]);

  // ---------------------------------------------------------------------- HOW MUCH
  const howmuchRef = useRef(null) as any;

  const howmuchItems: iMultiSelectItem[] = [
    { content: <p className="m-0 bodytext regular">0-150€</p>, value: "0-150" },
    {
      content: <p className="m-0 bodytext regular">150-300€</p>,
      value: "150-300",
    },
    {
      content: <p className="m-0 bodytext regular">300-500€</p>,
      value: "300-500",
    },
    {
      content: <p className="m-0 bodytext regular">500-1000€</p>,
      value: "500-1000",
    },
    { content: <p className="m-0 bodytext regular">1000+€</p>, value: "1000+" },
  ];

  const handleHowMuchChange = (updatedItems: iMultiSelectItem[]) => {
    const _howMuch = updatedItems
      .filter((item) => item.selected)
      .map((item) => {
        const [min, max] = item.value.split("-");
        return {
          min: parseInt(min),
          max: parseInt(max),
        } as PriceRange;
      });

    if (_howMuch.length === 0) {
      const { prices, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
    } else {
      setSearchCriteria({ ...searchCriteria, prices: _howMuch });
    }
  };

  //---------------------------------------------------------------------------- NON FLESSIBILE
  // ---------------------------------------------------------------------- LUOGO
  const [isOpen, setIsOpen] = useState(false);
  const [place, setPlace] = useState<any>();

  const getAddressComp = async (e: any) => {
    let coords: any = {};

    try {
      //console.log(e.value);
      coords = await getPlaceCoordinates(e.value.place_id);
      coords.name = e.value.structured_formatting.main_text;
      setPlace(coords);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentTab === "flex") {
      return;
    }
    setSearchCriteria({ ...searchCriteria, location: place });
  }, [place]);
  // ---------------------------------------------------------------------- GUESTS
  const [gueststState, setGuestsState] = useState<{
    [key: string]: boolean;
  }>({});

  const gueststData: CheckboxItem[] = [
    { label: "1 " + t("search.guest"), value: "1" },
    { label: "2 " + t("search.guests"), value: "2" },
    { label: "3 " + t("search.guests"), value: "3" },
    { label: "4 " + t("search.guests"), value: "4" },
    { label: "5 " + t("search.guests"), value: "5" },
    { label: "6+ " + t("search.guests"), value: "6-999" },
  ];

  const handleGuetstsStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setGuestsState({
      ...gueststState,
      [name]: checked,
    });
  };

  useEffect(() => {
    if (currentTab === "flex") {
      return;
    }
    const guests: Guests[] = Object.entries(gueststState)
      .filter(([key, value]) => value)
      .map(([key, value]) => {
        const [min, max] = key.split("-");
        return {
          min: parseInt(min),
          max: parseInt(max || min),
        };
      });

    if (guests.length === 0) {
      const { guests, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
      return;
    } else {
      setSearchCriteria({ ...searchCriteria, guests: guests });
    }
  }, [gueststState]);

  // ---------------------------------------------------------------------- DATE
  const [checkinDate, setCheckinDateState] = useState<Date | undefined>(
    undefined
  );
  const [checkoutDate, setCheckoutDateState] = useState<Date | undefined>(
    undefined
  );
  const [checkoutMinDate, setCheckoutMinDateState] = useState<Date>(new Date());

  useEffect(() => {
    if (currentTab === "flex") {
      return;
    }
    if (checkinDate && checkoutDate) {
      const dateRange: DateRange = {
        from: new Date(checkinDate).toISOString(),
        to: new Date(checkoutDate).toISOString(),
      };

      setSearchCriteria({ ...searchCriteria, dates: [dateRange] });
    } else {
      const { dates, ...rest } = searchCriteria;
      setSearchCriteria({ ...rest });
    }
  }, [checkinDate, checkoutDate]);

  // ---------------------------------------------------------------------- BUDGET
  const minBudget = 9;
  const maxBudget = 1000;
  const [minBudgetState, setMinBudgetState] = useState<number>(minBudget);
  const [maxBudgetState, setMaxBudgetState] = useState<number>(maxBudget);

  const [minSliderBudgetState, setminSliderBudgetState] =
    useState<number>(minBudget);
  const [maxSliderBudgetState, setmaxSliderBudgetState] =
    useState<number>(maxBudget);
  const [minTempBudgetState, setTempminSliderBudgetState] = useState<string>(
    minBudget.toString()
  );
  const [maxTempBudgetState, setTempmaxSliderBudgetState] = useState<string>(
    maxBudget.toString()
  );

  const handleMinBudgetChange = (value: number) => {
    if (currentTab === "flex") {
      return;
    }
    const _maxTempBudgetState: number = Number(maxTempBudgetState);

    let newValue = value;
    if (newValue >= _maxTempBudgetState) {
      newValue = _maxTempBudgetState - 10;
    }
    if (newValue < minBudget) {
      newValue = minBudget;
    }
    setminSliderBudgetState(newValue);
    setTempminSliderBudgetState(newValue.toString());
    setMinBudgetState(newValue);
  };

  const handleMaxBudgetChange = (value: number) => {
    if (currentTab === "flex") {
      return;
    }
    const _minTempBudgetState: number = Number(minTempBudgetState);
    let newValue = value;
    if (newValue <= _minTempBudgetState) {
      newValue = _minTempBudgetState + 10;
    }
    if (newValue > maxBudget) {
      newValue = maxBudget;
    }
    setmaxSliderBudgetState(newValue);
    setTempmaxSliderBudgetState(newValue.toString());
    setMaxBudgetState(newValue);
  };

  useEffect(() => {
    if (currentTab === "flex") {
      return;
    }
    const priceRange: PriceRange = {
      min: minBudgetState,
      max: maxBudgetState,
    };
    if (isResetted === false) {
      setSearchCriteria({ ...searchCriteria, prices: [priceRange] });
    }
  }, [minBudgetState, maxBudgetState]);

  //---------------------------------------------------------------------------- FOOTER
  const resetValues = () => {
    setIsResetted(true);

    if (currentTab === "flex") {
      locationTypeRef.current?.resetAll();
      viaggiRef.current?.resetAll();
      dateRef.current?.resetAll();
      howmuchRef.current?.resetAll();
      setBestMomentState({});
    } else {
      setPlace({});
      setGuestsState({});
      //TODO finire logica reset per datapicker e autocomplete
      setCheckinDateState(undefined);
      setCheckoutDateState(undefined);
      setMinBudgetState(minBudget);
      setMaxBudgetState(maxBudget);
      setminSliderBudgetState(minBudget);
      setmaxSliderBudgetState(maxBudget);
      setTempminSliderBudgetState(minBudget.toString());
      setTempmaxSliderBudgetState(maxBudget.toString());
    }
    setSearchCriteria({});

    setTimeout(() => {
      setIsResetted(false);
    }, 20);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  function onChangeTab(value: any) {
    setCurrentTab(value);
    const windowSearch = containerRef.current;

    if (windowSearch) {
      windowSearch.scrollTo(0, 0);
    }
    setSearchCriteria({});
  }

  //---------------------------------------------------------------------------- HTML
  return (
    <div
      className="white-background position-relative"
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "711px",
        borderRadius: "5px",
        height: isSmallScreen ? "100vh" : "90vh",
        overflow: "hidden",
      }}
      // style={{ borderRadius: "5px" }}
    >
      <div className="d-flex justify-content-center p-2 border-bottom">
        <p className="bodytext medium m-0">{t("search.title_hotel")}</p>
      </div>

      <div>
        <div className="modal-padding py-3">
          <Tab size="50%" items={tabItems} onChange={onChangeTab}></Tab>
        </div>
      </div>
      <div
        style={{
          height: isSmallScreen ? "calc(100dvh - 165px)" : "calc(90vh - 175px)",
          overflow: "scroll",
        }}
      >
        {/* Body */}
        <div ref={containerRef} className="position-relative modal-padding">
          {currentTab === "flex" ? (
            <>
              <div className="mt-4 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.location_label")}</h3>
                <MultiSelect
                  ref={locationTypeRef}
                  items={locationTypeItems}
                  onChange={handleWhereChange}
                />
              </div>
              <div className="mt-5 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.people_label")}</h3>
                <MultiSelect
                  ref={viaggiRef}
                  items={viaggiItems}
                  onChange={handleWithWhoChange}
                />
              </div>
              <div className="mt-5 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.date_label")}</h3>
                <MultiSelect
                  ref={dateRef}
                  items={dateItems}
                  onChange={handleWhenChange}
                />
                <div className="mt-3">
                  <p className="bodytext medium">
                    {t("search.date_type_label")}
                  </p>
                  <div className="d-flex">
                    <div>
                      {bestMomentData.map((item) => (
                        <div className="form-check mb-4" key={item.value}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={item.value}
                            name={item.value}
                            checked={bestMomentState[item.value] || false}
                            onChange={handleBestMomentStateChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={item.value}
                          >
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 pb-3">
                <h3 className="h3 regular">{t("search.price_label")}</h3>
                <MultiSelect
                  ref={howmuchRef}
                  items={howmuchItems}
                  onChange={handleHowMuchChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mt-4 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.location_label")}</h3>
                {!isResetted ? (
                  <GooglePlacesAutocomplete
                    selectProps={{
                      placeholder: String(t("search.search_location")),
                      onInputChange: () => setIsOpen(false),
                      onChange: (e: any) => {
                        getAddressComp(e);
                      },
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    autocompletionRequest={{
                      componentRestrictions: { country: ["es", "it", "fr"] },
                    }}
                  />
                ) : null}
                <div className="d-flex justify-content-between"></div>
              </div>
              <div className="mt-4 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.guests_label")}</h3>
                <div
                  className="d-flex flex-column flex-wrap"
                  style={{ height: "170px" }}
                >
                  {gueststData.map((item) => (
                    <div className="form-check mb-4" key={item.value}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={item.value}
                        name={item.value}
                        checked={gueststState[item.value] || false}
                        onChange={handleGuetstsStateChange}
                      />
                      <label
                        className="form-check-label bodytext regular"
                        htmlFor={item.value}
                      >
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4 pb-3 border-bottom">
                <h3 className="h3 regular">{t("search.date_label")}</h3>
                <div className="d-flex justify-content-between">
                  {!isResetted ? (
                    <>
                      <div>
                        <DatePicker
                          minDate={new Date()}
                          placeholder="Check-in"
                          onChange={(date) => {
                            if (date) {
                              setCheckinDateState(date as Date);
                              setCheckoutMinDateState(date as Date);
                            } else {
                              setCheckinDateState(undefined);
                              setCheckoutMinDateState(new Date());
                            }
                          }}
                        />
                      </div>
                      <div>
                        <DatePicker
                          minDate={checkoutMinDate}
                          placeholder="Check-out"
                          onChange={(date) => {
                            if (date) {
                              setCheckoutDateState(date as Date);
                            } else {
                              setCheckoutDateState(undefined);
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 pb-3">
                <h3 className="h3 regular">{t("search.budget_label")}</h3>
                <Slider
                  min={minBudget}
                  max={maxBudget}
                  values={{
                    min: minSliderBudgetState,
                    max: maxSliderBudgetState,
                  }}
                  onChange={(min, max) => {
                    const minTemp = Math.floor(min);
                    const maxTemp = Math.floor(max);
                    setminSliderBudgetState(minTemp);
                    setmaxSliderBudgetState(maxTemp);
                    setTempminSliderBudgetState(minTemp.toString());
                    setTempmaxSliderBudgetState(maxTemp.toString());
                  }}
                  onChangeEnd={(min, max) => {
                    setMinBudgetState(Math.floor(min));
                    setMaxBudgetState(Math.floor(max));
                  }}
                />
                <div className="d-flex justify-content-between mt-4 gap-2">
                  <div className="col-6">
                    <p className="bodytext medium mb-1">
                      {t("search.budget_min_label")}
                    </p>
                    <input
                      type="number"
                      className="form-control"
                      value={minTempBudgetState}
                      onChange={(e) =>
                        setTempminSliderBudgetState(e.target.value)
                      }
                      onBlur={(e) => {
                        handleMinBudgetChange(Number(e.target.value));
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <p className="bodytext medium mb-1">
                      {t("search.budget_max_label")}
                    </p>
                    <input
                      type="number"
                      className="form-control"
                      value={maxTempBudgetState}
                      onChange={(e) =>
                        setTempmaxSliderBudgetState(e.target.value)
                      }
                      onBlur={(e) => {
                        handleMaxBudgetChange(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* footer */}
      <div className="d-flex align-items-center justify-content-between py-2 border-top px-4">
        <Button
          className="bodytext medium m-0"
          text="Reset"
          variant="blank"
          onClick={() => {
            resetValues();
          }}
        />
        <Button
          className="bodytext medium m-0"
          text={buttonResults ?? t("search.show")}
          disabled={buttonResultDisabled}
          variant="primary"
          loading={isLoading}
          onClick={() => {
            navigate("/stays");
            dispatch(setSearch(searchCriteria));
            closeSearchModal();
          }}
        />
      </div>
    </div>
  );
}
