import { useTranslation } from "react-i18next";
import { iWallSlot } from "../../../../config/wall";
import Button from "../Button/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { selectUser } from "../../../../redux/slices/userSlice";
import SwipeController from "../../../Controllers/SwipeController";
import { refreshSwipePage } from "../../../Utils/app";
import { apiErrorToast } from "../../../Utils/errors";
import { handleInputChange } from "../../../Utils/input";
import { closeModal, openModal } from "../../../Utils/modal";
import DatePicker from "../DatePicker/DatePicker";
import Input from "../Input/Input";
import Select from "../Select/Select";
import verifiedPng from "../../../../assets/img/offerbuy/verified.png";
import Icon from "../Icon/Icon";

function TellUsMoreModal() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [choosenSpots, setChoosenSpots] = useState<Array<string>>([]);
  const [choosenGuests, setChoosenGuests] = useState<Array<string>>([]);
  const [howManyAdults, setHowManyAdults] = useState("0");
  const [howManyKids, setHowManyKids] = useState("0");
  const [howManyInfants, setHowManyInfants] = useState("0");
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const peopleList: Array<number> = [0, 1, 2, 3, 4];

  const spotsList: Array<string> = [
    t("swipe.askcard.mountain"),
    t("swipe.askcard.sea"),
    t("swipe.askcard.lake"),
    t("swipe.askcard.city"),
    t("swipe.askcard.wellness"),
    t("swipe.askcard.hidden_gems"),
  ];

  const guestsList: Array<string> = [
    `1 ${t("swipe.askcard.guests")}`,
    `2 ${t("swipe.askcard.guests")}`,
    `3 ${t("swipe.askcard.guests")}`,
    `4 ${t("swipe.askcard.guests")}`,
    `5 ${t("swipe.askcard.guests")}`,
    `6 ${t("swipe.askcard.guests")}`,
    `7+ ${t("swipe.askcard.guests")}`,
  ];

  const [request, setRequest] = useState<{
    spots: Array<string>;
    place: string;
    guests: Array<string>;
    checkin?: string | undefined;
    checkout?: string | undefined;
    request: string;
    email: string | undefined;
    howMuch: string;
    howManyAdults: string | undefined;
    howManyKids: string | undefined;
    howManyInfants: string | undefined;
  }>({
    spots: [],
    place: "",
    guests: [],
    checkin: undefined,
    checkout: undefined,
    request: "",
    email: user?.email,
    howMuch: "",
    howManyAdults: "0",
    howManyKids: "0",
    howManyInfants: "0",
  });

  useEffect(() => {
    if (
      request.email !== "" &&
      request.howMuch !== "" &&
      request.checkin !== undefined &&
      request.checkout !== undefined &&
      (howManyAdults !== "0" || howManyKids !== "0" || howManyInfants !== "0")
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [request, howManyAdults, howManyKids, howManyInfants]);

  const SendForm = async () => {
    setIsLoading(true);
    try {
      await SwipeController.requestStay({
        ...request,
        guests: choosenGuests.join(", "),
        spots: choosenSpots.join(", "),
        howManyAdults: howManyAdults,
        howManyKids: howManyKids,
        howManyInfants: howManyInfants,
      });
      setIsFormSent(true);
      refreshSwipePage();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isFormSent ? (
        <div className="d-flex flex-column h-100">
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span style={{ height: "22px" }}></span>
          </div>
          <div className="height-24"></div>
          <div className="p-4 p-md-5 d-flex flex-column align-items-center justify-content-center h100 w100">
            <Icon icon="check_circle" type="symbol" size={150} />

            <div className="height-10"></div>

            <span className="h2 text-center">
              {t("swipe.askcard.modal.thanks_title")}
            </span>
            <div className="height-10"></div>

            <span className="bodytext light text-center">
              {t("swipe.askcard.modal.thanks_text")}
            </span>
            <Button
              style={{ marginTop: "40px" }}
              text={t("swipe.askcard.modal.continue")}
              onClick={() => closeModal()}
            ></Button>
          </div>
        </div>
      ) : null}

      {!isFormSent ? (
        <>
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span className="bodytext medium">
              {t("swipe.askcard.modal.title")}
            </span>
          </div>
          <div className="modal-body">
            <div className="only-mobile"></div>
            {/* Email */}
            <Form.Group className="modal-padding py-32 border-bottom">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.6")}*
              </Form.Label>
              <Input
                type="email"
                placeholder={t("swipe.askcard.modal.61")}
                name="email"
                value={request.email}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>
            {/* Place */}
            <Form.Group className="modal-padding py-32 border-bottom">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.2")}
              </Form.Label>
              <Input
                type="text"
                placeholder={t("swipe.askcard.modal.21")}
                name="place"
                value={request.place}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>
            {/* Budget */}
            <Form.Group className="modal-padding py-32 border-bottom">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.7")}*
              </Form.Label>
              <Form.Select
                value={request.howMuch}
                onChange={(e) =>
                  setRequest({ ...request, howMuch: e.target.value })
                }
              >
                <option value=""></option>
                <option value="0-150">0-150</option>
                <option value="150-300">150-300</option>
                <option value="300-500">300-500</option>
                <option value="500-1000">500-1000</option>
                <option value="1000+">1000+</option>
              </Form.Select>
            </Form.Group>
            {/* Guets */}
            <Form.Group className="modal-padding py-32 border-bottom">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.71")}*
              </Form.Label>
              <div className="d-flex flex-column flex-md-row gap-3">
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.72")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={howManyAdults}
                    onChange={(e) => setHowManyAdults(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.73")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={howManyKids}
                    onChange={(e) => setHowManyKids(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.74")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={howManyInfants}
                    onChange={(e) => setHowManyInfants(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
              </div>
            </Form.Group>
            {/* Dates */}
            <Form.Group className="modal-padding py-32 border-bottom">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.4")}*
              </Form.Label>
              <p className="mb-0 bodytext medium">Check-in</p>
              <div className="d-flex flex-column flex-md-row gap-3">
                <DatePicker
                  placeholder={t("swipe.askcard.modal.41")}
                  className="w100"
                  status={request.checkin ? "success" : "normal"}
                  onChange={(e) => {
                    if (e) {
                      setRequest({
                        ...request,
                        checkin: e.toISOString(),
                      });
                    }

                    if (!e)
                      setRequest({
                        ...request,
                        checkin: undefined,
                      });
                  }}
                />
                <DatePicker
                  placeholder={t("swipe.askcard.modal.41")}
                  className="w100"
                  status={request.checkout ? "success" : "normal"}
                  onChange={(e) => {
                    if (e) {
                      setRequest({
                        ...request,
                        checkout: e.toISOString(),
                      });
                    }

                    if (!e)
                      setRequest({
                        ...request,
                        checkout: undefined,
                      });
                  }}
                />
              </div>
            </Form.Group>
            {/* Details */}
            <Form.Group className="modal-padding py-32">
              <Form.Label className="h4 medium">
                {t("swipe.askcard.modal.5")}
              </Form.Label>
              <Input
                type="text"
                placeholder={t("swipe.askcard.modal.51")}
                name="request"
                value={request.request}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>
          </div>

          <div className="d-flex column justify-content-between gap-2 white-background px-4 py-2 border-top">
            <Button
              className="btn-text"
              type="button"
              text={t("swipe.askcard.modal.cancel")}
              variant="blank"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              disabled={!isFormValid}
              type="button"
              loading={isLoading}
              text={t("swipe.askcard.modal.send")}
              onClick={() => SendForm()}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
interface Props {
  slot?: iWallSlot;
}

export default function StayRequest(props: Props) {
  const { t } = useTranslation();

  return (
    <section className="light-black-background rounded mt-5 mb-5 p-4">
      <div className="text-white">
        <h2 className="h1 regular m-0">{t("wall.stay_request_title")}</h2>
        <div className="height-8"></div>
        <p className="bodytext regular m-0">
          {t("wall.stay_request_subtitle")}
        </p>
        <div className="height-24"></div>
        <Button
          text={t("wall.stay_request_btn")}
          onClick={() => {
            openModal({
              content: <TellUsMoreModal />,
            });
          }}
        />
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
      </div>
    </section>
  );
}
