import Nav from "./components/Nav/Nav";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main/Main";
import Auth from "./pages/Auth/Auth";
import Wallet from "../assets/TakyonDesignSystem/components/Wallet/Wallet";
import { Redirect } from "../assets/Utils/app";
import getLink, { LINK_TYPE } from "../config/Links";
import PendingOrders from "../_Shared/components/PendingOrders/PendingOrders";
// import NftPage2 from "./components/NftPage/NftPage2";
import CheckoutPage from "./components/Actions/CheckoutPage";
import OfferPage from "./components/Actions/OfferPage";
import { HubLoader } from "../_Web/pages/Hub/Hub";

function AppRouter() {
  return (
    <>
      <Routes>
        <Route path="auth/:mode" element={<></>} />
        <Route path=":mode" element={<Main />} />
        <Route path=":mode/:id/*" element={<Main />} />
        <Route
          path="*"
          element={<Redirect to={getLink(LINK_TYPE.APP_EXPLORE)} />}
        />
      </Routes>
    </>
  );
}

export default function App() {
  return (
    <>
      <div id="swipes-app" className="black-background d-flex h100 w100">
        <HubLoader />

        <Auth />
        {/* <NftPage2 /> */}
        <CheckoutPage />
        <OfferPage />

        <div className="d-flex w100 h100">
          <div className="d-flex flex-column h100 w100">
            <PendingOrders />
            <Nav />
            <AppRouter />
          </div>
          <Wallet />
        </div>
      </div>
    </>
  );
}
