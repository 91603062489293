import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import getLink, { LINK_TYPE } from "../../../config/Links";
import {
  Flags,
  ForgotPassword,
  Login,
  LoginAsk,
  LoginAskSmall,
  Register,
  RegisterAsk,
  RegisterAskSmall,
  ResetPassword,
  Retrieve,
  UserInfo,
  VerifyEmail,
} from "../../components/Auth/AuthComponents";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import UserController from "../../../assets/Controllers/UserController";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import NftController from "../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../assets/Utils/errors";

import { WALLET_NAV_ELEMENT } from "../../../assets/TakyonDesignSystem/components/Wallet/WalletNav";
import { openWallet } from "../../../assets/Utils/wallet";

enum AUTH_SECTION {
  login = "login",
  register = "register",
  forgot_password = "forgot_password",
  reset_password = "reset_password",
  verify_email = "verify_email",
  reset_password_success = "reset_password_success",
  flags = "flags",
  info = "info",
  retrieve = "retrieve",
}

export default function Auth() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [referralCode, setReferralCode] = useState("");
  const [promocode, setPromoCode] = useState("");
  const [nftRetrieveId, setNftRetrieveId] = useState("");
  const [nftRetrieveHash, setNftRetrieveHash] = useState("");
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const [authMode, setAuthMode] = useState<AUTH_SECTION>(AUTH_SECTION.login);
  const [hidden, setHidden] = useState(true);

  const retrieve = async () => {
    try {
      await NftController.retrieveFromEmailHash({
        hash: nftRetrieveHash,
      });
      // await UserController.refreshUserInfo();
      openWallet(WALLET_NAV_ELEMENT.bookings);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  useEffect(() => {
    // if (user && hidden) return;

    const myMode = String(String(window.location.pathname).split("/").pop());
    const isAuthPage = Object.keys(AUTH_SECTION).includes(myMode);

    if (isAuthPage) {
      setAuthMode(myMode as AUTH_SECTION);
      setHidden(false);
    } else {
      setHidden(true);
    }

    if (myMode === AUTH_SECTION.retrieve) return;
    if (myMode === AUTH_SECTION.reset_password) return;

    if (user) {
      if (nftRetrieveHash) retrieve();

      if (!user.emailVerified) {
        navigate(getLink(LINK_TYPE.VERIFY_EMAIL));
        return;
      }

      if (typeof user.marketingEmails === "undefined") {
        navigate(getLink(LINK_TYPE.AUTH_FLAGS));
        return;
      }

      if (
        !user.firstName ||
        !user.secondName ||
        !user.phone ||
        !user.phonePrefix
      ) {
        navigate(getLink(LINK_TYPE.AUTH_INFO));
        return;
      }

      setHidden(true);
      if (isAuthPage) return navigate(getLink(LINK_TYPE.APP_EXPLORE));
      else return;
    }

    // if (!isAuthPage && !UserController.isLoggedIn())
    //   return navigate(getLink(LINK_TYPE.LOGIN));

    // setHidden(false);

    // eslint-disable-next-line
  }, [window.location.pathname, user]);

  // Referral code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const referralcode = params.get("referralcode");
    if (referralcode) setReferralCode(referralcode);
  }, []);

  // retrieve nft effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const nft = params.get("nftId");
    if (nft) setNftRetrieveId(nft);
    const hash = params.get("hash");
    if (hash) setNftRetrieveHash(hash);
  }, []);

  // reset password token effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const token = params.get("token");
    if (token) setResetPasswordToken(token);
  }, []);

  // Promo code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const _promocode = params.get("promocode");

    if (_promocode) setPromoCode(_promocode);

    (async () => {
      try {
        if (user && promocode) {
          const credits = await UserController.redeemCredits(promocode);
          setPromoCode("");

          openModal({
            content: (
              <p>
                By using the Promo code {promocode.toUpperCase()} you received{" "}
                {credits.amount}€ in Credits usable in the Takyon App!
              </p>
            ),
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user]);

  if (hidden) return <></>;

  return (
    <section id="app-auth" className="overflow-scroll">
      {authMode === "login" || authMode === "register" ? (
        <>
          <div className="only-desktop position-relative d-flex h100 w100 align-items-center">
            <div className="w100 d-flex justify-content-center">
              <Login />
            </div>
            <div className="w100 d-flex justify-content-center">
              <Register referralCode={referralCode} />
            </div>

            <div
              style={{ zIndex: 10 }}
              className={`ask-overlay bg-black d-flex justify-content-center align-items-center h100 ${authMode}`}
            >
              {authMode === "register" ? <LoginAsk /> : null}
              {authMode === "login" ? <RegisterAsk /> : null}
            </div>
          </div>

          <div className="only-mobile d-flex flex-column h100 w100">
            <div className="d-flex h100 w100 align-items-center justify-content-center">
              {authMode === "register" ? (
                <Register referralCode={referralCode} />
              ) : null}
              {authMode === "login" ? <Login /> : null}
            </div>
            <div className="bg-black">
              {authMode === "register" ? <LoginAskSmall /> : null}
              {authMode === "login" ? <RegisterAskSmall /> : null}
            </div>
          </div>
        </>
      ) : (
        <div className="h100 w100 d-flex flex-column align-items-center">
          {authMode === "verify_email" ? <VerifyEmail /> : null}
          {authMode === "flags" ? <Flags /> : null}
          {authMode === "info" ? <UserInfo /> : null}
          {authMode === "forgot_password" ? <ForgotPassword /> : null}
          {authMode === "reset_password" ? (
            <ResetPassword token={resetPasswordToken} />
          ) : null}
          {authMode === "retrieve" ? (
            <Retrieve onRetrieve={retrieve} nftId={nftRetrieveId} />
          ) : null}
        </div>
      )}
    </section>
  );
}
