import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iSwipe, { SWIPE_OUTCOME } from "../../../assets/models/iSwipe";
import { DEFAULT_PAGINATION_SIZE } from "../../../assets/models/iPagination";
import { selectUser } from "../../../redux/slices/userSlice";
import SwipeController from "../../../assets/Controllers/SwipeController";
import { apiErrorToast } from "../../../assets/Utils/errors";
// import NftCard from "../../../assets/TakyonDesignSystem/components/NftCard/NftCard";
import { iNft, NFT_SELL_STATUS } from "../../../assets/models/iNft";
import getLink, { LINK_TYPE } from "../../../config/Links";
import Skeleton from "react-loading-skeleton";
import NothingHere from "../../../assets/TakyonDesignSystem/components/Wallet/Components/NothingHere";
import { useMediaQuery } from "react-responsive";
import { datetimeToString, SMALL_SCREEN } from "../../../assets/Utils/generic";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { useTranslation } from "react-i18next";
import { openNftPage } from "../../../assets/Utils/nftpage";
import { selectRefreshSwipes } from "../../../redux/slices/appSlice";
import NftCardFav from "../../../assets/TakyonDesignSystem/components/NftCard/NftCardFav";

export default function Saved() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [mode] = useState<SWIPE_OUTCOME>(SWIPE_OUTCOME.GOOD);

  const [swipes, setSwipes] = useState<iSwipe[]>([]);
  const [page] = useState(1);
  const [size] = useState(DEFAULT_PAGINATION_SIZE);
  const refreshSwipes = useSelector(selectRefreshSwipes);

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await SwipeController.getPaginated({ page, size }, mode);

      let swipes = data.data.filter((s) => s._nft);

      const currentDate = new Date();
      swipes = swipes.filter(
        (swipe) => new Date(swipe._nft.payload?.checkin!) > currentDate
      );

      swipes = swipes.map((swipe) => {
        swipe._nft._swipeOutcome = swipe.outcome;
        return swipe;
      });

      setSwipes(swipes);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) load();
  }, [user, mode, refreshSwipes]);

  const handleLike = async (nft: iNft, outcome: SWIPE_OUTCOME) => {
    try {
      await SwipeController.swipe({ nftId: nft._id, outcome });

      const myswipes = swipes.map((swipe) => {
        if (swipe.nftId === nft._id) {
          swipe.outcome = outcome;
          swipe._nft._swipeOutcome = outcome;
          console.log(swipe);
        }
        return swipe;
      });
      setSwipes([...myswipes]);
    } catch (error) {
      apiErrorToast(error);
    }
  };

  return (
    <section className="position-relative d-flex flex-column h100 w100 overflow-hidden">
      {isLoading ? (
        <div className="m-2 d-flex flex-wrap align-items-center justify-content-center gap-2">
          <div>
            <Skeleton height={300} width={300} count={3} />
          </div>
          <div>
            <Skeleton height={300} width={300} count={3} />
          </div>
          <div>
            <Skeleton height={300} width={300} count={3} />
          </div>
        </div>
      ) : null}

      {!swipes.length && !isLoading ? (
        <NothingHere
          light
          title={t("app.saved.no_results")}
          subtitle={t("app.saved.no_results_text")}
          action={() => {
            navigate(getLink(LINK_TYPE.APP_EXPLORE));
          }}
          actionText={t("app.saved.no_results_action")}
        />
      ) : null}

      <div className="d-flex justify-content-center overflow-scroll w100">
        <div className="container">
          <div className="content">
            <div className="d-flex flex-wrap gap-3" style={{ width: "100%" }}>
              {swipes.map((swipe) => {
                return (
                  <>
                    <div
                      className="p-1"
                      key={Math.random()}
                    >
                      <NftCardFav
                        onClick={(nft) => {
                          openNftPage(nft._id);

                          fireTagManagerEvent("close_card", {
                            item_id: nft._id,
                            page: "favorites",
                          });
                        }}
                        onLike={async () => {
                          if (swipe._nft._swipeOutcome === SWIPE_OUTCOME.GOOD) {
                            await handleLike(swipe._nft, SWIPE_OUTCOME.NEUTRAL);

                            fireTagManagerEvent("like", {
                              item_id: swipe._nft._id,
                              auth: true,
                              outcome: SWIPE_OUTCOME.NEUTRAL,
                              side: "front",
                              page: "favorites",
                            });

                            return;
                          }

                          fireTagManagerEvent("like", {
                            item_id: swipe._nft._id,
                            auth: true,
                            outcome: SWIPE_OUTCOME.GOOD,
                            side: "front",
                            page: "favorites",
                          });

                          await handleLike(swipe._nft, SWIPE_OUTCOME.GOOD);
                        }}
                        nft={swipe._nft}
                      />
                      {swipe._nft.sellStatus ===
                      NFT_SELL_STATUS.NOT_FOR_SALE ? (
                        <div
                          className="position-absolute primary-background d-flex justify-content-center align-items-center bodytext-lg medium"
                          style={{
                            bottom: "0",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                            width: "95%",
                            height: "70px",
                          }}
                        >
                          {t("app.saved.sold")}
                        </div>
                      ) : null}
                    </div>
                    <></>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
