import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { handleInputChange } from "../../../assets/Utils/input";
import UserController from "../../../assets/Controllers/UserController";
import { apiErrorToast } from "../../../assets/Utils/errors";
import { useGoogleLogin } from "@react-oauth/google";
import googleSvg from "../../../assets/img/icons/google.svg";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { PHONE_CODES } from "../../../config/PhoneCodes";
import { useNavigate } from "react-router-dom";
import { isNumber, isPhoneNumber } from "../../../assets/Utils/generic";
import { toast } from "react-toastify";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import Switch from "../../../assets/TakyonDesignSystem/components/Switch/Switch";
import Select from "../../../assets/TakyonDesignSystem/components/Select/Select";
import { validate } from "email-validator";
import Stepper from "../../../assets/TakyonDesignSystem/components/Stepper/Stepper";
import NftController from "../../../assets/Controllers/NftController";
import { iNft } from "../../../assets/models/iNft";
import NftCard2 from "../../../assets/TakyonDesignSystem/components/NftCard/NftCard2";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { LANG } from "../../../config/Lang";
import { openWallet } from "../../../assets/Utils/wallet";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { WALLET_NAV_ELEMENT } from "../../../assets/TakyonDesignSystem/components/Wallet/WalletNav";
import { getLocaleLanguage } from "../../../assets/Services/i18next";

const PASS_LENGHT = 6;

export function RegisterAsk() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center text-white">
      <p className="m-0 h2 regular text-center">{t("auth.welcome")}!</p>
      <div className="height-20"></div>

      <p className="m-0 h4 regular text-center">{t("auth.welcometext1")}</p>
      <p className="m-0 h4 regular text-center">{t("auth.welcometext2")}</p>
      <div className="height-40"></div>

      <Button
        text={t("auth.register")}
        onClick={() => navigate(getLink(LINK_TYPE.REGISTER))}
      ></Button>
    </section>
  );
}

export function LoginAsk() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center text-white">
      <p className="m-0 h2 regular text-center">{t("auth.welcomeback")}!</p>
      <div className="height-20"></div>
      <p className="m-0 h4 regular text-center">
        {t("auth.welcomebacktext2")}{" "}
      </p>
      <div className="height-40"></div>
      <Button
        text={t("auth.login")}
        onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
      ></Button>
    </section>
  );
}

export function LoginAskSmall() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <section className="w100 p-4 d-flex justify-content-center align-items-center gap-3">
      <p className="text-white m-0">{t("auth.welcomebacktext1")}</p>
      <Button
        size="sm"
        text={t("auth.login")}
        onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
      ></Button>
    </section>
  );
}

export function RegisterAskSmall() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <section className="w100 p-4 d-flex justify-content-center align-items-center gap-3">
      <p className="text-white m-0">{t("auth.welcometext1")}</p>
      <Button
        size="sm"
        text={t("auth.register")}
        onClick={() => navigate(getLink(LINK_TYPE.REGISTER))}
      ></Button>
    </section>
  );
}

interface RegisterProps {
  referralCode?: string;
}

export function Register({ referralCode }: RegisterProps) {
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const [lang, setLang] = useState<LANG | undefined>();

  useEffect(() => {
    const initAsync = async () => {
      const l = await getLocaleLanguage();

      const langValue: LANG | undefined = l as LANG | undefined;
      setLang(langValue);
    };
    initAsync();
  }, []);

  const [registerState, setRegisterState] = useState({
    email: "",
    password: "",
    referralCode: referralCode,
    lang: lang,
  });
  const [registerStateStatus, setRegisterStateStatus] = useState({
    email: "normal",
    password: "normal",
  });
  const [registerStateStatusError, setRegisterStateStatusError] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    setRegisterState({ ...registerState, lang: i18n.language as LANG });
  }, [i18n.language]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const registerSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.register(registerState);
      fireTagManagerEvent("register");
    } catch (error: any) {
      if (
        String(error?.response?.data?.message)
          .toLowerCase()
          .includes("already exists")
      ) {
        setRegisterStateStatus({ ...registerStateStatus, email: "error" });
        setRegisterStateStatusError({
          ...registerStateStatusError,
          email: t("auth.alreadyexists"),
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <p className="m-0 h2 regular">{t("auth.register")}</p>
      <div className="height-40"></div>
      <GoogleAuth referralCode={referralCode} mode="register" />

      <div className="height-24"></div>
      <div className="w100 d-flex gap-3 align-items-center">
        <div className="w100">
          <hr />
        </div>
        <small className="nowrap bodytext-xs">{t("auth.useemail")}</small>
        <div className="w100">
          <hr />
        </div>
      </div>
      <div className="height-24"></div>

      <Form className="w100" onSubmit={registerSubmit}>
        <Form.Group>
          <Form.Label>{t("auth.email")}</Form.Label>
          <Input
            required
            value={registerState.email}
            onChange={(e) => {
              handleInputChange(e, setRegisterState);

              if (validate(e.target.value) === true) {
                setRegisterStateStatus({
                  ...registerStateStatus,
                  email: "success",
                });
                setRegisterStateStatusError({
                  ...registerStateStatusError,
                  email: "",
                });
              }
            }}
            type="email"
            name="email"
            placeholder="name@email.com"
            status={registerStateStatus.email as any}
            onBlur={() => {
              if (validate(registerState.email) === false)
                setRegisterStateStatus({
                  ...registerStateStatus,
                  email: "error",
                });
            }}
          />
          {registerStateStatusError.email ? (
            <Form.Text className="error">
              {registerStateStatusError.email}
            </Form.Text>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>{t("auth.password")}</Form.Label>
          <Input
            required
            value={registerState.password}
            onChange={(e) => {
              handleInputChange(e, setRegisterState);

              if (e.target.value.length >= PASS_LENGHT)
                setRegisterStateStatus({
                  ...registerStateStatus,
                  password: "success",
                });
            }}
            type={`${showPass ? "" : "password"}`}
            name="password"
            icon={`${showPass ? "visibility" : "visibility_off"}`}
            placeholder={t("auth.passwordph")}
            onIconClick={() => setShowPass(!showPass)}
            status={registerStateStatus.password as any}
            onBlur={() => {
              if (registerState.password.length < PASS_LENGHT)
                setRegisterStateStatus({
                  ...registerStateStatus,
                  password: "error",
                });
            }}
          />
          <Form.Text
            className={`${
              registerStateStatus.password === "error" ? "error" : ""
            }`}
          >
            {t("auth.passwordtext")}
          </Form.Text>
        </Form.Group>
        <div className="d-flex justify-content-center mt-2">
          <Button
            className="btn-responsive"
            text={t("auth.register")}
            loading={isLoading}
            variant="primary"
            type="submit"
            disabled={
              !validate(registerState.email) ||
              registerState.password.length < PASS_LENGHT
            }
          ></Button>
        </div>
      </Form>
      <br />
      <a
        onClick={() => {
          navigate(getLink(LINK_TYPE.APP_EXPLORE));
          openWallet(WALLET_NAV_ELEMENT.bookings);
          fireTagManagerEvent("guest_access");
        }}
      >
        <u>{t("auth.continueguest")}</u>
      </a>
    </section>
  );
}

export function Login() {
  const { t } = useTranslation();

  const [loginState, setLoginState] = useState({ email: "", password: "" });
  const [loginStateStatus, setLoginStateStatus] = useState({
    email: "normal",
    password: "normal",
  });
  const [loginStateStatusError, setLoginStateStatusError] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const loginSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.login(loginState);
      navigate(getLink(LINK_TYPE.APP_EXPLORE));

      fireTagManagerEvent("login");
    } catch (error: any) {
      // apiErrorToast(error);

      if (
        String(error?.response?.data?.message)
          .toLowerCase()
          .includes("password is not correct")
      ) {
        setLoginStateStatus({ ...loginStateStatusError, password: "error" });
        setLoginStateStatusError({
          ...loginStateStatusError,
          password: t("auth.passwordnotcorrect"),
        });
      }
      if (
        String(error?.response?.data?.message)
          .toLowerCase()
          .includes("not found")
      ) {
        setLoginStateStatus({ ...loginStateStatusError, email: "error" });
        setLoginStateStatusError({
          ...loginStateStatusError,
          email: t("auth.noaccount"),
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <p className="m-0 h2 regular">{t("auth.login")}</p>
      <div className="height-40"></div>
      <GoogleAuth mode="login" />

      <div className="height-24"></div>
      <div className="w100 d-flex gap-3 align-items-center">
        <div className="w100">
          <hr />
        </div>
        <small className="nowrap bodytext-xs">{t("auth.useemail")}</small>
        <div className="w100">
          <hr />
        </div>
      </div>
      <div className="height-24"></div>

      <Form className="w100" onSubmit={loginSubmit}>
        <Form.Group>
          <Form.Label>{t("auth.email")}</Form.Label>
          <Input
            required
            value={loginState.email}
            onChange={(e) => {
              handleInputChange(e, setLoginState);

              if (validate(e.target.value) === true) {
                setLoginStateStatus({
                  ...loginStateStatus,
                  email: "success",
                });
                setLoginStateStatusError({
                  ...loginStateStatusError,
                  email: "",
                });
              }
            }}
            type="email"
            name="email"
            placeholder="name@email.com"
            status={loginStateStatus.email as any}
            onBlur={() => {
              if (validate(loginState.email) === false) {
                setLoginStateStatus({
                  ...loginStateStatus,
                  email: "error",
                });
                setLoginStateStatusError({
                  ...loginStateStatusError,
                  email: t("auth.emailerror"),
                });
              }
            }}
          />
          {loginStateStatusError.email ? (
            <Form.Text className="error">
              {loginStateStatusError.email}
            </Form.Text>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Input
            onChange={(e) => {
              handleInputChange(e, setLoginState);

              if (e.target.value.length >= PASS_LENGHT)
                setLoginStateStatus({
                  ...loginStateStatus,
                  password: "success",
                });
              setLoginStateStatusError({
                ...loginStateStatusError,
                password: "",
              });
            }}
            required
            type="password"
            value={loginState.password}
            name="password"
            placeholder={t("auth.passwordph")}
            status={loginStateStatus.password as any}
          />
          {loginStateStatusError.password ? (
            <Form.Text className="error">
              {loginStateStatusError.password}
            </Form.Text>
          ) : null}
        </Form.Group>

        <div className="d-flex flex-column w100 align-items-center">
          <div className="height-14"></div>
          <p className="m-0 text-muted linktext regular">
            <a onClick={() => navigate(getLink(LINK_TYPE.FORGOT_PASSWORD))}>
              <u>{t("auth.passwordforgot")}</u>
            </a>
          </p>
          <div className="height-24"></div>
          <Button
            className="btn-responsive"
            text={t("auth.login")}
            loading={isLoading}
            variant="primary"
            type="submit"
            disabled={!validate(loginState.email) || !loginState.password}
          ></Button>
        </div>
      </Form>
      <br />
      <a
        onClick={() => {
          navigate(getLink(LINK_TYPE.APP_EXPLORE));
          openWallet(WALLET_NAV_ELEMENT.bookings);
          fireTagManagerEvent("guest_access");
        }}
      >
        <u>{t("auth.continueguest")}</u>
      </a>
    </section>
  );
}

interface GoogleAuthProps {
  mode: "register" | "login";
  referralCode?: string;
}
export function GoogleAuth({ mode, referralCode }: GoogleAuthProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await UserController.socialLogin({
        googleToken: tokenResponse.access_token,
        redirect: String(process.env.REACT_APP_FRONTEND_URI),
        referralCode,
      });
      navigate(getLink(LINK_TYPE.APP_EXPLORE));
    },
  });

  return (
    <div
      onClick={() => {
        googleLogin();

        if (mode === "login") fireTagManagerEvent("login_google");
        if (mode === "register") fireTagManagerEvent("login_register");
      }}
      className="d-flex gap-3 py-2 px-5 border cursor-pointer rounded text-muted"
    >
      <img src={googleSvg} alt="google icon" className="height-25" />
      {mode === "login" ? (
        <span className="text">{t("auth.logingoogle")}</span>
      ) : null}
      {mode === "register" ? (
        <span className="text">{t("auth.registergoogle")}</span>
      ) : null}
    </div>
  );
}

interface ResetPasswordProps {
  token: string;
}
export function ResetPassword({ token }: ResetPasswordProps) {
  const { t } = useTranslation();

  const [resetState, setResetState] = useState({
    token,
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const resetSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const user = await UserController.resetPassword(resetState);
      UserController._login(user);
      navigate(getLink(LINK_TYPE.APP_EXPLORE));
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <div className="w100">
        <Button
          text="Go back"
          variant="blank"
          onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
          icon="arrow_back"
        />
      </div>
      <br />
      <br />
      <p className="h2 regular">{t("auth.resetpassword")}</p>
      <Form className="w100" onSubmit={resetSubmit}>
        <Form.Group>
          <Form.Label>{t("auth.newpassword")}</Form.Label>
          <Input
            value={resetState.password}
            onChange={(e) => handleInputChange(e, setResetState)}
            type="password"
            name="password"
            placeholder={t("auth.passwordph")}
          />
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            className="btn-responsive"
            text={t("auth.save")}
            loading={isLoading}
            variant="primary"
            type="submit"
          ></Button>
        </div>
      </Form>
    </section>
  );
}

export function ForgotPassword() {
  const { t } = useTranslation();

  const [forgotState, setForgotState] = useState({
    email: "",
    redirect: getLink(LINK_TYPE.RESET_PASSWORD, "EXCHANGE_FULL"),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const forgotSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.sendRecoveryPasswordEmail(forgotState);
      toast(t("auth.checkemailpassword"));
      setSent(true);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <div className="w100">
        <Button
          text="Go back"
          variant="blank"
          onClick={() => navigate(-1)}
          icon="arrow_back"
        />
      </div>
      <br />

      <p className="h2 regular">{t("auth.resetpassword")}</p>
      <Form className="w100" onSubmit={forgotSubmit}>
        <Form.Group>
          <Form.Label>{t("auth.email")}</Form.Label>
          <Input
            required
            value={forgotState.email}
            onChange={(e) => handleInputChange(e, setForgotState)}
            type="email"
            name="email"
            placeholder="name@email.com"
          />
        </Form.Group>
        <p className="text-center">{t("auth.resetpasswordtext")}</p>
        <div className="d-flex justify-content-center">
          <Button
            loading={isLoading}
            disabled={sent}
            className="btn-responsive"
            variant="primary"
            type="submit"
            text={t("auth.sendreset")}
          ></Button>
        </div>
      </Form>
    </section>
  );
}

export function VerifyEmail() {
  const { t } = useTranslation();

  const [code, setCode] = useState(Array(6).fill(""));

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const verifyRequestSubmit = async () => {
    setIsLoading(true);
    try {
      await UserController.sendVerificationEmail({
        redirect: getLink(LINK_TYPE.LOGIN, "EXCHANGE_FULL"),
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  const verifySubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      await UserController.verifyEmail({
        redirect: getLink(LINK_TYPE.APP_EXPLORE, "EXCHANGE_FULL"),
        code: code.join(""),
      });
      await UserController.refreshUserInfo();
    } catch (error) {
      toast.error(t("auth.codenotcorrect"));
    }
    setIsLoading(false);
  };

  const handleChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newCode = [...code];
    const input = event.target.value[0];
    if (isNumber(input) || input === "") {
      newCode[index] = input;
      setCode(newCode);
      if (input && index < 5) {
        document.getElementById(`code-input-${index + 1}`)?.focus();
      }
    }
  };

  const handleBackspace = (index: number, event: { key: string }) => {
    if (event.key === "Backspace") {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
      if (index > 0)
        document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  };

  useEffect(() => {
    verifyRequestSubmit();
  }, []);

  // TODO fix the auto submit, the bug with this is that it runs twice.
  // useEffect(() => {
  //   if (code.join("").length === 6) verifySubmit();
  // }, [code]);

  return (
    <section className="w100 px-4 mw-600 d-flex flex-column align-items-center">
      <br />
      <br />
      <div className="w100">
        <Button
          text="Logout"
          variant="blank"
          onClick={() => {
            UserController.logout();
            navigate(getLink(LINK_TYPE.LOGIN));
          }}
          icon="arrow_back"
        />
      </div>

      <div className="height-39"></div>

      <p className="h2 regular text-center">{t("auth.completeaccounttitle")}</p>

      <div className="height-39"></div>
      <Stepper steps={3} step={1} />
      <div className="height-100"></div>

      <p className="h3 regular text-center">{t("auth.verifyemail")}</p>

      <p className="text-center text-muted bodytext regular">
        {t("auth.verifyemailtext")}
      </p>

      <Form className="w100" onSubmit={verifySubmit}>
        <Form.Group className="d-flex flex-column align-items-center">
          <div className="d-flex gap-1 my-2">
            {code.map((value, index) => (
              <input
                onPaste={(e) => {
                  try {
                    const paste = e.clipboardData.getData("text");
                    setCode(String(paste).split(""));
                  } catch (error) {}
                }}
                pattern="[0-9]*"
                inputMode="decimal"
                key={index}
                id={`code-input-${index}`}
                value={value}
                onKeyDown={(event) => handleBackspace(index, event)}
                onChange={(event) => handleChange(index, event)}
                // type="number"
                className="text-center form-control height-40 width-40"
              />
            ))}
          </div>
        </Form.Group>

        <div className="d-flex justify-content-center gap-2">
          <Button
            onClick={verifyRequestSubmit}
            className="btn-responsive"
            loading={isLoading}
            variant="secondary"
            type="button"
            text={t("auth.sendagain")}
          ></Button>

          <Button
            text={t("auth.submitcode")}
            className="btn-responsive"
            loading={isLoading}
            variant="primary"
            type="submit"
          ></Button>
        </div>
      </Form>
    </section>
  );
}

export function Flags() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [flagsState, setFlagsState] = useState<{
    marketingEmails: "on" | "off";
  }>({
    marketingEmails: "off",
  });

  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  const navigate = useNavigate();

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(flagsState);
      await UserController.refreshUserInfo();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <br />
      <br />
      <div className="w100">
        <Button
          text="Logout"
          variant="blank"
          onClick={() => {
            UserController.logout();
            navigate(getLink(LINK_TYPE.LOGIN));
          }}
          icon="arrow_back"
        />
      </div>

      <div className="height-39"></div>

      <p className="h2 regular text-center">{t("auth.completeaccounttitle")}</p>

      <div className="height-39"></div>
      <Stepper steps={3} step={2} />
      <div className="height-100"></div>

      <h3 className="regular">{t("auth.acceptconditions")}</h3>
      <div className="height-39"></div>

      <Form className="w100" onSubmit={submit}>
        <Form.Group className="mb-2 d-flex gap-3">
          <Switch
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
          />
          <Form.Label className="light">
            {t("auth.conditionstos")}{" "}
            <a onClick={() => navigate(getLink(LINK_TYPE.TOS))}>
              <u>{t("auth.tos")}</u>
            </a>
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-2 d-flex gap-3">
          <Switch
            name="privacyPolicy"
            checked={privacy}
            onChange={(e) => setPrivacy(e.target.checked)}
          />
          <span className="bodytext-sm regular">
            {t("forhotels.section_6.17")}{" "}
            <a
              onClick={() => {
                navigate(getLink(LINK_TYPE.PRIVACY));
              }}
            >
              <u>{t("forhotels.section_6.19")}</u>
            </a>
          </span>
        </Form.Group>
        <Form.Group className="mb-3 d-flex gap-3">
          <Switch
            onChange={(e) => {
              e.target.checked
                ? setFlagsState({ marketingEmails: "on" })
                : setFlagsState({ marketingEmails: "off" });
            }}
            checked={flagsState.marketingEmails === "on"}
            type="switch"
            name="marketingEmails"
            id="marketing"
          />
          <Form.Label className="light" htmlFor="marketing">
            {t("auth.conditionsmarketing")}
          </Form.Label>
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button
            text={t("auth.save")}
            loading={isLoading}
            disabled={!terms || !privacy}
            variant="primary"
            className="btn-responsive"
            type="submit"
          ></Button>
        </div>
      </Form>
    </section>
  );
}

export function UserInfo() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [userState, setUserState] = useState<{
    firstName: string;
    secondName: string;
    phonePrefix: string;
    phone: string;
  }>({ firstName: "", secondName: "", phonePrefix: "", phone: "" });

  const [phoneError, setPhoneError] = useState(false);

  const navigate = useNavigate();

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(userState);
      await UserController.refreshUserInfo();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="w100 p-4 mw-600 d-flex flex-column align-items-center">
      <br />
      <br />
      <div className="w100">
        <Button
          text="Logout"
          variant="blank"
          onClick={() => {
            UserController.logout();
            navigate(getLink(LINK_TYPE.LOGIN));
          }}
          icon="arrow_back"
        />
      </div>

      <div className="height-39"></div>

      <p className="h2 regular text-center">{t("auth.completeaccounttitle")}</p>

      <div className="height-39"></div>
      <Stepper steps={3} step={3} />
      <div className="height-100"></div>

      <h3 className="regular">{t("auth.info")}</h3>

      <p className="text-center text-muted bodytext regular">
        {t("auth.infotext")}
      </p>

      <Form className="w100" onSubmit={submit}>
        <Form.Group>
          <Form.Label>{t("auth.firstname")}*</Form.Label>
          <Input
            status={userState.firstName ? "success" : "normal"}
            required
            value={userState.firstName}
            onChange={(e) => handleInputChange(e, setUserState)}
            type="text"
            name="firstName"
            placeholder={t("auth.firstnameph")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("auth.secondname")}*</Form.Label>
          <Input
            status={userState.secondName ? "success" : "normal"}
            required
            value={userState.secondName}
            onChange={(e) => handleInputChange(e, setUserState)}
            type="text"
            name="secondName"
            placeholder={t("auth.secondnameph")}
          />
        </Form.Group>
        <div className="d-flex w100 gap-2">
          <Form.Group>
            <Form.Label>{t("auth.prefix")}*</Form.Label>
            <Select
              name="phonePrefix"
              value={userState.phonePrefix}
              onChange={(e) => handleInputChange(e, setUserState)}
              options={[
                { value: "", text: "+00" },
                ...PHONE_CODES.map((e) => {
                  return {
                    value: e.code,
                    text: `${e.country} ${e.code}`,
                  };
                }),
              ]}
            />
          </Form.Group>

          <Form.Group className="w100">
            <Form.Label>{t("auth.phone")}*</Form.Label>
            <Input
              status={
                userState.phonePrefix && isPhoneNumber(userState.phone)
                  ? "success"
                  : "normal"
              }
              placeholder="00 00 0000"
              required
              value={userState.phone}
              onChange={(e) => {
                setPhoneError(false);
                handleInputChange(e, setUserState);
              }}
              onBlur={() => {
                if (!isPhoneNumber(userState.phone)) setPhoneError(true);
              }}
              type="text"
              name="phone"
            />
            {phoneError ? (
              <Form.Text className="error">{t("auth.phoneerror")}</Form.Text>
            ) : null}
          </Form.Group>
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Button
            text={t("auth.save")}
            loading={isLoading}
            className="btn-responsive"
            variant="primary"
            type="submit"
            disabled={
              !userState.firstName ||
              !userState.secondName ||
              !userState.phonePrefix ||
              !isPhoneNumber(userState.phone)
            }
          ></Button>
        </div>
      </Form>
    </section>
  );
}

// /app/auth/retrieve?nftId=75cb6fb7-3f88-4e7e-bc78-b65575e8b1f9&hash=__HASH__
// /app/auth/retrieve?nftId=66e9eec4-c055-4e37-9406-c53724899504&hash=__HASH__
export function Retrieve({
  nftId,
  onRetrieve,
}: {
  nftId: string;
  onRetrieve(): any;
}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [nft, setNft] = useState<iNft>();
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const loadNft = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getNft(nftId);
      setNft(data);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    loadNft();
  }, []);

  // TODO LOADER

  return (
    <section className="h100 w100 d-flex align-items-center justify-content-center black-background overflow-scroll">
      <div
        style={{ maxWidth: "1000px" }}
        className="h100 d-flex gap-4 flex-column flex-md-row align-items-center p-4"
      >
        {nft ? (
          <NftCard2
            maxHeight="700px"
            nft={nft}
            style={{ cursor: "deafult!" }}
          />
        ) : null}
        <div className="d-flex flex-column justify-content-center align-items-center align-items-md-start p-3">
          <p className="m-0 regular white-color h1 text-center text-md-left">
            {t("retrieve.title1")}{" "}
            <span className="primary-color">{t("retrieve.title2")}</span>{" "}
            {t("retrieve.title3")}{" "}
            <span className="primary-color">{t("retrieve.title4")}</span>
          </p>
          <div className="height-16"></div>
          <p className="m-0 bodytext-sm regular dark-grey-color text-center text-md-left">
            {t("retrieve.text")}
          </p>
          <div className="height-40"></div>

          <div
            style={{ maxWidth: "380px" }}
            className="d-flex flex-column w100"
          >
            {!user ? (
              <>
                <Button
                  onClick={() => navigate(getLink(LINK_TYPE.REGISTER))}
                  className="w100"
                  text={t("auth.register")}
                />
                <div className="height-16"></div>
                <Button
                  onClick={() => navigate(getLink(LINK_TYPE.LOGIN))}
                  variant="light"
                  className="w100"
                  text={t("auth.login")}
                />
              </>
            ) : (
              <>
                <Button
                  loading={isLoading}
                  onClick={() => {
                    onRetrieve();
                    navigate(getLink(LINK_TYPE.APP_EXPLORE));
                    openWallet(WALLET_NAV_ELEMENT.bookings);
                  }}
                  className="w100"
                  text={t("retrieve.view")}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
