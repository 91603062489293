import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import Button from "../../../../Button/Button";
import {
  closeWalletPages,
  openWalletPage,
} from "../../../../../../Utils/wallet";
import { BID_STATUS, iBid } from "../../../../../../models/iBid";
import OfferController from "../../../../../../Controllers/OfferController";
import { apiErrorToast } from "../../../../../../Utils/errors";
import Input from "../../../../Input/Input";
import OrderController from "../../../../../../Controllers/OrderController";
import { WalletCheckout } from "../../../../../../../_Web/components/Checkout/WalletCheckout";
import { fireTagManagerEvent } from "../../../../../../Services/tagmanager";

interface WalletOfferActionsProps {
  offer: iBid;
  onSuccess?: Function;

  mode: "accept" | "cancel" | "decline" | "counteroffer" | "checkout";
}

export function WalletOfferSuccess({ offer, mode }: WalletOfferActionsProps) {
  const { t } = useTranslation();

  useEffect(() => {
    if (offer._side === "buyer" && mode === "accept") {
      openWalletPage({
        id: "wallet_page_checkout" + offer._nft!._id,
        content: <WalletCheckout nft={offer._nft!} />,
      });
    }
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex flex-column">
        <div className="height-8"></div>
        {mode === "accept" ? (
          <div>
            <p className="m-0 bodytext regular">
              {t("wallet.offers.yousuccessfullyaccepted")}
            </p>

            <p className="m-0 bodytext regular dark-grey-color">
              {t("wallet.offers.youaccepted")} €
              {!offer.counterOfferAmount || offer.counterOfferAmount === 0
                ? offer.amount
                : offer.counterOfferAmount}
              &nbsp;
              {t("wallet.offers.buyertime")}.
            </p>
          </div>
        ) : null}

        {mode === "cancel" ? (
          <div>
            <span>{t("wallet.offers.youcanceled1")}</span>
            <div className="d-flex flex-wrap dark-grey-color">
              <span>{t("wallet.offers.youcanceled2")}</span>
              &nbsp;{offer.amount} € .
              <span>{t("wallet.offers.youcanceled3")}</span>
            </div>
          </div>
        ) : null}

        {mode === "decline" ? (
          <div>
            <p className="m-0 bodytext regular">
              {" "}
              {t("wallet.offers.youdeclined1")}
            </p>
            <div className="height-4"></div>
            <p className="m-0 bodytext regular dark-grey-color">
              {t("wallet.offers.youdeclined2")}
              &nbsp;{Number(offer.amount).toFixed(2)} €.{" "}
              {t("wallet.offers.youdeclined3")}
            </p>
          </div>
        ) : null}

        {mode === "counteroffer" ? (
          <div>
            <p className="m-0 bodytext regular">
              {t("wallet.offers.yourcounteroffer")} {t("wallet.offers.hassent")}
            </p>
            <div className="height-4"></div>

            <p className="m-0 bodytext regular dark-grey-color">
              {t("wallet.offers.youcounteroffer")} €
              {Number(offer.counterOfferAmount).toFixed(2)}{" "}
              {t("wallet.offers.hassubmmited")}{" "}
              {t("wallet.offers.counteroffertime")}
            </p>
          </div>
        ) : null}

        {mode === "checkout" ? (
          <div>
            <span> {t("app.checkout.successtitle")}</span>
            <div className="d-flex flex-wrap dark-grey-color">
              <span>{t("app.checkout.successtext")}</span>
            </div>
          </div>
        ) : null}

        <div className="height-24"></div>

        <div className="d-flex justify-content-between gap-2">
          <Button
            onClick={() => {
              closeWalletPages();
            }}
            text={t("wallet.offers.check")}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export function WalletCancelOffer({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const cancelOfferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.cancelOffer({ bidId: offer._id });

      fireTagManagerEvent("cancel_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <span>Loading ...</span>;

  return (
    <div className="p-3">
      <div className="d-flex flex-column">
        <span className="medium">{t("wallet.offers.cancel")}</span>
        <div className="height-8"></div>
        <span className="dark-grey-color">{t("wallet.offers.cancelsure")}</span>
        <div className="height-16"></div>

        <div>
          <Button
            onClick={() => {
              cancelOfferClick();
              openWalletPage({
                id: "wallet_offer_page_success" + offer._id,
                content: <WalletOfferSuccess offer={offer} mode="cancel" />,
                hideGoBack: true,
              });
            }}
            text={t("wallet.offers.cancel")}
            className="w100"
          ></Button>
        </div>
      </div>
    </div>
  );
}

export function WalletDeclineOffer({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const declineOfferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.declineOffer({ bidId: offer._id });

      openWalletPage({
        id: "wallet_offer_page_success" + offer._id,
        content: <WalletOfferSuccess offer={offer} mode="decline" />,
        hideGoBack: true,
      });

      fireTagManagerEvent("decline_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.offers.decline")}</p>
      <div className="height-8"></div>
      <p className="m-0 bodytext regular dark-grey-color">
        {t("wallet.offers.declinesure")}
      </p>
      <div className="height-16"></div>

      <Button
        onClick={declineOfferClick}
        loading={isLoading}
        text={t("wallet.offers.decline")}
      />
    </div>
  );
}

export function WalletAcceptOffer({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  // const user = useSelector(selectUser);

  const acceptOfferClick = async () => {
    // if (
    //   (!user.stripeDetailsSubmitted || !user.stripeChargesEnabled) &&
    //   bid.mode == BID_MODE.MONEY
    // ) {
    //   openModal(<NoStripeError />, false);
    //   return;
    // }

    setIsLoading(true);
    try {
      await OfferController.acceptOffer({ bidId: offer._id });

      fireTagManagerEvent("accept_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.offers.accept")}</p>
      <div className="height-8"></div>
      <p className="m-0 bodytext regular dark-grey-color">
        {t("wallet.offers.acceptsure")}
      </p>
      <div className="height-16"></div>

      <Button
        onClick={() => {
          fireTagManagerEvent("create_payment_intent", {
            mode: "offer",
            item_id: offer.nftId,
          });

          acceptOfferClick();
          openWalletPage({
            id: "wallet_offer_page_success" + offer._id,
            content: <WalletOfferSuccess offer={offer} mode="accept" />,
            hideGoBack: true,
          });
        }}
        text={t("wallet.offers.accept")}
        loading={isLoading}
      />
    </div>
  );
}

export function WalletCounteroffer({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState<number | string>();

  const counterofferClick = async () => {
    setIsLoading(true);
    try {
      const counteroffer = await OfferController.createCounterOffer({
        bidId: offer._id,
        amount: Number(amount),
      });

      openWalletPage({
        id: "WalletOfferSuccess:" + counteroffer._id,
        content: (
          <WalletOfferSuccess offer={counteroffer} mode="counteroffer" />
        ),
        hideGoBack: true,
      });

      fireTagManagerEvent("make_counter_offer", {
        item_id: offer.nftId,
        amount: Number(amount),
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.offers.makecounter")}</p>
      <div className="height-4"></div>
      <p className="m-0 bodytext regular dark-grey-color">
        {t("wallet.offers.counteroffersuggest1")}
      </p>
      <div className="height-24"></div>

      <p className="m-0 bodytext medium">
        {t("wallet.offers.counteroffersuggest2")}: €
        {!offer.counterOfferAmount || offer.counterOfferAmount === 0
          ? offer.amount
          : offer.counterOfferAmount}
      </p>

      <div className="height-16"></div>

      <form>
        <Form.Label htmlFor="amount_counteroffer">
          {t("wallet.offers.counteroffersuggest3")}
        </Form.Label>
        <Input
          status={Number(amount) > 0 ? "success" : "normal"}
          min={0}
          type="number"
          name="amount"
          required
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </form>

      <div className="height-24"></div>

      <Button
        onClick={() => {
          counterofferClick();
        }}
        text={t("wallet.offers.makecounter")}
        loading={isLoading}
        disabled={Number(amount) <= 0}
      />
    </div>
  );
}

export function WalletCounterofferAccept({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const acceptCounterofferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.acceptCounterOffer({ bidId: offer._id });
      await OrderController.initPendingOrders();

      openWalletPage({
        id: "wallet_offer_page_success" + offer._id,
        content: <WalletOfferSuccess offer={offer} mode="accept" />,
        hideGoBack: true,
      });

      fireTagManagerEvent("accept_counter_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="p-3">
      <span className="bodytext medium">
        {t("wallet.offers.acceptcounter")}
      </span>
      <div className="height-8"></div>
      <span className="bodytext dark-grey-color">
        {t("wallet.offers.acceptsure")}
      </span>
      <div className="height-16"></div>

      <Button
        onClick={() => {
          acceptCounterofferClick();
        }}
        text={t("wallet.offers.acceptcounter")}
        loading={isLoading}
      />
    </div>
  );
}

export function WalletCounterofferDecline({ offer }: WalletOfferActionsProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const declineCounterofferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.declineCounterOffer({ bidId: offer._id });
      closeWalletPages("wallet_offer_page_decline_offer" + offer._id);

      // openWalletPage({
      //   id: "wallet_offer_page_success" + offer._id,
      //   content: <WalletOfferSuccess offer={offer} mode="decline" />,
      // });

      fireTagManagerEvent("decline_counter_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="mx-4">
      <span className="bodytext medium">{t("wallet.offers.cancel")}</span>
      <div className="height-8"></div>
      <span className="bodytext regular dark-grey-color">
        {t("wallet.offers.declinesure")}
      </span>

      <div className="height-16"></div>

      <Button
        onClick={() => {
          declineCounterofferClick();
        }}
        text={
          (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
            offer._side === "buyer") ||
          (offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
            offer._side === "seller")
            ? t("wallet.offers.decline")
            : t("wallet.offers.cancel")
        }
        loading={isLoading}
      />
    </div>
  );
}
