import store from "../../redux/redux";
import { setNftInOffer } from "../../redux/slices/appSlice";

export function openNftOffer(nftId: string) {
  store.dispatch(setNftInOffer(nftId));
}

export function closeNftOffer() {
  store.dispatch(setNftInOffer(undefined));
}
