import SearchBoxTakType from "./SearchBoxTakType";
import { SEARCH_TAK_TYPE } from "./SearchBoxTakType";
import SearchBoxStay from "./SearchBoxStay";
import { useEffect, useState } from "react";
import { setModalVariables } from "../../../../assets/Utils/modal";
import { useSelector } from "react-redux";
import {
  selectIsSearchBoxOpen,
  selectSearch,
  setIsSearchBoxOpen,
} from "../../../../redux/slices/appSlice";
import store from "../../../../redux/redux";
import Icon from "../../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";

export function openSearchModal() {
  store.dispatch(setIsSearchBoxOpen(true));
}
export function closeSearchModal() {
  store.dispatch(setIsSearchBoxOpen(false));
}

export function SearchModal() {
  const isOpen = useSelector(selectIsSearchBoxOpen);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const search = useSelector(selectSearch);
  const [currentStep, setCurrentStep] = useState(0);

  function result(value: SEARCH_TAK_TYPE) {
    if (value === SEARCH_TAK_TYPE.STAYS) {
      setCurrentStep(1);
    }
  }

  useEffect(() => {
    if (!Object.keys(search).length) backToStepZero();
  }, [search]);

  function backToStepZero() {
    setCurrentStep(0);
    setModalVariables({});
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          height: "100dvh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: isOpen ? "99999" : "-1",
          opacity: isOpen ? 1 : 0,
        }}
      >
        <div
          className="white-background position-relative"
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "711px",
            borderRadius: "5px",
            height: isSmallScreen ? "100vh" : "",
            maxHeight: isSmallScreen ? "100vh" : "",
          }}
        >
          <Icon
            onClick={() => {
              closeSearchModal();
            }}
            size={25}
            icon="close"
            style={{
              position: "absolute",
              top: "6px",
              right: "10px",
              cursor: "pointer",
              zIndex: isOpen ? "999999" : "-1",
            }}
          />

          {currentStep !== 0 ? (
            <>
              <Icon
                onClick={() => {
                  backToStepZero();
                }}
                size={25}
                icon="arrow_back"
                style={{
                  position: "absolute",
                  top: "8px",
                  left: "10px",
                  cursor: "pointer",
                  zIndex: isOpen ? "999999" : "-1",
                }}
              />
            </>
          ) : null}

          <>
            {currentStep === 0 && <SearchBoxTakType onClick={result} />}
            {currentStep === 1 && (
              <SearchBoxStay onBackClick={backToStepZero} />
            )}
          </>
        </div>
      </div>
    </>
  );
}
