import { ReactNode, useEffect, useState } from "react";
import { closeWalletPages } from "../../../Utils/wallet";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";

export interface WalletPageProps {
  id: string;
  title?: string;
  subtitle?: string;
  hideGoBack?: boolean;
  content: ReactNode;
  onClose?(): Promise<any>;
}

export default function WalletPage({
  id,
  content,
  hideGoBack,
}: WalletPageProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const close = () => {
    setIsOpen(false);
    closeWalletPages(id);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1);
  }, []);

  return (
    <section className={`wallet-page ${isOpen ? "open" : ""}`}>
      {!hideGoBack ? (
        <div className="light-grey-background p-3">
          <Button
            onClick={close}
            text={t("wallet.generic.goback")}
            icon="arrow_back"
            variant="blank"
            className="dark-grey-color"
          />
        </div>
      ) : null}

      <div className="wallet-page-content w100 h100">{content}</div>
    </section>
  );
}
