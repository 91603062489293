import { Form } from "react-bootstrap";
import Button from "../Button/Button";
import { useEffect, useState } from "react";
import { iSwipeFilter } from "../../../Controllers/SwipeController";
import { useAppDispatch } from "../../../../redux/redux";
import {
  selectSwipeFilter,
  setSwipeFilter,
} from "../../../../redux/slices/appSlice";
import Icon from "../Icon/Icon";
import Switch from "../Switch/Switch";
import Select from "../Select/Select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MONTH_DICT } from "../../../Utils/generic";

interface FiltersProps {
  onOpen?(): any;
  onClose?(): any;
  iconOnly?: boolean;
  dark?: boolean;
  className?: string;
}

export default function Filters({
  onOpen,
  onClose,
  iconOnly,
  className,
}: FiltersProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const [filter, setFilter] = useState<iSwipeFilter>({});

  const swipeFilter = useSelector(selectSwipeFilter);
  const [filterAmount, setFilterAmount] = useState(0);

  const [guestSelectValue, setGuestSelectValue] = useState<number>(-1);
  const [weenkendOnlyValue, setWeenkendOnlyValue] = useState<boolean>(false);
  const [cheapestFirstValue, setCheapestFirstValue] = useState<boolean>(false);
  const [timeframValue, setTimeframeValue] = useState<number>(12);
  const [timeframValueDate, setTimeframeValueDate] = useState(new Date());
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!timeframValue) return setTimeframeValueDate(new Date());

    const date = new Date(
      new Date().setMonth(new Date().getMonth() + timeframValue)
    );
    setTimeframeValueDate(date);
  }, [timeframValue]);

  const onChange = (filter: iSwipeFilter) => {
    dispatch(setSwipeFilter({ ...filter, category: swipeFilter.category }));
  };

  useEffect(() => {
    if (open) onOpen?.();
    if (!open) onClose?.();
  }, [open]);

  const reset = () => {
    setGuestSelectValue(-1);
    setTimeframeValue(12);
    setWeenkendOnlyValue(false);
    setCheapestFirstValue(false);
    setFilter({});
  };

  useEffect(() => {
    let amount = 0;

    if (filter.geustsMax || filter.geustsMax) amount++;
    if (typeof filter.timeframe !== "undefined") amount++;
    if (filter.weekendOnly) amount++;

    setFilterAmount(amount);
  }, [swipeFilter]);

  return (
    <div className="swipe-filters">
      <Button
        outline
        variant="blank"
        size="sm"
        onClick={() => setOpen(!open)}
        text={`${iconOnly ? "" : t("app.filters.match")}`}
        icon="sort"
        className={`white-color ${className}`}
        notification={filterAmount}
      />

      {open ? (
        <div className="swipe-filters-container">
          <div className="swipe-filters-content d-flex flex-column justify-content-between white-background rounded">
            <div className="d-flex justify-content-end p-3">
              <Icon
                className="cursor-pointer"
                icon="close"
                onClick={() => setOpen(false)}
              />
            </div>
            <div
              className="swipe-filters-inner"
              style={{
                padding: "0px 40px 16px 40px",
              }}
            >
              <p className="m-0 bodytext medium">
                {t("app.filters.1")} {MONTH_DICT(timeframValueDate.getMonth())}{" "}
                {timeframValueDate.getFullYear() !== new Date().getFullYear()
                  ? timeframValueDate.getFullYear()
                  : ""}
              </p>
              <Form.Range
                onChange={(e) => {
                  setTimeframeValue(Math.ceil(Number(e.target.value)));
                  setFilter({
                    ...filter,
                    timeframe: Math.ceil(Number(e.target.value)),
                  });
                }}
                value={timeframValue}
                max={12}
                min={0}
              />

              <div className="height-16"></div>
              <div className="d-flex justify-content-between">
                <p className="m-0 bodytext medium">{t("app.filters.4")}</p>
                <Switch
                  onChange={(e) => {
                    setWeenkendOnlyValue(e.target.checked);
                    setFilter({ ...filter, weekendOnly: e.target.checked });
                  }}
                  checked={weenkendOnlyValue}
                />
              </div>

              <div
                className="horizontal-divider"
                style={{ margin: "24px 0" }}
              ></div>

              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 bodytext medium">{t("app.filters.5")}</p>
                <div>
                  <Select
                    value={guestSelectValue}
                    onChange={(e) => {
                      setGuestSelectValue(Number(e.target.value));
                      switch (Number(e.target.value)) {
                        case -1: {
                          setFilter({
                            ...filter,
                            geustsMin: undefined,
                            geustsMax: undefined,
                          });
                          break;
                        }
                        case 1: {
                          setFilter({ ...filter, geustsMin: 1, geustsMax: 1 });
                          break;
                        }
                        case 2: {
                          setFilter({ ...filter, geustsMin: 2, geustsMax: 2 });
                          break;
                        }
                        case 3: {
                          setFilter({ ...filter, geustsMin: 3, geustsMax: 3 });
                          break;
                        }
                        case 4: {
                          setFilter({ ...filter, geustsMin: 4, geustsMax: 4 });
                          break;
                        }
                        case 5: {
                          setFilter({
                            ...filter,
                            geustsMin: 5,
                            geustsMax: 999,
                          });
                          break;
                        }
                      }
                    }}
                    options={[
                      { text: t("app.filters.6"), value: -1, disabled: true },
                      { text: "1", value: 1 },
                      { text: "2", value: 2 },
                      { text: "3", value: 3 },
                      { text: "4", value: 4 },
                      { text: "5", value: 5 },
                    ]}
                  />
                </div>
              </div>

              <div
                className="horizontal-divider"
                style={{ margin: "24px 0" }}
              ></div>

              <div className="d-flex justify-content-between">
                <p className="m-0 bodytext medium">{t("app.filters.7")}</p>
                <Switch
                  onChange={(e) => {
                    setCheapestFirstValue(e.target.checked);
                    setFilter({ ...filter, cheapestFirst: e.target.checked });
                  }}
                  checked={cheapestFirstValue}
                />
              </div>

              <div className="height-16"></div>
            </div>

            <div>
              <div className="horizontal-divider" style={{ margin: 0 }}></div>
              <div className="d-flex gap-2" style={{ padding: "16px 24px" }}>
                <Button
                  className="w100"
                  variant="secondary"
                  text={t("app.filters.8")}
                  onClick={() => {
                    reset();
                  }}
                />
                <Button
                  className="w100"
                  text={t("app.filters.9")}
                  onClick={() => {
                    setOpen(false);
                    onChange(filter);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
