import { useState } from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import OrderController from "../../../../../assets/Controllers/OrderController";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import _OffersActionsLayout from "./_OffersActionsLayout";
import { useOfferNavigation } from "../../../../hooks/hooks";
import OfferDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/OfferDetails";
import { openModal } from "../../../../../assets/Utils/modal";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import helpers from "../../../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../../../config/Links";

export default function CounterofferAccept({
  side,
}: {
  side: "seller" | "buyer";
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { offer } = useOfferNavigation();
  const navigate = useNavigate();

  const acceptCounterofferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.acceptCounterOffer({ bidId: offer._id });
      await OrderController.initPendingOrders();

      fireTagManagerEvent("accept_counter_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.offers.yousuccessfullyaccepted")}
              message={`${t("wallet.offers.youaccepted")} ${helpers.price(
                offer.counterOfferAmount ?? offer.amount
              )} ${
                side === "buyer"
                  ? t("wallet.offers.sellertime")
                  : t("wallet.offers.buyertime")
              }`}
              continueClickCallback={() =>
                navigate(getLink(LINK_TYPE.PROFILE_OFFERS))
              }
              continueBtnText={t("app.checkout.viewoffers")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_OffersActionsLayout title={t("wallet.offers.acceptcounter")}>
      <OfferDetails offer={offer} />

      <hr className="my-3" />

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            acceptCounterofferClick();
          }}
          text={t("wallet.offers.acceptcounter")}
          loading={isLoading}
        />
      </div>
    </_OffersActionsLayout>
  );
}
