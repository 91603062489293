import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { datetimeRangeToString, SMALL_SCREEN } from "../../../../Utils/generic";
import {
  NFT_ACCESS_STATUS,
  NFT_SELL_STATUS,
  iNft,
} from "../../../../models/iNft";
import { WalletBookingPageDetails } from "./WalletBookings";
import { fireTagManagerEvent } from "../../../../Services/tagmanager";
import { selectWalletSection } from "../../../../../redux/slices/appSlice";
import Stars from "../../Stars/Stars";
import { openNftPage } from "../../../../Utils/nftpage";
import {
  getAmountOfGuests,
  getCityFromLocation,
} from "../../../../Utils/location";
import { useMediaQuery } from "react-responsive";

export default function BookingHeader({
  nft,
  customActions,
  disabled,
}: {
  nft: iNft;
  showActions?: boolean;
  onRefresh?: Function;
  customActions?: JSX.Element;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const section = useSelector(selectWalletSection);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  if (!nft) return <></>;

  return (
    <div
      className="w100 position-relative overflow-hidden mb-3 p-3 light-grey-background"
      style={{
        borderRadius: "8px",
      }}
    >
      <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex gap-2">
          <img
            style={{ borderRadius: "8px" }}
            height="118px"
            width="118px"
            src={
              nft.images?.[0] ??
              nft._collectionImages?.[0] ??
              nft._collectionCensus?.images?.[0]
            }
          />
          <div className="bodytext light black-color">
            <p>{getCityFromLocation(nft._collectionCensus?.location)}</p>
            <p>
              {nft._collectionCensus?.name}
              {nft._collectionCensus?.stars ? (
                <Stars stars={nft._collectionCensus?.stars} />
              ) : null}
            </p>
            <p>
              {datetimeRangeToString(
                nft.payload?.checkin,
                nft.payload?.checkout,
                nft._collectionCensus?.location?.gmt
              )}
            </p>
            <p>
              {getAmountOfGuests(nft).adults + getAmountOfGuests(nft).childs}{" "}
              {t("app.nft.guests")}
            </p>
          </div>
        </div>
        {isSmallScreen ? (
          <div className="horizontal-divider m-0 mt-3 mb-3"></div>
        ) : (
          <div
            className="vertical-divider m-0 mx-3"
            style={{ height: "118px" }}
          ></div>
        )}

        <div className="d-flex flex-column gap-2 bodytext black-color justify-content-center">
          {nft.sellStatus === NFT_SELL_STATUS.NOT_FOR_SALE &&
          nft.accessStatus === NFT_ACCESS_STATUS.OPEN ? (
            <p>
              {t("wallet.generic.originallypurchased")}
              <span className="medium" style={{ marginLeft: "5px" }}>
                {Number(nft.originalPrice).toFixed(2)} €
              </span>
            </p>
          ) : (
            <>
              <p style={{ textDecoration: "line-through" }}>
                {t("wallet.generic.originallypurchased")}
                <span className="medium" style={{ marginLeft: "5px" }}>
                  {Number(nft.originalPrice).toFixed(2)} €
                </span>
              </p>
              <p>
                {t("app.nft.soldat")}
                <span className="medium" style={{ marginLeft: "5px" }}>
                  {Number(nft.currentPrice).toFixed(2)} €
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
