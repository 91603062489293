import store from "../../redux/redux";
import {
  setHideModalCloseBtn,
  setModal,
  setOnModalBack,
  setOnModalClose,
} from "../../redux/slices/appSlice";
import {
  ModalPage,
  ModalPageProps,
} from "../../_Shared/components/Modal/Modal";

export function openModal(
  props: ModalPageProps,
  hideModalCloseBtn: boolean = false,
  onBack?: Function,
  onClose?: Function
) {
  const modal = (
    <ModalPage
      content={props.content}
      small={props.small}
      style={props.style}
      cancelButton={props.cancelButton}
      className={props.className}
    />
  );

  store.dispatch(setModal(modal));
  store.dispatch(setHideModalCloseBtn(hideModalCloseBtn));
  store.dispatch(setOnModalBack(onBack));
  store.dispatch(setOnModalClose(onClose));
}

export function setModalVariables(props: {
  hideModalCloseBtn?: boolean;
  onBack?: Function;
  onClose?: Function;
}) {
  if (typeof props.hideModalCloseBtn !== "undefined")
    store.dispatch(setHideModalCloseBtn(props.hideModalCloseBtn));

  if (props.onBack) store.dispatch(setOnModalBack(props.onBack));
  if (props.onClose) store.dispatch(setOnModalClose(props.onClose));
}

export function closeModal() {
  store.dispatch(setModal(null));
}
