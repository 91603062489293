import { useTranslation } from "react-i18next";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";

import Animate from "../../components/Animate/Animate";

import landingImg from "../../../assets/img/forclients/landing.png";
import takImg from "../../../assets/img/forclients/tak.svg";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useMediaQuery } from "react-responsive";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import { useState } from "react";

import section11img from "../../../assets/img/forclients/01_Acquista tak/IMG-AcquistaTak_1-EN.png";
import section12img from "../../../assets/img/forclients/01_Acquista tak/IMG-AcquistaTak_2-EN.png";
import section13img from "../../../assets/img/forclients/01_Acquista tak/IMG-AcquistaTak_3-EN.png";
import section14img from "../../../assets/img/forclients/01_Acquista tak/IMG-AcquistaTak_4-EN.png";

import section21img from "../../../assets/img/forclients/02_Prenota tak/IMG-PrenotaTak_1-EN.png";
import section22img from "../../../assets/img/forclients/02_Prenota tak/IMG-PrenotaTak_2-EN.png";
import section23img from "../../../assets/img/forclients/02_Prenota tak/IMG-PrenotaTak_3-EN.png";

import section31img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_1-EN.png";
import section32img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_2-EN.png";
import section33img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_3-EN.png";
import section34img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_4-EN.png";
import section35img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_5-EN.png";
import section36img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_6-EN.png";
import section37img from "../../../assets/img/forclients/03_Vendi tak/IMG-VendiTak_7-EN.png";

import section41img from "../../../assets/img/forclients/04_Regala tak/IMG-RegalaTak_1-EN.png";
import section42img from "../../../assets/img/forclients/04_Regala tak/IMG-RegalaTak_2-EN.png";

import section51img from "../../../assets/img/forclients/05_Rimborso tak/IMG-RimborsoTak_1-EN.png";
import section52img from "../../../assets/img/forclients/05_Rimborso tak/IMG-RimborsoTak_2-EN.png";
import section53img from "../../../assets/img/forclients/05_Rimborso tak/IMG-RimborsoTak_3-EN.png";

interface iSection {
  title: string;
  blocks: { title: string; subtitle: string; img: string }[];
}

interface Props {
  title: string;
  sections: iSection[];
  onClose(): void;
}

function ForClientsModal({ title, sections, onClose }: Props) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [openSection, setOpenSection] = useState(-1);

  return (
    <section
      style={{
        height: "100dvh",
        width: "100vw",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 200,
      }}
      className="black-background d-flex flex-column"
    >
      <div
        style={{
          padding: isSmallScreen ? "12px" : "24px",
          borderBottom: "1px solid white",
        }}
        className="d-flex align-items-center justify-content-between"
      >
        <p className="h3 medium white-color">{title}</p>
        <Button
          icon="close"
          onClick={onClose}
          variant="blank"
          className="white-color"
        />
      </div>

      <div
        id="scroll-section-forclients-modal"
        className="h100"
        style={{ overflow: "scroll" }}
      >
        <div className="container h100">
          <div className="content h100">
            {sections.map((section, key) => {
              return (
                <div
                  onClick={() => {
                    setOpenSection(key === openSection ? -1 : key);
                    document
                      .getElementById("scroll-section-forclients-modal")
                      ?.scrollTo(0, 0);
                  }}
                  key={"section_" + key}
                  style={{
                    padding: isSmallScreen ? "40px 0 12px 0" : "80px 0 24px 0",
                    userSelect: "none",
                  }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid white",
                    }}
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                  >
                    <p className="h1 regular white-color">{section.title}</p>

                    <Button
                      icon={
                        openSection === key
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"
                      }
                      variant="blank"
                      className="white-color"
                    />
                  </div>

                  {openSection === key ? (
                    <div style={{ marginTop: "24px" }} className="w100">
                      {section.blocks.map((block, key) => {
                        return (
                          <div
                            className="d-flex align-items-center"
                            key={"block_" + key}
                            style={{
                              gap: "40px",
                              marginTop: "24px",
                              flexDirection: isSmallScreen
                                ? "column"
                                : key % 2 === 0
                                ? "row"
                                : "row-reverse",
                            }}
                          >
                            <div className="w100">
                              <p className="h4 regular white-color">
                                [{key + 1}]
                              </p>
                              <p className="h4 regular white-color">
                                {block.title}
                              </p>
                              <div className="height-24"></div>
                              <p className="h4 regular dark-grey-color">
                                {block.subtitle}
                              </p>
                            </div>
                            <div
                              style={{
                                background: `url(${block.img})`,
                                backgroundPosition: "center center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                minHeight: isSmallScreen ? "250px" : "500px",
                              }}
                              className="w100"
                            ></div>

                            <div className="height-50 only-mobile"></div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default function ForClients() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [section, setSection] = useState<"buy" | "sell" | "">("");

  const sectionsBuy: iSection[] = [
    {
      title: t("forclients.sectionsBuy.section_1.title"),
      blocks: [
        {
          title: t("forclients.sectionsBuy.section_1.blocks.block_1.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_1.blocks.block_1.subtitle"
          ),
          img: section11img,
        },
        {
          title: t("forclients.sectionsBuy.section_1.blocks.block_2.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_1.blocks.block_2.subtitle"
          ),
          img: section12img,
        },
        {
          title: t("forclients.sectionsBuy.section_1.blocks.block_3.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_1.blocks.block_3.subtitle"
          ),
          img: section13img,
        },
        {
          title: t("forclients.sectionsBuy.section_1.blocks.block_4.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_1.blocks.block_4.subtitle"
          ),
          img: section14img,
        },
      ],
    },
    {
      title: t("forclients.sectionsBuy.section_2.title"),
      blocks: [
        {
          title: t("forclients.sectionsBuy.section_2.blocks.block_1.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_2.blocks.block_1.subtitle"
          ),
          img: section21img,
        },
        {
          title: t("forclients.sectionsBuy.section_2.blocks.block_2.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_2.blocks.block_2.subtitle"
          ),
          img: section22img,
        },
        {
          title: t("forclients.sectionsBuy.section_2.blocks.block_3.title"),
          subtitle: t(
            "forclients.sectionsBuy.section_2.blocks.block_3.subtitle"
          ),
          img: section23img,
        },
      ],
    },
  ];

  const sectionsSell: iSection[] = [
    {
      title: t("forclients.sectionsSell.section_1.title"),
      blocks: [
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_1.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_1.subtitle"
          ),
          img: section31img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_2.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_2.subtitle"
          ),
          img: section32img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_3.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_3.subtitle"
          ),
          img: section33img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_4.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_4.subtitle"
          ),
          img: section34img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_5.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_5.subtitle"
          ),
          img: section35img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_6.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_6.subtitle"
          ),
          img: section36img,
        },
        {
          title: t("forclients.sectionsSell.section_1.blocks.block_7.title"),
          subtitle: t(
            "forclients.sectionsSell.section_1.blocks.block_7.subtitle"
          ),
          img: section37img,
        },
      ],
    },
    {
      title: t("forclients.sectionsSell.section_2.title"),
      blocks: [
        {
          title: t("forclients.sectionsSell.section_2.blocks.block_1.title"),
          subtitle: t(
            "forclients.sectionsSell.section_2.blocks.block_1.subtitle"
          ),
          img: section41img,
        },
        {
          title: t("forclients.sectionsSell.section_2.blocks.block_2.title"),
          subtitle: t(
            "forclients.sectionsSell.section_2.blocks.block_2.subtitle"
          ),
          img: section42img,
        },
      ],
    },
    {
      title: t("forclients.sectionsSell.section_3.title"),
      blocks: [
        {
          title: t("forclients.sectionsSell.section_3.blocks.block_1.title"),
          subtitle: t(
            "forclients.sectionsSell.section_3.blocks.block_1.subtitle"
          ),
          img: section51img,
        },
        {
          title: t("forclients.sectionsSell.section_3.blocks.block_2.title"),
          subtitle: t(
            "forclients.sectionsSell.section_3.blocks.block_2.subtitle"
          ),
          img: section52img,
        },
        {
          title: t("forclients.sectionsSell.section_3.blocks.block_3.title"),
          subtitle: t(
            "forclients.sectionsSell.section_3.blocks.block_3.subtitle"
          ),
          img: section53img,
        },
      ],
    },
  ];

  if (section === "sell")
    return (
      <ForClientsModal
        onClose={() => setSection("")}
        title={t("forclients.sectionsSell.title")}
        sections={sectionsSell}
      />
    );

  if (section === "buy")
    return (
      <ForClientsModal
        onClose={() => setSection("")}
        title={t("forclients.sectionsBuy.title")}
        sections={sectionsBuy}
      />
    );

  return (
    <main className="dark-background">
      <div
        style={{
          height: isSmallScreen ? "" : "100dvh",
          background: `url(${landingImg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: isSmallScreen ? "150px 0" : "",
        }}
      >
        <div className="container h100">
          <div className="content h100">
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-5 gap-md-1 h100">
              <div>
                <p className="h1-large regular white-color">
                  {t("forclients.section_1.title")}
                </p>
                <div className="height-24"></div>
                <p className="h4 light white-color">
                  {t("forclients.section_1.description")}
                </p>
                <div className="height-40"></div>
                <Button
                  text={t("forclients.section_1.button_text")}
                  icon="arrow_downward"
                  variant="secondary"
                  onClick={() => {
                    window.scrollBy({ top: window.innerHeight });
                  }}
                />
              </div>
              <div style={{ maxWidth: "500px" }} className="w100">
                <Animate
                  style={{ scale: isSmallScreen ? "0.8" : 1 }}
                  automatic
                  className="w100 d-flex justify-content-center"
                  element={
                    <div className="w100 d-flex justify-content-center position-relative">
                      <div
                        id="text-1"
                        style={{
                          borderRadius: "100px 100px 100px 0px",
                          padding: "16px 36px 11px 28px",
                          zIndex: 2,
                          top: "25px",
                          left: "-20px",
                        }}
                        className="position-absolute white-background shadow border medium bodytext-lg"
                      >
                        {t("forclients.section_2.text1")}
                      </div>
                      <div
                        id="text-2"
                        style={{
                          borderRadius: "100px 100px 0px 100px",
                          padding: "16px 36px 11px 28px",
                          zIndex: 2,
                          bottom: "25px",
                          right: "-20px",
                        }}
                        className="position-absolute primary-background shadow border medium bodytext-lg"
                      >
                        {t("forclients.section_2.text2")}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          maxWidth: "300px",
                          aspectRatio: "12/16",
                          borderRadius: "25px",
                          opacity: 0.8,
                        }}
                        className="white-background d-flex align-items-center justify-content-center"
                      >
                        <img style={{ width: "65%" }} src={takImg} alt="" />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="content">
          <div className="height-180"></div>
          <p className="h1-large white-color">
            {t("forclients.section_3.title")}
          </p>
          <div className="height-64"></div>

          <div
            style={{ gap: "40px" }}
            className="d-flex flex-column flex-md-row"
          >
            <div
              style={{
                padding: "24px 32px",
                border: "1px solid white",
                borderRadius: "8px",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <p className="white-color bodytext-lg medium">
                  {t("forclients.section_4.question")}
                </p>
                <div className="height-24"></div>
                <p className="white-color bodytext light">
                  {t("forclients.section_4.answer")}
                </p>
              </div>

              <div>
                <Button
                  style={{ marginTop: "auto" }}
                  icon="north_east"
                  text={t("forclients.section_4.button_text")}
                  onClick={() => setSection("buy")}
                />
              </div>
            </div>
            <div
              style={{
                padding: "24px 32px",
                border: "1px solid white",
                borderRadius: "8px",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <p className="white-color bodytext-lg medium">
                  {t("forclients.section_5.question")}
                </p>
                <div className="height-24"></div>
                <p className="white-color bodytext light">
                  {t("forclients.section_5.answer")}
                </p>
              </div>

              <div>
                <Button
                  icon="north_east"
                  text={t("forclients.section_5.button_text")}
                  onClick={() => setSection("sell")}
                />
              </div>
            </div>
          </div>

          <div className="height-180"></div>
        </div>
      </div>
    </main>
  );
}
