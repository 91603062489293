import { useState } from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import helpers from "../../../../helpers/helpers";
import { useOfferNavigation } from "../../../../hooks/hooks";
import _OffersActionsLayout from "./_OffersActionsLayout";
import OfferDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/OfferDetails";
import { openModal } from "../../../../../assets/Utils/modal";
import { DEFAULT_FEE_SPLIT } from "../../../../../config/Fees";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import { useNavigate } from "react-router-dom";

export default function OfferDecline() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer } = useOfferNavigation();

  const [isLoading, setIsLoading] = useState(false);

  const declineOfferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.declineOffer({ bidId: offer._id });

      fireTagManagerEvent("decline_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.offers.youdeclined1")}
              message={`${t("wallet.offers.youdeclined2")} ${helpers.price(
                offer.amount
              )} ${t("wallet.offers.youdeclined3")}`}
              continueClickCallback={() => navigate("/profile/offers")}
              continueBtnText={t("app.checkout.viewoffers")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_OffersActionsLayout title={t("wallet.offers.decline")}>
      <OfferDetails offer={offer} />
      <hr />

      <Button
        onClick={declineOfferClick}
        loading={isLoading}
        text={t("wallet.offers.decline")}
      />
    </_OffersActionsLayout>
  );
}
