import { useLocation, useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";
import logoSvgWhite from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";

import { selectIsWalletOpen } from "../../../redux/slices/appSlice";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { openWallet } from "../../../assets/Utils/wallet";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { LANG } from "../../../config/Lang";

export default function Nav() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const [walletText, setWalletText] = useState("");
  const { t, i18n } = useTranslation();

  const isWalletOpen = useSelector(selectIsWalletOpen);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isSmallScreen) {
      if (!user) return setWalletText(t("auth.login"));

      switch (i18n.language) {
        case LANG.it: {
          setWalletText("Wallet di " + user?.firstName);
          break;
        }
        case LANG.es: {
          setWalletText("Wallet de " + user?.firstName);
          break;
        }
        default: {
          setWalletText(user.firstName + "'s Wallet");
        }
      }
    }

    if (isSmallScreen) {
      if (!user || !user.firstName || !user.secondName)
        return setWalletText("");

      setWalletText((user.firstName[0] + user.secondName[0]).toUpperCase());
    }
  }, [isSmallScreen, user, i18n.language]);

  return (
    <nav className={`black-background`}>
      <div className="d-flex justify-content-between align-items-center px-2 px-md-4 py-2 py-md-3">
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(getLink(LINK_TYPE.APP_EXPLORE, "EXCHANGE_FULL"))
          }
        >
          {/* <img className="height-37 only-desktop" src={logoSvgBlack} alt="" /> */}
          <img className="height-28 only-" src={logoSvgWhite} alt="" />
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="height-45 only-mobile"></div>

          <>
            {pathname.includes(getLink(LINK_TYPE.APP_SAVED)) ||
            pathname.includes(getLink(LINK_TYPE.APP_EXPLORE)) ? (
              <>
                <div className="d-flex px-2 gap-2 gap-md-4 align-items-center">
                  <span
                    className={`white-color bodytext cursor-pointer 
                      ${
                        pathname.includes(getLink(LINK_TYPE.APP_EXPLORE))
                          ? "underline medium"
                          : "light"
                      }
                      `}
                    onClick={() => navigate(getLink(LINK_TYPE.APP_EXPLORE))}
                  >
                    {t("app.nav.explore")}
                  </span>

                  {user ? (
                    <span
                      className={`white-color bodytext cursor-pointer 
                  ${
                    pathname.includes(getLink(LINK_TYPE.APP_SAVED))
                      ? "underline medium"
                      : "light"
                  }
                  `}
                      onClick={() => navigate(getLink(LINK_TYPE.APP_SAVED))}
                    >
                      {t("app.nav.favorites")}
                    </span>
                  ) : null}
                </div>
              </>
            ) : null}
          </>

          {!isWalletOpen ? (
            <Button
              style={
                isSmallScreen ? { aspectRatio: "1/1", height: "40px" } : {}
              }
              size="sm"
              icon={user ? undefined : "person"}
              iconFill
              text={walletText}
              onClick={() => {
                openWallet();
                fireTagManagerEvent("open_wallet");
              }}
            />
          ) : null}
        </div>
      </div>
      {/* <div
        className="horizontal-divider only-desktop"
        style={{ margin: 0 }}
      ></div> */}
    </nav>
  );
}
