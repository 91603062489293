import SwipeController, {
  iSwipeFilter,
} from "../../../assets/Controllers/SwipeController";

import { iNft } from "../../../assets/models/iNft";

export const loadNfts3 = async (
  {
    filter,
    firstNftId,
    showMatch,
    includePartners,
    callback,
  }: {
    filter: iSwipeFilter;
    firstNftId?: string;
    showMatch?: boolean;
    includePartners?: boolean;
    callback?: Function;
  },
  index: number
): Promise<iNft> => {
  const data = await SwipeController.getNftsToSwipe2(
    {
      filter,
      firstNftId,
      showMatch,
      includePartners,
    },
    index
  );

  callback?.();

  return data;
};
