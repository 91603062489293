import store from "../../redux/redux";
import { setNftInPage } from "../../redux/slices/appSlice";

export function openNftPage(nftId: string) {
  store.dispatch(setNftInPage(nftId));
}

export function closeNftPage() {
  store.dispatch(setNftInPage(undefined));
}
