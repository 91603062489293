import { ReactNode } from "react";
import WalletPage, {
  WalletPageProps,
} from "../TakyonDesignSystem/components/Wallet/WalletPage";
import store from "../../redux/redux";
import {
  setIsWalletFullPage,
  setIsWalletOpen,
  setWalletPages,
  setWalletSection,
} from "../../redux/slices/appSlice";
import { WALLET_NAV_ELEMENT } from "../TakyonDesignSystem/components/Wallet/WalletNav";
import { sleep } from "./generic";
import { fireTagManagerEvent } from "../Services/tagmanager";

export function openWallet(page?: WALLET_NAV_ELEMENT, fullpage = false) {
  store.dispatch(setIsWalletOpen(true));
  store.dispatch(setIsWalletFullPage(fullpage));
  if (page) store.dispatch(setWalletSection(page));

  fireTagManagerEvent("navigate_wallet", { section: page });
}

export function closeWallet() {
  store.dispatch(setIsWalletFullPage(false));
  store.dispatch(setIsWalletOpen(false));
  // setTimeout(() => {
  //   closeWalletPages();
  // }, 500);
}

export function openWalletPage(props: WalletPageProps) {
  const walletPages = store.getState().app.walletPages;

  const element: { id: string; page: ReactNode; onClose?: Function } = {
    id: props.id,
    onClose: props.onClose,
    page: (
      <WalletPage
        hideGoBack={props.hideGoBack}
        id={props.id}
        title={props.title}
        subtitle={props.subtitle}
        content={props.content}
      />
    ),
  };

  if (!walletPages.find((page) => page.id === element.id))
    store.dispatch(setWalletPages([...walletPages, element]));
}

export function closeWalletPages(id?: string) {
  const walletPages = store.getState().app.walletPages;
  if (id) {
    const myWalletPage = walletPages.find((e) => e.id === id);
    const promise = myWalletPage?.onClose?.();

    setTimeout(() => {
      store.dispatch(setWalletPages(walletPages.filter((e) => e.id !== id)));
    }, 500);
    return promise;
  }

  const promise = Promise.all(walletPages.map((e) => e.onClose?.()));
  store.dispatch(setWalletPages([]));
  return promise;
}

export async function shakeWallet() {
  const wallet = document.getElementById("wallet");
  wallet?.classList.add("shake");
  await sleep(200);
  wallet?.classList.remove("shake");
}
