import { LANG } from "../../config/Lang";
import { COLLECTION_TYPE } from "./iCollection";
import { SWIPE_OUTCOME } from "./iSwipe";

export const DEFAULT_OVERPRICE = 0.03;

export enum NFT_BLOCKCHAIN_STATUS {
  TO_MINT = "TO_MINT",
  MINTING = "MINTING",
  MINTED = "MINTED",

  TO_BURN = "TO_BURN",
  BURNING = "BURNING",
  BURNED = "BURNED",

  TO_LOCK = "TO_LOCK",
  LOCKING = "LOCKING",
  LOCKED = "LOCKED",

  TO_UNLOCK = "TO_UNLOCK",
  UNLOCKING = "UNLOCKING",
  UNLOCKED = "MINTED", // use MINTED
}

export enum NFT_ACCESS_STATUS {
  REDEEMED = "REDEMEED",
  OPEN = "OPEN",
}

export enum NFT_SELL_STATUS {
  NOT_FOR_SALE = "NOT_FOR_SALE",
  FOR_SALE = "FOR_SALE",
  WAITING_PAYMENT = "WAITING_PAYMENT",
  PENDING_SALE = "PENDING_SALE", // we check if the reservation is ok, if so, we put it for sale
}

export enum NFT_OWNER_TYPE {
  ORIGINAL = "ORIGINAL",
  RESOLD = "RESOLD",
}

export type Guest = {
  firstName?: string;
  secondName?: string;
  email?: string;
};

type BlockchainTransaction = {
  type: "mint" | "lock" | "burn" | "unlock";
  id: string;
  date: Date;
};

export type payloadHotelType = {
  type?: COLLECTION_TYPE.hotel;

  checkin?: string;
  checkout?: string;
  reference?: string;
  extra?: string;

  rooms?: Array<{
    name?: string;
    guestsAdults?: number;
    guestsKids?: number;
    amenities?: string;
    extraInfo?: {
      amenities?: Array<{ name: string; data: any }>;
      features?: Array<{ text: string; icon: string }>;
      images?: Array<string>;
    };
  }>;

  note?: string;
  hotel?: string;
  stars?: string;
  website?: string;
  email?: string;

  location?: {
    label: string;
    value: any;
    address_components: object;
    coordinates?: object;
    gmt?: number;
  };

  extraInfo?: {
    hotelAmenities?: Array<{ text: string; icon: string }>;
    mapDistance?: Array<{
      title: string;
      text: string;
      marker: {
        icon: string;
        latLong: { latitude: number; longitude: number };
      };
    }>;
    poi?: Array<{
      title: string;
      text: string;
      marker: string;
    }>;
    reviews?: Array<{
      reviewScoreWithDescription: { value: string };
      submissionTimeLocalized: string;
      stayDuration: string;
      title: string;
      text: string;
    }>;
    reviewsSummary?: {
      averageOverallRating: { raw: number };
      cleanliness: { raw: number };
      hotelCondition: { raw: number };
      reviewDisclaimer: string;
      reviewDisclaimerUrl: { link: { uri: { value: string } } };
      roomComfort: { raw: number };
      serviceAndStaff: { raw: number };
      totalCount: { raw: number };
    };
  };

  [key: string]: any;
};

export type room = {
  name: string;
  services?: { icon: string; value: string; data: string }[];
  traits?: { icon: string; value: string }[];
  images?: string[];
};

export type service = { icon: string; value: string };

export type poi = {
  icon: string;
  value: string;
  coordinates: { lat: number; lng: number };
};

export type reviewSummary = {
  vote: number;
  voteText: number;
  cleanlinessVote: number;
  hotelConditionVote: number;
  roomConfortVote: number;
  serviceStaffVote: number;
};

export type review = {
  vote: number;
  voteText: number;
  date: Date;
  context: string;
  title: string;
  text: string;
};

export type hotelCensusLocationType =
  | "sea"
  | "mountain"
  | "city"
  | "lake"
  | "wellness";

export type hotelCensus = {
  type?: string;

  name?: string;
  stars?: string;
  location?: any;
  email?: string;
  locationType?: hotelCensusLocationType;
  website?: string;
  firstName?: string;
  secondName?: string;
  note?: string; // deprecated
  images?: Array<string>;

  info?: {
    [lang in LANG]?: {
      description?: string;
      services?: service[];
      rooms?: room[];
      poi?: poi[];
      reviews?: review[];
      reviewSummary?: reviewSummary;
      policies?: {
        title: string;
        text: string;
      }[];
    };
  };

  hotelsComSlug?: string;
  hotelsComId?: string;
};

export type payloadExperienceType = {
  type?: COLLECTION_TYPE.experience;

  reference?: string;
  category?: string;

  date?: string;
  duration?: number;
  participants?: number;

  description?: string;

  tour?: {
    step?: number;
    name?: string;
    location?: {
      label: string;
      value?: object;
      address_components?: object;
      coordinates?: object;
      gmt?: any;
    };
    date?: string;
  }[];

  hostName?: string;
  hostDescription?: string;

  extra?: string;
};

export interface iNft<
  nftPayloadType extends payloadHotelType | payloadExperienceType = any
> {
  _id: string;
  shortId?: string;
  payload?: nftPayloadType;
  owner?: string; // email of owner

  lang?: LANG | string;
  collectionId?: string;

  sellStatus?: NFT_SELL_STATUS;
  ownerType?: NFT_OWNER_TYPE;
  accessStatus?: NFT_ACCESS_STATUS;

  lockDate?: string;

  images?: Array<string>;

  likesCount: number;
  offersCount: number;

  originalPrice?: number;
  currentPrice?: number;
  marketPrice?: number;

  guests?: Array<Guest>;

  gold?: boolean;

  isReceivingOffersBlocked?: boolean;
  isHotelFakeSupply?: boolean;

  _collectionCensus?: hotelCensus;
  _swipeOutcome?: SWIPE_OUTCOME;
  _score?: number;
  _isInCategory?: boolean;
  _ownerName?: string;
  _fake?: boolean;
  _collectionImages?: string[];

  // web3
  blockchainStatus?: NFT_BLOCKCHAIN_STATUS;
  contractAddress?: string;
  solidityId?: number;
  blockchainTransactions?: Array<BlockchainTransaction>;
  errors?: Array<any>;
}
