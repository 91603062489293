import { useParams } from "react-router-dom";

import Swipes from "../../components/Swipes/Swipes";
import getLink, { LINK_TYPE } from "../../../config/Links";
import Saved from "../../components/Saved/Saved";
import Actions from "../../components/Actions/Actions";
import { Redirect } from "../../../assets/Utils/app";
import Partners from "../../components/Partners/Partners";
// import logoLight from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";

// import img1 from "../../../assets/img/forclients/img7.png";
// import img2 from "../../../assets/img/forclients/img2.png";
// import img3 from "../../../assets/img/forclients/img3.png";
// import img4 from "../../../assets/img/forclients/img4.png";
// import img5 from "../../../assets/img/forclients/img9.png";
// import { useEffect, useState } from "react";

// function LoadingScreen() {
//   const imgs = [img1, img2, img3, img4, img5];

//   const [img, setImg] = useState(img1);

//   useEffect(() => {
//     let i = 0;
//     const interval = setInterval(() => {
//       setImg(imgs[i]);
//       i++;
//       if (i > imgs.length - 1) i = 0;
//     }, 500);

//     setTimeout(() => {
//       clearInterval(interval);
//     }, 5000);
//   }, []);

//   return (
//     <div className="loading-screen d-flex flex-column gap-4 align-items-center justify-content-center black-background">
//       <img
//         style={{
//           mask: `url(${logoLight})`,
//           maskRepeat: "no-repeat",
//           maskSize: "100% 100%",
//         }}
//         src={img}
//         alt=""
//       />
//     </div>
//   );
// }

export default function Main() {
  const { id } = useParams();
  const { mode } = useParams();

  return (
    <main id="swipes-app-main" className="position-relative">
      {/* <LoadingScreen /> */}

      <div className="main-container">
        <div className="main-content">
          {mode === "explore" && <Swipes />}
          {mode === "saved" && <Saved />}
          {mode === "partners" && <Partners />}
          {mode === "actions" && !id ? (
            <Redirect to={getLink(LINK_TYPE.APP_EXPLORE)} />
          ) : null}
          {mode === "actions" && id && <Actions nftId={id} />}
        </div>
      </div>
    </main>
  );
}
