import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import getLink, { LINK_TYPE } from "../../../config/Links";
import {
  Flags,
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
  Retrieve,
  UserInfo,
  VerifyEmail,
} from "../../components/Auth/AuthComponents";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import UserController from "../../../assets/Controllers/UserController";
import {
  closeModal,
  openModal,
  setModalVariables,
} from "../../../assets/Utils/modal";
import NftController from "../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../assets/Utils/errors";
import Tab from "../../../assets/TakyonDesignSystem/components/Tab/Tab";
import { useTranslation } from "react-i18next";
import store from "../../../redux/redux";
import { setProfileMenu } from "../../../redux/slices/appSlice";

export default function openAuthModal(
  mode: AUTH_SECTION = AUTH_SECTION.login,
  onSuccess?: Function,
  defaultReferralCode?: string
) {
  openModal(
    {
      content: (
        <Auth
          mode={mode}
          onSuccess={onSuccess}
          defaultReferralCode={defaultReferralCode}
        />
      ),
    },
    false,
    undefined,
    () => {
      store.dispatch(setProfileMenu(false));
    }
  );
}

export enum AUTH_SECTION {
  login = "login",
  register = "register",
  forgot_password = "forgot_password",
  reset_password = "reset_password",
  verify_email = "verify_email",
  reset_password_success = "reset_password_success",
  flags = "flags",
  info = "info",
}

export function Auth({
  mode,
  onSuccess,
  defaultReferralCode,
}: {
  mode: AUTH_SECTION;
  onSuccess?: Function;
  defaultReferralCode?: string;
}) {
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const [referralCode, setReferralCode] = useState(defaultReferralCode);
  const [promocode, setPromoCode] = useState("");
  const [_, setNftRetrieveId] = useState("");
  const [nftRetrieveHash, setNftRetrieveHash] = useState("");
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const navigate = useNavigate();

  const [authMode, setAuthMode] = useState<AUTH_SECTION>(mode);

  const tabItems = [
    { name: "Accedi", value: AUTH_SECTION.login },
    { name: "Registrati", value: AUTH_SECTION.register },
  ];

  const retrieve = async () => {
    try {
      await NftController.retrieveFromEmailHash({
        hash: nftRetrieveHash,
      });

      navigate(getLink(LINK_TYPE.PROFILE));
    } catch (error) {
      apiErrorToast(error);
    }
  };

  useEffect(() => {
    if (resetPasswordToken) {
      setAuthMode(AUTH_SECTION.reset_password);
      return;
    }

    if (user) {
      if (nftRetrieveHash) retrieve();

      if (!user.emailVerified) {
        setAuthMode(AUTH_SECTION.verify_email);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      if (typeof user.marketingEmails === "undefined") {
        setAuthMode(AUTH_SECTION.flags);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      if (
        !user.firstName ||
        !user.secondName ||
        !user.phone ||
        !user.phonePrefix ||
        !user.dateOfBirth
      ) {
        setAuthMode(AUTH_SECTION.info);
        setModalVariables({
          onBack: () => {
            UserController.logout();
            setAuthMode(AUTH_SECTION.login);
            setModalVariables({ onBack: () => {} });
          },
        });
        return;
      }

      closeModal();
      onSuccess?.();
    }
  }, [user, resetPasswordToken, nftRetrieveHash]);

  // Referral code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const referralcode = params.get("referralcode");
    if (referralcode) setReferralCode(referralcode);
  }, []);

  // retrieve nft effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const nft = params.get("nftId");
    if (nft) setNftRetrieveId(nft);
    const hash = params.get("hash");
    if (hash) setNftRetrieveHash(hash);
  }, []);

  // reset password token effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const token = params.get("resetPasswordToken");
    if (token) setResetPasswordToken(token);
  }, []);

  // Promo code effect
  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const _promocode = params.get("promocode");

    if (_promocode) setPromoCode(_promocode);

    (async () => {
      try {
        if (user && promocode) {
          const credits = await UserController.redeemCredits(promocode);
          setPromoCode("");

          openModal({
            content: (
              <p>
                <h1>You just received ${credits.amount} Credits</h1>
                By using the Promo code {promocode.toUpperCase()} you received{" "}
                {credits.amount}€ in Credits usable in the Takyon App!
                <Button onClick={() => closeModal()}>Let's go!</Button>
              </p>
            ),
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user]);

  function onChangeTab(value: any) {
    setAuthMode(value);
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
        <span className="bodytext medium">{t("auth.login_register")}</span>
      </div>

      <div className="modal-body">
        <div className="px-4">
          {authMode === "login" || authMode === "register" ? (
            <>
              <div className="height-24"></div>
              <Tab size="50%" items={tabItems} onChange={onChangeTab}></Tab>
            </>
          ) : null}
        </div>
        {authMode === "login" ? (
          <Login
            onForgotPassword={() => {
              setAuthMode(AUTH_SECTION.forgot_password);
              setModalVariables({
                onBack: () => {
                  setAuthMode(AUTH_SECTION.login);
                  setModalVariables({ onBack: () => {} });
                },
              });
            }}
          />
        ) : null}
        {authMode === "register" ? (
          <Register referralCode={referralCode} />
        ) : null}
        {authMode === "verify_email" ? <VerifyEmail /> : null}
        {authMode === "flags" ? <Flags /> : null}
        {authMode === "info" ? (
          <UserInfo onBack={() => setAuthMode(AUTH_SECTION.login)} />
        ) : null}
        {authMode === "forgot_password" ? <ForgotPassword /> : null}
        {authMode === "reset_password" ? (
          <ResetPassword
            onBack={() => setAuthMode(AUTH_SECTION.login)}
            token={resetPasswordToken}
          />
        ) : null}
      </div>
    </>
  );
}
