import { useSelector } from "react-redux";

import WalletBookings from "./sub/WalletBookings";
import WalletNav, { WALLET_NAV_ELEMENT } from "./WalletNav";
import WalletOffers from "./sub/WalletOffers/WalletOffers";
import { selectUser } from "../../../../redux/slices/userSlice";
import WalletBalance from "./sub/WalletBalance";
import {
  selectIsWalletOpen,
  selectWalletPages,
  selectWalletSection,
  setlectIsWalletFullPage,
} from "../../../../redux/slices/appSlice";
import {
  closeWallet,
  closeWalletPages,
  openWallet,
  openWalletPage,
} from "../../../Utils/wallet";
import Button from "../Button/Button";
import WalletCredits from "./sub/WalletCredits";
import Icon from "../Icon/Icon";
import NothingHere from "./Components/NothingHere";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../../config/Links";
import WalletSettings from "./sub/WalletSettings";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../Services/tagmanager";
import { useEffect, useState } from "react";
import { LANG } from "../../../../config/Lang";

export function WalletHead() {
  const user = useSelector(selectUser);
  const walletPages = useSelector(selectWalletPages);
  const [walletText, setWalletText] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!user) return setWalletText("Wallet");

    switch (i18n.language) {
      case LANG.it: {
        setWalletText("Wallet di " + user?.firstName);
        break;
      }
      case LANG.es: {
        setWalletText("Wallet de " + user?.firstName);
        break;
      }
      default: {
        setWalletText(user.firstName + "'s Wallet");
      }
    }
  }, [user, i18n.language]);

  if (!user) return <></>;

  return (
    <div className="d-flex flex-column w100">
      {walletPages.find((page) => page.id === "WalletSettings") ? (
        <div className="d-flex p-3 px-4 pb-0 white-background">
          <Icon
            icon="close"
            className="cursor-pointer"
            onClick={() => {
              fireTagManagerEvent("close_wallet");
              closeWalletPages();
            }}
          />

          <div className="height-24"></div>
          <div className="text-wrap w100 text-center">
            <span className="h4 my-4 w100">
              {t("wallet.settings.settingstitle")}
            </span>
          </div>
          <div className="height-24"></div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center p-3 px-4 pb-0">
          <div className="d-flex gap-2 align-items-center">
            <Icon
              icon="close"
              className="cursor-pointer"
              onClick={() => {
                fireTagManagerEvent("close_wallet");
                closeWallet();
              }}
            />
            <p className="m-0 bodytext-sm medium">{walletText}</p>
          </div>
          <Icon
            icon="settings"
            className="cursor-pointer"
            onClick={() =>
              openWalletPage({
                hideGoBack: true,
                id: "WalletSettings",
                content: <WalletSettings />,
              })
            }
          />
        </div>
      )}
    </div>
  );
}

export default function Wallet() {
  const mode = useSelector(selectWalletSection);
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const walletPages = useSelector(selectWalletPages);
  const isOpen = useSelector(selectIsWalletOpen);
  const isFullPage = useSelector(setlectIsWalletFullPage);

  const navigate = useNavigate();

  return (
    <>
      <div
        id="wallet"
        className={`
        ${isOpen ? "open" : "close"} 
        ${isFullPage ? "fullpage" : ""}`}
      >
        <div>
          {user ? (
            <>
              <WalletHead />
              <WalletNav
                activeMenu={mode}
                onChange={(mode) => openWallet(mode)}
              />
            </>
          ) : (
            <div className="height-50">
              <Button
                onClick={() => {
                  fireTagManagerEvent("close_wallet");
                  closeWallet();
                }}
                variant="blank"
                icon="close"
                size="lg"
              />
            </div>
          )}
        </div>

        <div className="wallet-container">
          <div className="wallet-content">
            {user ? (
              <>
                {mode === WALLET_NAV_ELEMENT.bookings ? (
                  <WalletBookings />
                ) : null}
                {mode === WALLET_NAV_ELEMENT.offers ? <WalletOffers /> : null}
                {mode === WALLET_NAV_ELEMENT.balance ? <WalletBalance /> : null}
                {mode === WALLET_NAV_ELEMENT.credits ? <WalletCredits /> : null}
              </>
            ) : (
              <NothingHere
                title={t("wallet.noauth.title")}
                subtitle={t("wallet.noauth.subtitle")}
                actionText={t("wallet.noauth.action")}
                action={() => navigate(getLink(LINK_TYPE.LOGIN))}
                actionText2={t("wallet.noauth.action2")}
                action2={() => navigate(getLink(LINK_TYPE.REGISTER))}
              />
            )}
          </div>
        </div>

        {walletPages.map((page) => {
          return <div key={page.id}>{page.page}</div>;
        })}

        <div className="d-flex justify-content-center p-1">
          <span className="bodytext-sm light dark-grey-color">
            <span
              className="cursor-pointer"
              onClick={() => navigate(getLink(LINK_TYPE.TOS))}
            >
              Terms & conditions
            </span>{" "}
            -{" "}
            <span
              className="cursor-pointer"
              onClick={() => navigate(getLink(LINK_TYPE.PRIVACY))}
            >
              Privacy policy
            </span>{" "}
            -{" "}
            <span
              className="cursor-pointer"
              onClick={() => navigate(getLink(LINK_TYPE.COOCKIES))}
            >
              Cookie policy
            </span>
          </span>
        </div>
      </div>
    </>
  );
}
