import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import openOfferModal from "../../../../_Web/components/Checkout/Offer";
import { iNft } from "../../../models/iNft";

export default function TakOffersReceived({ nft }: { nft: iNft }) {
  const { t } = useTranslation();
  const offersCountText = t(
    (nft.offersCount ?? 0) === 1
      ? "tak_card.offer_received_single"
      : "tak_card.offer_received"
  );
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        openOfferModal(nft._id);
      }}
      className="tag tag-rounded bodytext-sm-xs medium text-nowrap"
    >
      <Icon icon="mark_as_unread" type="symbol" size={20} />
      {`${nft.offersCount ?? 0} ${offersCountText}`}
    </div>
  );
}
