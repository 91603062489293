import { useSelector } from "react-redux";

import {
  selectHideModalCloseBtn,
  selectModal,
  selectOnModalBack,
  selectOnModalClose,
} from "../../../redux/slices/appSlice";
import { closeModal } from "../../../assets/Utils/modal";
import { ReactNode } from "react";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";

export interface ModalPageProps {
  cancelButton?: boolean;
  small?: boolean;
  className?: string;
  style?: any;
  onBack?(): any;
  onClose?(): any;
  content: ReactNode;
}

export function ModalPage({
  content,
  small,
  className,
  style,
}: ModalPageProps) {
  const hideModalCloseBtn = useSelector(selectHideModalCloseBtn);

  const onClose = useSelector(selectOnModalClose);
  const onBack = useSelector(selectOnModalBack);

  return (
    <section
      style={style ?? {}}
      className={`modal-content white-background rounded ${
        small ? "mw-500" : ""
      } ${className}`}
    >
      {hideModalCloseBtn ? null : (
        <>
          <Icon
            onClick={() => {
              closeModal();
              onClose?.();
            }}
            size={25}
            icon="close"
            className="close-modal"
          />
        </>
      )}
      {onBack ? (
        <>
          <Icon
            onClick={() => {
              onBack();
            }}
            size={25}
            icon="arrow_back"
            className="back-modal"
          />
        </>
      ) : null}
      {content}
    </section>
  );
}

export default function Modal() {
  const modal = useSelector(selectModal);

  if (!modal) return <></>;

  return (
    <div id="modal-container">
      <div className="modal-middle">
        <>{modal}</>
        {/* <div onClick={(e) => e.stopPropagation()}>{modal}</div> */}
      </div>
    </div>
  );
}
