import { useEffect, useState } from "react";
import NewFavoriteList from "../Favorites/NewFavoriteList";
import FavoritesController from "../../../assets/Controllers/FavoritesController";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import iFavoriteList from "../../../assets/models/iFavoriteList";
import FavoritesLists from "../Favorites/FavoritesLists";
import { closeModal, setModalVariables } from "../../../assets/Utils/modal";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import {
  x_SMALL_SCREEN,
  LARGE_SCREEN,
  LARGE_SHORT_SCREEN,
  SMALL_SCREEN,
} from "../../../assets/Utils/generic";

interface Props {
  nftId: string;
  userId: string;
  onFinish?: () => void;
}

export default function Favorites({ nftId, userId, onFinish }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [favorites, setFavorites] = useState<iFavoriteList[]>([]);
  const [editMode, setEditMode] = useState(false);
  const isTinyScreen = useMediaQuery({ query: x_SMALL_SCREEN });
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (min-height: 583px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 769px) and (min-height: 700px)",
  });

  const getPaddingValue = () => {
    if (isTinyScreen) return "2px";
    if (isSmallScreen) return "20px";
    if (isMediumScreen) return "35px";
    if (isLargeScreen) return "45px";
  };

  const paddingValue = getPaddingValue();

  const loadCustomerFavorites = async () => {
    try {
      setIsLoading(true);

      const data = await FavoritesController.getLists(userId);
      setFavorites(data.data);

      // if no favorites list enter in edit mode (to add new list)
      if (data.data.length === 0) {
        setEditMode(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.log("FavoritesModal - loadCustomerFavorites error", error);
      setIsLoading(false);
    }
  };

  const addFavToExistingList = async (listId: string) => {
    try {
      setIsLoading(true);
      await FavoritesController.addFavToList(listId, userId, nftId);
      setIsLoading(false);
      closeModal();
      onFinish && onFinish();
    } catch (error) {
      console.log("addFavToExistingList error", error);
    }
  };

  const onCreated = (favList: any) => {
    setEditMode(false);

    // reload use favorites list
    loadCustomerFavorites();
  };

  useEffect(() => {
    loadCustomerFavorites();
    return () => {
      console.log("FavoritesModal Unmounted");
    };
  }, []);

  return (
    <>
      {editMode ? (
        <>
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span className="bodytext medium">
              {t("favorites.modal.newlist_title")}
            </span>
          </div>
          <NewFavoriteList
            nftId={nftId}
            userId={userId}
            onCreated={onCreated}
          />
        </>
      ) : (
        <>
          {/* favorites list */}
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span className="bodytext medium">
              {t("favorites.modal.title")}
            </span>
          </div>
          {!isLoading ? (
            <div className="modal-padding modal-body py-3">
              <FavoritesLists
                userId={userId}
                onItemClick={addFavToExistingList}
              />
            </div>
          ) : (
            <div
              className="d-flex py-3 border-bottom"
              style={{
                height: "calc(100% - 55px)",
                paddingLeft: paddingValue,
              }}
            >
              <div className="height-24"></div>
              <div className="favorites-list d-flex gap-2 flex-wrap">
                <Skeleton width={200} height={200}></Skeleton>
                <Skeleton width={200} height={200}></Skeleton>
              </div>
            </div>
          )}

          {/* footer */}
          <div className="d-flex align-items-center justify-content-end py-2 px-4 border-top">
            <Button
              loading={isLoading}
              variant="secondary"
              size="sm"
              text={t("favorites.new_list.add")}
              onClick={() => {
                setEditMode(true);

                setModalVariables({
                  onBack: () => {
                    setEditMode(false);
                    setModalVariables({});
                  },
                });
              }}
            ></Button>
          </div>
        </>
      )}
    </>
  );
}
