import { useState } from "react";
import { iNft } from "../../../models/iNft";
import { datetimeRangeToString, SMALL_SCREEN } from "../../../Utils/generic";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../redux/slices/userSlice";
import { SWIPE_OUTCOME } from "../../../models/iSwipe";
import { getCityFromLocation } from "../../../Utils/location";
// import { LikeButton } from "./NftCard";

interface Props {
  nft: iNft;
  onClick?(nft: iNft): void;
  onLike?(nft: iNft): void;
}

export default function NftCardFav({ onClick, onLike, nft }: Props) {
  const user = useSelector(selectUser);
  const [outcome, setOutcome] = useState(nft?._swipeOutcome);

  return (
    <div>
      <div className="nft-card-fav" onClick={() => onClick?.(nft)}>
        <img
          style={{
            borderRadius: "15px",
            objectFit: "cover",
            width: "230px",
            height: "230px",
          }}
          // className="w100 h100"
          src={nft.images?.[0]}
          alt=""
        />

        <div className="nft-info-tag price z-3">
          <span className="bodytext-sm regular white-color">
            €{(nft.currentPrice ?? nft.marketPrice)?.toFixed(2)}
          </span>
        </div>

        <div className="nft-info-tag date">
          <span className="bodytext-sm regular white-color">
            {datetimeRangeToString(
              nft.payload?.checkin,
              nft.payload?.checkout,
              nft.payload?.location?.gmt,
              false
            )}
          </span>
        </div>

        {!nft._fake ? (
          <div
            className="position-absolute d-flex flex-column gap-3"
            style={{
              bottom: "-10px",
              right: "-10px",
              padding: "22px",
              zIndex: 2,
            }}
          >
            <div
              style={{
                animationDelay: "0.2s",
                transform: "scale(1.1)",
              }}
            >
              {/* <LikeButton
                onClick={(e) => {
                  e.stopPropagation();
                  onLike?.(nft);

                  if (!user) return;
                  if (outcome === SWIPE_OUTCOME.GOOD)
                    setOutcome(SWIPE_OUTCOME.NEUTRAL);
                  else setOutcome(SWIPE_OUTCOME.GOOD);
                }}
                liked={outcome === SWIPE_OUTCOME.GOOD}
              /> */}
            </div>
          </div>
        ) : null}
      </div>

      <div className="height-12"></div>

      <div className="white-color">{nft.payload?.hotel}</div>

      <div className="regular m-0 bodytext medium dark-grey-color">
        {getCityFromLocation(
          nft._collectionCensus?.location ?? nft.payload?.location
        )}{" "}
      </div>
    </div>
  );
}
