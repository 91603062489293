import Skeleton from "react-loading-skeleton";

export default function WalletSkeletonLoader() {
  return (
    <div className="w100 d-flex gap-1">
      <div className="w100">
        <Skeleton count={4} height={250} className="w100" />
      </div>
    </div>
  );
}
