import { t } from "i18next";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NftController from "../../../../assets/Controllers/NftController";
import { fireTagManagerEvent } from "../../../../assets/Services/tagmanager";
import TakCard from "../../../../assets/TakyonDesignSystem/components/TakCard/TakCard";
import { apiErrorToast } from "../../../../assets/Utils/errors";
import {
  iNft,
  NFT_ACCESS_STATUS,
  NFT_SELL_STATUS,
} from "../../../../assets/models/iNft";
import { selectUser } from "../../../../redux/slices/userSlice";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useNavigate } from "react-router-dom";
import helpers from "../../../helpers/helpers";
import Stars from "../../../../assets/TakyonDesignSystem/components/Stars/Stars";
import { openModal } from "../../../../assets/Utils/modal";
import TakCreditRefund from "./Actions/TakCreditRefund";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";
import { useMediaQuery } from "react-responsive";
import {
  getCityFromLocation,
  getCityShortFromLocation,
  getRegionFromLocation,
  getCountryFromLocation,
  getAmountOfGuests,
} from "../../../../assets/Utils/location";
import TakOnSaleHeader from "../../../../assets/TakyonDesignSystem/components/TakDeal/TakOnSaleHeader";

export function TakListActive() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [nfts, setNfts] = useState<iNft[]>([]);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const loadNfts = async (status: string) => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated(
        {
          page: 1,
          size: 500,
          query: JSON.stringify({
            owner: user?.email,
            accessStatus: status,
          }),
          sort: JSON.stringify({
            _lockDate: 1,
          }),
        },
        false
      );
      setNfts([...data.data]);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && user.email) {
      loadNfts(NFT_ACCESS_STATUS.OPEN);
    }
  }, [user]);

  if (isLoading) return <div>Loading...</div>;

  if (nfts.length === 0)
    return (
      <span className="bodytext regular dark-grey-color">
        {t("wallet.bookings.noresultstext")}
      </span>
    );

  return (
    <section className="w100 d-flex flex-column gap-3">
      {/* initially a deal has only one bid/offer */}
      {nfts.map((nft) => (
        <div
          className={`d-flex flex-column flex-lg-row gap-3 w100`}
          key={nft._id}
        >
          <div
            style={{
              minWidth: isSmallScreen ? "100%" : "250px",
            }}
          >
            <TakCard
              style={{ maxHeight: "472px" }}
              preventAspectRatio={isSmallScreen}
              nft={nft}
              small={true}
            />
          </div>

          <div
            className={`w-100 d-flex flex-column ${
              nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? "bg-light-grey" : ""
            }`}
          >
            {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? (
              <div
                className="h-100 d-flex flex-column justify-content-between"
                style={{ padding: isSmallScreen ? "24px" : "32px 40px" }}
              >
                <div>
                  <TakOnSaleHeader nft={nft} />
                  <hr className="my-3" />
                  <div className="bodytext light">
                    <div className="d-flex bodytext">
                      <div className="flex-grow-1 light">
                        {t("Numero Tak")}:
                      </div>
                      <div className="medium">
                        {nft?.shortId ? "#" + nft?.shortId : null}
                      </div>
                    </div>
                    <div className="height-12" />
                    <div className="d-flex bodytext">
                      <div className="flex-grow-1 light">
                        {t("Offerte attive")}:
                      </div>
                      <div className="medium">{nft?.offersCount}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="my-3" />
                  <div className="tak-actions d-flex gap-3 flex-column flex-xl-row">
                    <Button
                      variant="secondary"
                      text={t("wallet.bookings.changeprice")}
                      onClick={() => {
                        fireTagManagerEvent("click_change_price", {
                          item_id: nft._id,
                        });

                        navigate("/profile/wallet/change-price", {
                          state: nft,
                        });
                      }}
                    />
                    <Button
                      onClick={() => {
                        fireTagManagerEvent("click_remove_from_sale", {
                          item_id: nft._id,
                        });

                        navigate("/profile/wallet/remove-from-sale", {
                          state: nft,
                        });
                      }}
                      variant="secondary"
                      text={t("wallet.bookings.removesale")}
                    />
                  </div>
                </div>
              </div>
            ) : (
              // not yet in sale
              <>
                <div className="bodytext light h-100">
                  <p className="m-0 medium text-nowrap text-ellipsis">
                    {getCityFromLocation(nft._collectionCensus?.location)} (
                    {getCityShortFromLocation(nft._collectionCensus?.location)},{" "}
                    {getRegionFromLocation(nft._collectionCensus?.location)},{" "}
                    {getCountryFromLocation(nft._collectionCensus?.location)})
                  </p>
                  <div className="m-0 d-flex align-items-center gap-2">
                    <p className="m-0">{nft._collectionCensus?.name ?? ""}</p>

                    {nft._collectionCensus?.stars ? (
                      <Stars stars={nft._collectionCensus?.stars} />
                    ) : null}
                  </div>
                  <p className="m-0">
                    {helpers.takPeriodDates(
                      nft.payload?.checkin!,
                      nft.payload?.checkout!
                    )}
                  </p>
                  <p className="m-0">
                    {getAmountOfGuests(nft).adults +
                      getAmountOfGuests(nft).childs}{" "}
                    {t("wall.guests")}
                  </p>
                  <div>
                    {t("wallet.generic.originallypurchased")}{" "}
                    <span className="h4">
                      {helpers.price(nft.originalPrice!)}
                    </span>
                  </div>

                  <div className="height-24"></div>
                </div>

                <div className="tak-actions d-flex gap-3">
                  {nft.sellStatus === NFT_SELL_STATUS.PENDING_SALE ? (
                    <p className="bodytext-regular">
                      {t("wallet.bookings.pending_sale")}
                    </p>
                  ) : null}

                  {nft.sellStatus === NFT_SELL_STATUS.NOT_FOR_SALE ? (
                    <div className="d-flex flex-column flex-xl-row gap-2 w100">
                      <Button
                        text={t("wallet.bookings.putsale")}
                        className={isSmallScreen ? "w100" : ""}
                        onClick={() => {
                          navigate("/profile/wallet/put-on-sale", {
                            state: nft,
                          });
                          // TODO - devo fare il tag manager?
                          // fireTagManagerEvent("click_put_on_sale", {
                          //   item_id: nft._id,
                          // });
                        }}
                      />
                      <Button
                        className={isSmallScreen ? "w100" : ""}
                        text={t("wallet.bookings.transfer")}
                        variant="secondary"
                        onClick={() => {
                          navigate("/profile/wallet/transfer-gift", {
                            state: nft,
                          });
                          // TODO - devo fare il tag manager?
                          // fireTagManagerEvent("click_transfer", {
                          //   item_id: nft._id,
                          // });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </section>
  );
}
