import { useEffect, useState } from "react";

import { apiErrorToast } from "../../../../../Utils/errors";
import OfferController from "../../../../../Controllers/OfferController";
import { DEFAULT_PAGINATION_SIZE } from "../../../../../models/iPagination";
import { BID_MODE, BID_STATUS, iBid } from "../../../../../models/iBid";
import { datetimeToString } from "../../../../../Utils/generic";
import { openWalletPage } from "../../../../../Utils/wallet";
import Button from "../../../Button/Button";
import {
  WalletAcceptOffer,
  WalletCancelOffer,
  WalletCounteroffer,
  WalletCounterofferAccept,
  WalletCounterofferDecline,
  WalletDeclineOffer,
} from "./sub/WalletOffersComponents";
import { WalletCheckout } from "../../../../../../_Web/components/Checkout/WalletCheckout";
import NothingHere from "../../Components/NothingHere";
import WalletSkeletonLoader from "../../Components/WalletSkeletonLoader";
import BookingHeader from "../BookingHeader";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../../../Services/tagmanager";

interface WalletOfferPageProps {
  offersByNft: iBid[];
  onCloseHandle?: any;
}

function WalletOfferPage({ offersByNft }: WalletOfferPageProps) {
  const { t } = useTranslation();

  // filter offers
  const sortedOffers = [...offersByNft].sort(
    (a, b) =>
      new Date(b._createdAt).getTime() - new Date(a._createdAt).getTime()
  );

  // render by offers status
  const offerStatusMessages = {
    [BID_STATUS.PENDING]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="medium">
          {offer._side === "seller"
            ? t("wallet.offers.receivedof")
            : t("wallet.offers.sentof")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)}{" "}
        </div>
      </div>
    ),
    [BID_STATUS.ACCEPTED]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {offer._side === "seller"
            ? t("wallet.offers.youaccepted")
            : t("wallet.offers.youraccepted")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)} {t("wallet.offers.hasaccepted")}
          {offer._side === "seller"
            ? t("wallet.offers.buyertime")
            : t("wallet.offers.sellertime")}
        </div>
      </div>
    ),
    [BID_STATUS.DECLINED]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {offer._side === "seller"
            ? t("wallet.offers.youdeclined2")
            : t("wallet.offers.buyerdeclined1")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)}{" "}
          {offer._side === "seller" ? "" : t("wallet.offers.buyerdeclined2")}{" "}
        </div>
      </div>
    ),
    [BID_STATUS.DELETED]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {t("wallet.offers.thisoffer")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)} {t("wallet.offers.hasdecliend")}
        </div>
      </div>
    ),
    [BID_STATUS.EXPIRED]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="bodytext-sm">
          {t("wallet.offers.thisoffer")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)} {t("wallet.offers.hasexpired")}
        </div>
      </div>
    ),
    [BID_STATUS.FULFILLED]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {offer._side === "seller"
            ? t("wallet.offers.yousold")
            : t("wallet.offers.youbought")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.amount).toFixed(2)}
        </div>
      </div>
    ),
    [BID_STATUS.BUYER_COUNTEROFFER]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {offer._side === "seller"
            ? t("wallet.offers.sellercounteroffer")
            : t("wallet.offers.buyercounteroffer")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.counterOfferAmount).toFixed(2)}
        </div>
      </div>
    ),
    [BID_STATUS.SELLER_COUNTEROFFER]: (offer: iBid) => (
      <div className="d-flex flex-column gap-2">
        <div className="bodytext-sm dark-grey-color">
          {datetimeToString(offer._createdAt, undefined, undefined, true)}
        </div>
        <div className="d-flex flex-column medium">
          {offer._side === "seller"
            ? t("wallet.offers.buyercounteroffer")
            : t("wallet.offers.sellercounteroffer")}{" "}
          {offer.mode === BID_MODE.CREDITS
            ? `${t("wallet.creditsmenu")} `
            : "€"}
          {Number(offer.counterOfferAmount).toFixed(2)}
        </div>
      </div>
    ),
  };

  const renderOfferStatus = (offer: iBid) => {
    const statusContent = offerStatusMessages[offer.status];
    return statusContent ? statusContent(offer) : null;
  };

  return (
    <div className="h100 p-4">
      <div className="d-flex flex-column h100">
        {offersByNft[0]._nft ? (
          <BookingHeader nft={offersByNft[0]._nft} />
        ) : null}

        <div className="h100">
          {sortedOffers.map((offer, key) => {
            return (
              <div key={"wallet_offer_page" + key}>
                {renderOfferStatus(offer)}

                <div className="height-16"></div>

                <div className="d-flex flex-wrap gap-2">
                  {offer.status === BID_STATUS.PENDING &&
                  offer._side === "seller" ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id: "wallet_offer_page_accept_offer" + offer._id,
                          content: (
                            <WalletAcceptOffer mode="accept" offer={offer} />
                          ),
                        })
                      }
                      text={t("wallet.offers.accept")}
                      size="sm"
                      className="w100"
                    ></Button>
                  ) : null}

                  {offer.status === BID_STATUS.ACCEPTED &&
                  offer._side === "buyer" ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id: "wallet_page_checkout" + offer._id,
                          content: <WalletCheckout nft={offer._nft!} />,
                        })
                      }
                      text={t("wallet.offers.checkout")}
                      size="sm"
                    ></Button>
                  ) : null}

                  {offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
                  offer._side === "seller" ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id:
                            "wallet_offer_page_accept_counteroffer" + offer._id,
                          content: (
                            <WalletCounterofferAccept
                              mode="accept"
                              offer={offer}
                            />
                          ),
                        })
                      }
                      text={t("wallet.offers.acceptcounter")}
                      size="sm"
                      className="w100"
                    ></Button>
                  ) : null}

                  {offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
                  offer._side === "buyer" ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id:
                            "wallet_offer_page_accept_counteroffer" + offer._id,
                          content: (
                            <WalletCounterofferAccept
                              mode="accept"
                              offer={offer}
                            />
                          ),
                        })
                      }
                      text={t("wallet.offers.acceptcounter")}
                      size="sm"
                      className="w100"
                    ></Button>
                  ) : null}

                  <div className="d-flex gap-2 w100">
                    {offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
                    offer._side === "buyer" ? (
                      <Button
                        onClick={() =>
                          openWalletPage({
                            id:
                              "wallet_offer_page_create_counteroffer" +
                              offer._id,
                            content: (
                              <WalletCounteroffer
                                mode="counteroffer"
                                offer={offer}
                              />
                            ),
                          })
                        }
                        text={t("wallet.offers.makecounter")}
                        variant="secondary"
                        size="sm"
                        className="w100"
                      ></Button>
                    ) : null}

                    {(offer.status === BID_STATUS.PENDING ||
                      offer.status === BID_STATUS.BUYER_COUNTEROFFER) &&
                    offer.acceptCounterOffer &&
                    offer._side === "seller" ? (
                      <Button
                        onClick={() =>
                          openWalletPage({
                            id:
                              "wallet_offer_page_create_counteroffer" +
                              offer._id,
                            content: (
                              <WalletCounteroffer
                                mode="counteroffer"
                                offer={offer}
                              />
                            ),
                          })
                        }
                        text={t("wallet.offers.makecounter")}
                        variant="secondary"
                        size="sm"
                        className="w100"
                      ></Button>
                    ) : null}

                    {offer.status === BID_STATUS.PENDING &&
                    offer._side === "seller" ? (
                      <Button
                        onClick={() =>
                          openWalletPage({
                            id: "wallet_offer_page_decline_offer" + offer._id,
                            content: (
                              <WalletDeclineOffer
                                mode="decline"
                                offer={offer}
                              />
                            ),
                          })
                        }
                        text={t("wallet.offers.decline")}
                        variant="secondary"
                        size="sm"
                        className="w100"
                      ></Button>
                    ) : null}

                    {(offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
                      offer._side === "seller") ||
                    (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
                      offer._side === "buyer") ? (
                      <Button
                        onClick={() =>
                          openWalletPage({
                            id: "wallet_offer_page_decline_offer" + offer._id,
                            content: (
                              <WalletCounterofferDecline
                                mode="decline"
                                offer={offer}
                              />
                            ),
                          })
                        }
                        text={t("wallet.offers.decline")}
                        variant="secondary"
                        size="sm"
                        className="w100"
                      ></Button>
                    ) : null}
                  </div>

                  {(offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
                    offer._side === "buyer") ||
                  (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
                    offer._side === "seller") ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id: "wallet_offer_page_decline_offer" + offer._id,
                          content: (
                            <WalletCounterofferDecline
                              mode="decline"
                              offer={offer}
                            />
                          ),
                        })
                      }
                      text={t("wallet.offers.cancel")}
                      variant="secondary"
                      size="sm"
                    ></Button>
                  ) : null}

                  {offer.status === BID_STATUS.PENDING &&
                  offer._side === "buyer" ? (
                    <Button
                      onClick={() =>
                        openWalletPage({
                          id: "wallet_offer_page_cancel_offer" + offer._id,
                          content: (
                            <WalletCancelOffer offer={offer} mode="cancel" />
                          ),
                        })
                      }
                      text={t("wallet.offers.cancel")}
                      variant="secondary"
                      size="sm"
                    ></Button>
                  ) : null}
                </div>

                <div className="height-8"></div>

                <div className="bodytext-xs regular">
                  {t("wallet.offers.expires")}{" "}
                  {datetimeToString(
                    offer.expireDate,
                    undefined,
                    undefined,
                    true
                  )}
                </div>

                {key !== sortedOffers.length - 1 ? <hr /> : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default function WalletOffers() {
  const [isLoading, setIsLoading] = useState(false);

  const [page] = useState(1);
  const [size] = useState(DEFAULT_PAGINATION_SIZE);

  const { t } = useTranslation();

  const [nftOffersMap, setNftOffersMap] = useState<Record<string, iBid[]>>({});

  const loadOffers = async () => {
    setIsLoading(true);
    try {
      const offers = await OfferController.getPaginated({ page, size });

      setNftOffersMap((prevMap) => {
        const updatedMap: Record<string, iBid[]> = { ...prevMap };

        offers.data.forEach((offer) => {
          const nftId = offer._nft?._id || "unknown";

          updatedMap[nftId] = [...(updatedMap[nftId] || []), offer];
        });

        return updatedMap;
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  const loadAllOffers = async () => {
    setIsLoading(true);
    try {
      setNftOffersMap({});
      await loadOffers();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadAllOffers();
  }, []);

  // sort offers
  const filterAndSortOffers = (offers: any) => {
    const activeOffers = offers.filter(
      (offer: any) =>
        offer.status === BID_STATUS.PENDING ||
        offer.status === BID_STATUS.BUYER_COUNTEROFFER ||
        offer.status === BID_STATUS.SELLER_COUNTEROFFER ||
        offer.status === BID_STATUS.ACCEPTED
    );

    return {
      activeOffers,
      pendingOffersCount: activeOffers.length,
    };
  };

  const sortedNftOffers = Object.entries(nftOffersMap).sort(
    ([, offersA], [, offersB]) => {
      const { activeOffers: activeOffersA } = filterAndSortOffers(offersA);
      const { activeOffers: activeOffersB } = filterAndSortOffers(offersB);

      return activeOffersB.length - activeOffersA.length;
    }
  );

  return (
    <section className="w100 p-3">
      {!sortedNftOffers.length && !isLoading ? (
        <>
          <NothingHere
            title={t("wallet.offers.nooffers")}
            subtitle={t("wallet.offers.noofferstext")}
          />
        </>
      ) : null}

      {isLoading ? <WalletSkeletonLoader /> : null}

      {sortedNftOffers.map(([nftId, offers]) => {
        const nft = offers[0]._nft!;
        const { pendingOffersCount } = filterAndSortOffers(offers);

        return (
          <BookingHeader
            customActions={
              <>
                <div className="d-flex align-items-end">
                  <div className="d-flex flex-column w100">
                    <div className="d-flex gap-md-1 flex-column flex-md-row">
                      <p className="m-0 bodytext-sm regular light-grey-color">
                        {t("wallet.offers.lastoffer")}
                      </p>
                      <p className="m-0 bodytext-sm regular light-grey-color">
                        {datetimeToString(offers[0]._createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex w100 align-items-end">
                    <Button
                      size="sm"
                      className="w100"
                      onClick={() => {
                        openWalletPage({
                          id: "wallet_offer_page_" + nftId,
                          onClose: loadAllOffers,
                          content: <WalletOfferPage offersByNft={offers} />,
                        });

                        fireTagManagerEvent("click_view_offers", {
                          item_id: nft._id,
                        });
                      }}
                      variant={
                        pendingOffersCount === 0 ? "secondary" : "primary"
                      }
                      // notification={pendingOffersCount}
                      text={t("wallet.offers.check")}
                    />
                  </div>
                </div>
              </>
            }
            nft={nft}
          />
        );
      })}
    </section>
  );
}
