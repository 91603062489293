import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TakCard from "../TakCard/TakCard";
import { apiErrorToast } from "../../../Utils/errors";

import { iWallSlot } from "../../../../config/wall";
import NftController from "../../../Controllers/NftController";
import Skeleton from "react-loading-skeleton";
import { iNft } from "../../../models/iNft";
import Icon from "../Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";
import { useSelector } from "react-redux";
import { selectTakWidth, setTakWidth } from "../../../../redux/slices/appSlice";
import { useAppDispatch } from "../../../../redux/redux";

const MIN_TAK_WIDTH = 300;

interface ChevronButtonProps {
  direction: "left" | "right";
  onClick: Function;
}

function ChevronButton({ direction, onClick }: ChevronButtonProps) {
  return (
    <div
      className="height-40 width-40 d-flex align-items-center justify-content-center m-3 cursor-pointer"
      style={{
        pointerEvents: "all",
        border: "1px solid white",
        borderRadius: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      }}
      onClick={() => onClick()}
    >
      <Icon className="white-color" icon={`chevron_${direction}`} size={30} />
    </div>
  );
}

export function TakCarousel({
  nfts,
  scroll,
  isLoading,
}: {
  nfts: iNft[];
  scroll?: boolean;
  isLoading?: boolean;
}) {
  const takWidth = useSelector(selectTakWidth);
  const dispatch = useAppDispatch();

  const takScrollContainer = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  useEffect(() => {
    const run = () => {
      const w = takScrollContainer.current?.offsetWidth;
      if (!w) return;
      const howManyTakPerView = Math.floor(w / MIN_TAK_WIDTH);
      const _takWidth = w / howManyTakPerView;
      if (takWidth !== _takWidth) dispatch(setTakWidth(_takWidth));

      if (nfts.length) setShowArrows(howManyTakPerView < nfts.length);
    };

    run();

    setInterval(() => {
      run();
    }, 150);
  }, [nfts]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isSmallScreen && scroll && showArrows && takWidth ? (
        <div
          className="d-flex h100 justify-content-between align-items-center"
          style={{
            position: "absolute",
            zIndex: 100,
            pointerEvents: "none",
            width: "calc(100% + 125px)",
          }}
        >
          <ChevronButton
            direction="left"
            onClick={() => {
              takScrollContainer.current?.scrollBy({
                left: -takWidth,
                behavior: "smooth",
              });
            }}
          />
          <ChevronButton
            direction="right"
            onClick={() => {
              takScrollContainer.current?.scrollBy({
                left: takWidth,
                behavior: "smooth",
              });
            }}
          />
        </div>
      ) : null}

      <div className="position-relative w100 h100 overflow-hidden">
        <div
          ref={takScrollContainer}
          className="d-flex"
          style={{
            overflowX: "scroll",
            overflowY: "hidden",
            flexDirection: isSmallScreen ? "column" : "row",
            flexWrap: scroll ? "unset" : "wrap",
          }}
        >
          {isLoading ? (
            <>
              {Array(4)
                .fill(0)
                .map((item, key) => (
                  <div
                    style={{
                      maxWidth: isSmallScreen ? "100%" : takWidth,
                      minWidth: isSmallScreen ? "100%" : takWidth,
                    }}
                    key={`takcard_${key}`}
                    className="p-2"
                  >
                    <Skeleton
                      style={{
                        opacity: "0.5",
                        width: "100%",
                        aspectRatio: "8/11",
                      }}
                    />
                  </div>
                ))}
            </>
          ) : null}

          {!isLoading ? (
            <>
              {nfts.map((item, key) => (
                <div
                  style={{
                    maxWidth: isSmallScreen ? "100%" : takWidth,
                    minWidth: isSmallScreen ? "100%" : takWidth,
                  }}
                  key={`takcard_${key}`}
                  className="p-2"
                >
                  <TakCard nft={item} key={`slot_${key}`} />
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface Props {
  slot?: iWallSlot;
}

export default function TakContainer(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [nfts, setNfts] = useState<iNft[]>([]);

  const scroll = (props.slot?.config.payload as any).scroll ? true : false;

  const loadPayload = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated({
        page: 1,
        size: (props.slot?.config.payload as any).limit,
        sort: JSON.stringify((props.slot?.config.payload as any).sort),
        query: JSON.stringify((props.slot?.config.payload as any).query),
      });

      setNfts(data.data);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPayload();
  }, []);

  return (
    <>
      <div className="text-white mt-5 mb-5">
        <h2 className="h1 regular m-0">{t(props.slot?.title ?? "")}</h2>
        <div className="height-32"></div>
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
        <TakCarousel isLoading={isLoading} scroll={scroll} nfts={nfts} />
      </div>
    </>
  );
}
