import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { openModal } from "../../../assets/Utils/modal";
import Animate from "../../components/Animate/Animate";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";

import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { ImgPartner } from "../ForHotels/ForHotels";

import l1 from "../../../assets/img/forexperience/SNOWAVE_logo_Bianco.png";
import l2 from "../../../assets/img/forexperience/cicero_logo.png";
import l3 from "../../../assets/img/forexperience/ingondola_logo.png";

import p1 from "../../../assets/img/forpartners/Snowave.png";
import p2 from "../../../assets/img/forpartners/Cicero.png";
import p3 from "../../../assets/img/forpartners/InGondola.png";
import headerImg from "../../../assets/img/forpartners/Experience_header.png";

export default function ForExperience({
  title1,
  title2,
  title3,
  title4,
  titleClass,
  cobrandingImg,
}: {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  titleClass?: string;
  cobrandingImg?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <main id="web-page">
      <section
        style={{
          height: isSmallScreen ? "unset" : "100vh",
          background: `url(${headerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        className="web-section-container w100 position-relative"
      >
        {/* <div
          style={{ top: 0, bottom: 0, left: 0, right: 0, opacity: 0.7 }}
          className="position-absolute black-background"
        ></div> */}

        <div style={{ zIndex: 2 }} className="container h100">
          <div className="content h100">
            <div className="height-100 only-mobile"></div>
            <div className="d-flex h100 align-items-center justify-content-center">
              <div className="d-md-flex" style={{ gap: "50px" }}>
                <div className="white-color w100">
                  {cobrandingImg ? (
                    <div
                      style={{
                        width: "200px",
                        zIndex: 2,
                      }}
                    >
                      <img className="w100" src={cobrandingImg} alt="" />
                      <div className="height-25"></div>
                    </div>
                  ) : null}
                  <Animate
                    element={
                      <h1
                        className={`m-0 regular
                      ${titleClass ?? "h1-large"} 
                      `}
                      >
                        {title1 ?? t("forexperience.section_1.1")}{" "}
                        <i>
                          {title2} {title3}
                        </i>
                      </h1>
                    }
                    automatic
                    delay={0}
                  />
                  <div className="height-20"></div>
                  <Animate
                    element={
                      <>
                        <p className="m-0 bodytext light">
                          {t("forexperience.section_1.2")}
                        </p>
                        <p className="m-0 bodytext light">
                          {t("forexperience.section_1.3")}
                        </p>
                      </>
                    }
                    automatic
                    delay={0.3}
                  />
                  <div className="height-40"></div>

                  <Animate
                    element={
                      <Button
                        text={t("forexperience.become_button")}
                        icon="group"
                        iconFill
                        onClick={() => {
                          openModal({
                            content: <BecomePartner />,
                          });
                        }}
                      />
                    }
                    automatic
                    delay={0.6}
                  />
                </div>

                <div className="height-80 only-mobile"></div>
              </div>
            </div>
            <div className="height-50 only-mobile"></div>
          </div>
        </div>
      </section>

      <div className="height-100"></div>

      <div className="container">
        <div className="content">
          <span className="h1-large regular white-color">
            {t("forexperience.section_1.3")}
          </span>

          <div className="height-50"></div>

          <div className="d-flex flex-column flex-lg-row">
            <ImgPartner
              index={0}
              img={p1}
              logo={l1}
              link={"https://snowave.it/"}
              height={400}
            />
            <ImgPartner
              index={0}
              img={p2}
              logo={l2}
              link={"https://ciceroexperience.com/en/"}
              height={400}
            />
            <ImgPartner
              index={0}
              img={p3}
              logo={l3}
              link={"https://ingondola.com/"}
              height={400}
            />
          </div>
        </div>
      </div>

      <div className="height-100"></div>
    </main>
  );
}
