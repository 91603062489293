import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";
import { apiErrorToast } from "../../../Utils/errors";
import Button from "../../components/Button/Button";

import { iWallSlot } from "../../../../config/wall";
import Skeleton from "react-loading-skeleton";
import NftController from "../../../Controllers/NftController";
import { iNft } from "../../../models/iNft";
import Countdown from "../Countdown/Countdown";
import openBuyModal from "../../../../_Web/components/Checkout/Buy";
import TakCard from "../TakCard/TakCard";

const HIDE_IF_MORE_THAN_HOURS = 100;

interface Props {
  slot?: iWallSlot;
}

export default function LastMinuteContainer(props: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const [nft, setNft] = useState<iNft>();
  const [lockDate, setLockDate] = useState<Date | null>(null);

  const loadPayload = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getPaginated({
        page: 1,
        size: (props.slot?.config.payload as any).limit,
        sort: JSON.stringify((props.slot?.config.payload as any).sort),
        query: JSON.stringify((props.slot?.config.payload as any).query),
      });

      setNft(data.data[0]);

      if (data.data.length > 0) {
        const lockDateStr = data?.data[0].lockDate;
        setLockDate(lockDateStr ? new Date(lockDateStr) : null);
      }
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  const onCountDownEnd = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    loadPayload();
  }, []);

  if (!isVisible) return <></>;

  if (
    nft &&
    !isLoading &&
    new Date(String(lockDate)).getTime() >
      new Date().getTime() + HIDE_IF_MORE_THAN_HOURS * 60 * 60 * 1000
  )
    return <></>; // hide if more that 100 hours

  return (
    <>
      <div>
        {!isLoading && nft ? (
          <>
            <h2 className="h1 regular m-0 white-color">
              {t(props.slot?.title ?? "")}
            </h2>
            <div className="height-32"></div>

            <TakCard
              preventAspectRatio
              style={{ minHeight: "400px" }}
              nft={nft}
              addon={
                <div
                  className="panel white-background text-black"
                  style={{
                    maxWidth: !isSmallScreen ? "258px" : "100%",
                    position: !isSmallScreen ? "absolute" : "unset",
                    right: "25px",
                    bottom: "70px",
                  }}
                >
                  <div className="d-flex flex-row align-items-baseline flex-md-column gap-2">
                    <p className="mb-0 bodytext">
                      {t("wall.lastminute.expires")}
                    </p>
                    <p className="h1 medium mb-0">
                      {lockDate ? (
                        <Countdown
                          startTime={lockDate}
                          onFinishedCountdown={onCountDownEnd}
                        />
                      ) : null}
                    </p>
                  </div>
                  <Button
                    className="w-100"
                    text={t("wall.lastminute.getitnow")}
                    onClick={(e) => {
                      e.stopPropagation();
                      openBuyModal(nft._id);
                    }}
                  />
                </div>
              }
            />
          </>
        ) : (
          <Skeleton style={{ width: "100%", opacity: "0.5" }} height={550} />
        )}
      </div>
    </>
  );
}
