import { useEffect, useState } from "react";
import { apiErrorToast } from "../../../assets/Utils/errors";
import NftController from "../../../assets/Controllers/NftController";
import { iNft } from "../../../assets/models/iNft";
import { CheckoutBuy } from "../../../_Web/components/Checkout/Checkout";
import { useSelector } from "react-redux";
import { selectNftInCheckout } from "../../../redux/slices/appSlice";
import { closeNftCheckout } from "../../../assets/Utils/nftcheckout";

export default function CheckoutPage() {
  const nftId = useSelector(selectNftInCheckout);
  const [, setIsLoading] = useState(false);
  const [nft, setNft] = useState<iNft>();

  const loadNft = async () => {
    if (!nftId) {
      setNft(undefined);
      return;
    }

    setIsLoading(true);
    try {
      const nft = await NftController.getNft(nftId);
      setNft(nft);
    } catch (error) {
      apiErrorToast(error);
      closeNftCheckout();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadNft();
  }, [nftId]);

  if (!nftId) return <></>;
  
  return (
    <section
      style={{
        position: "fixed",
        left: "0",
        top: "0",
        width: "100vw",
        height: "100dvh",
        zIndex: "8888",
        overflow: "hidden",
      }}
      className="d-flex"
    >
      {nft ? <CheckoutBuy nft={nft} /> : null}
    </section>
  );
}
