import { useEffect, useState } from "react";
import UserController from "../../../../Controllers/UserController";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/slices/userSlice";
import {
  iStripeAccountData,
  iStripeBankAccount,
} from "../../../../models/iStripe";
import { apiErrorToast } from "../../../../Utils/errors";
import StripeController from "../../../../Controllers/StripeController";
import Button from "../../Button/Button";
import { closeWalletPages, openWalletPage } from "../../../../Utils/wallet";
import { Form } from "react-bootstrap";
import Input from "../../Input/Input";
import { handleInputChange } from "../../../../Utils/input";
import DatePicker from "../../DatePicker/DatePicker";
import Select from "../../Select/Select";
import { useTranslation } from "react-i18next";
// import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { fireTagManagerEvent } from "../../../../Services/tagmanager";
import { isPhoneNumber } from "../../../../Utils/generic";
import { closeModal } from "../../../../Utils/modal";

// https://stripe.com/docs/connect/testing#account-numbers
// https://stripe.com/docs/payouts

function scrollTo(ref: string) {
  const element = document.getElementById(ref);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

interface iCountry {
  countryName: string;
  countryNameExtended: string;
  fields: Array<{
    label: string;
    type: "number" | "text" | "select";
    options?: Array<{
      value: string | number;
      text: string | number;
      disabled?: boolean;
    }>;
    name: string;
    min?: number;
    max?: number;
    readOnly?: boolean;
    default?: string;
    required?: boolean;
    placeholder?: string;
  }>;
}

export function RegisterBankAccountPage() {
  const user = useSelector(selectUser)!;

  const [isLoading, setIsLoading] = useState(false);

  const [accountState, setAccountState] = useState<any>({
    email: user.email,
    // phonePrefix: user.phonePrefix,
    phone: `${user.phonePrefix}${user.phone}`,
    first_name: user.firstName,
    last_name: user.secondName,
    dob_day: new Date(user.dateOfBirth).getDate(),
    dob_month: new Date(user.dateOfBirth).getMonth() + 1,
    dob_year: new Date(user.dateOfBirth).getFullYear(),
    dob: new Date(user.dateOfBirth),
  });

  const [account, setAccount] = useState<iStripeAccountData>();
  // const [accountExists, setAccountExists] = useState(false);

  const loadStripeAccount = async () => {
    setIsLoading(true);
    try {
      const account = await StripeController.getStripeAccountData();
      setAccount(account);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadStripeAccount();
  }, []);

  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [errorAccountNumber, setErrorAccountNumber] = useState(false);
  const [errorAge, setErrorAge] = useState(false);
  const [errorPostalCode, setErrorPostalCode] = useState(false);

  const countriesArray: Array<iCountry> = [
    {
      countryName: "AU",
      countryNameExtended: "Austria",
      fields: [
        {
          label: "Ihre Kontonummer* (Zeichen 4-9)",
          type: "text",
          name: "account_number",
          min: 4,
          max: 9,
          required: true,
          placeholder: "123456",
        },
        {
          label: "bsb* (Zeichen 6)",
          type: "text",
          name: "routing_number",
          min: 6,
          max: 6,
          required: true,
          placeholder: "12345678",
        },
        {
          label: "Währung*",
          type: "text",
          name: "currency",
          default: "aud",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryName: "IT",
      countryNameExtended: "Italy",
      fields: [
        {
          label: "Regione*",
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Abruzzo", text: "Abruzzo" },
            { value: "Aosta Valley", text: "Aosta Valley" },
            { value: "Apulia", text: "Apulia" },
            { value: "Basilicata", text: "Basilicata" },
            { value: "Calabria", text: "Calabria" },
            { value: "Campania", text: "Campania" },
            { value: "Emilia-Romagna", text: "Emilia-Romagna" },
            { value: "Friuli Venezia Giulia", text: "Friuli Venezia Giulia" },
            { value: "Lazio", text: "Lazio" },
            { value: "Liguria", text: "Liguria" },
            { value: "Lombardy", text: "Lombardy" },
            { value: "Marche", text: "Marche" },
            { value: "Molise", text: "Molise" },
            { value: "Piedmont", text: "Piedmont" },
            { value: "Sardinia", text: "Sardinia" },
            { value: "Sicily", text: "Sicily" },
            { value: "Trentino-Alto Adige", text: "Trentino-Alto Adige" },
            { value: "Tuscany", text: "Tuscany" },
            { value: "Umbria", text: "Umbria" },
            { value: "Veneto", text: "Veneto" },
          ],
        },
        {
          label: t("wallet.earnings.iban"),
          type: "text",
          name: "account_number",
          min: 27,
          max: 27,
          required: true,
          placeholder: "IT60X0542811101000000123456",
        },
        {
          label: "Valuta *",
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryName: "CA",
      countryNameExtended: "Canada",
      fields: [
        {
          label: `State*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "AB", text: "AB - Alberta" },
            { value: "BC", text: "BC - British Columbia" },
            { value: "MB", text: "MB - Manitoba" },
            { value: "NB", text: "NB - New Brunswick" },
            { value: "NL", text: "NL - Newfoundland and Labrador" },
            { value: "NS", text: "NS - Nova Scotia" },
            { value: "NT", text: "NT - Northwest Territories" },
            { value: "NU", text: "NU - Nunavut" },
            { value: "ON", text: "ON - Ontario" },
            { value: "PE", text: "PE - Prince Edward Island" },
            { value: "QC", text: "QC - Quebec" },
            { value: "SK", text: "SK - Saskatchewan" },
            { value: "YT", text: "YT - Yukon" },
          ],
        },
        {
          label: `Account Number*`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "Your Account Number",
        },
        {
          label: `Transit Number*`,
          type: "text",
          name: "routing_number",
          required: true,
          placeholder: "Your Transit Number",
        },
        {
          label: `Job Title*`,
          type: "text",
          name: "job_title",
          required: true,
          placeholder: "Your Job Title (e.g., CEO, Support Engineer)",
        },

        {
          label: `Currency*`,
          type: "text",
          name: "currency",
          default: "cad",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryName: "MX",
      countryNameExtended: "Mexico",
      fields: [
        {
          label: `State*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Aguascalientes", text: "Aguascalientes" },
            { value: "Baja California", text: "Baja California" },
            { value: "Baja California Sur", text: "Baja California Sur" },
            { value: "Campeche", text: "Campeche" },
            { value: "Chiapas", text: "Chiapas" },
            { value: "Chihuahua", text: "Chihuahua" },
            { value: "Ciudad de México", text: "Ciudad de México" },
            { value: "Coahuila de Zaragoza", text: "Coahuila de Zaragoza" },
            { value: "Colima", text: "Colima" },
            { value: "Durango", text: "Durango" },
            { value: "Estado de México", text: "Estado de México" },
            { value: "Guanajuato", text: "Guanajuato" },
            { value: "Guerrero", text: "Guerrero" },
            { value: "Hidalgo", text: "Hidalgo" },
            { value: "Jalisco", text: "Jalisco" },
            { value: "Michoacán", text: "Michoacán" },
            { value: "Morelos", text: "Morelos" },
            { value: "Nayarit", text: "Nayarit" },
            { value: "Nuevo León", text: "Nuevo León" },
            { value: "Oaxaca", text: "Oaxaca" },
            { value: "Puebla", text: "Puebla" },
            { value: "Querétaro", text: "Querétaro" },
            { value: "Quintana Roo", text: "Quintana Roo" },
            { value: "San Luis Potosí", text: "San Luis Potosí" },
            { value: "Sinaloa", text: "Sinaloa" },
            { value: "Sonora", text: "Sonora" },
            { value: "Tabasco", text: "Tabasco" },
            { value: "Tamaulipas", text: "Tamaulipas" },
            { value: "Tlaxcala", text: "Tlaxcala" },
            { value: "Veracruz", text: "Veracruz" },
            { value: "Yucatán", text: "Yucatán" },
            { value: "Zacatecas", text: "Zacatecas" },
          ],
        },
        {
          label: `Clave* (Caracters 18)`,
          type: "text",
          name: "account_number",
          min: 18,
          max: 18,
          required: true,
          placeholder: "123456789012345678",
        },
        {
          label: `Moneda*`,
          type: "text",
          name: "currency",
          default: "MXN",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryName: "BE",
      countryNameExtended: "Belgium",

      fields: [
        {
          label: `État*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            {
              value: "Brussels Capital Region",
              text: "Brussels Capital Region",
            },
            { value: "Flanders", text: "Flanders" },
            { value: "Wallonia", text: "Wallonia" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}*`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "BE12345678912345",
        },
        {
          label: `Devise*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Bulgaria",
      countryName: "BG",
      fields: [
        {
          label: `Държава*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Blagoevgrad", text: "Blagoevgrad" },
            { value: "Burgas", text: "Burgas" },
            { value: "Dobrich", text: "Dobrich" },
            { value: "Gabrovo", text: "Gabrovo" },
            { value: "Haskovo", text: "Haskovo" },
            { value: "Kardzhali", text: "Kardzhali" },
            { value: "Kyustendil", text: "Kyustendil" },
            { value: "Lovech", text: "Lovech" },
            { value: "Montana", text: "Montana" },
            { value: "Pazardzhik", text: "Pazardzhik" },
            { value: "Pernik", text: "Pernik" },
            { value: "Pleven", text: "Pleven" },
            { value: "Plovdiv", text: "Plovdiv" },
            { value: "Razgrad", text: "Razgrad" },
            { value: "Ruse", text: "Ruse" },
            { value: "Shumen", text: "Shumen" },
            { value: "Silistra", text: "Silistra" },
            { value: "Sliven", text: "Sliven" },
            { value: "Smolyan", text: "Smolyan" },
            { value: "Sofia", text: "Sofia" },
            { value: "Stara Zagora", text: "Stara Zagora" },
            { value: "Targovishte", text: "Targovishte" },
            { value: "Varna", text: "Varna" },
            { value: "Veliko Tarnovo", text: "Veliko Tarnovo" },
            { value: "Vidin", text: "Vidin" },
            { value: "Vratsa", text: "Vratsa" },
            { value: "Yambol", text: "Yambol" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Характер 22)`,
          type: "text",
          name: "account_number",
          min: 22,
          max: 22,
          required: true,
          placeholder: "BG80BNBG96611020345678",
        },
        {
          label: `Валута*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Croatia",
      countryName: "HR",
      fields: [
        {
          label: `Država*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Zagreb County", text: "Zagreb County" },
            { value: "City of Zagreb", text: "City of Zagreb" },
            { value: "Krapina-Zagorje County", text: "Krapina-Zagorje County" },
            { value: "Sisak-Moslavina County", text: "Sisak-Moslavina County" },
            { value: "Karlovačka County", text: "Karlovačka County" },
            { value: "Varaždin County", text: "Varaždin County" },
            {
              value: "Koprivnica-Križevci County",
              text: "Koprivnica-Križevci County",
            },
            {
              value: "Bjelovar-Bilogora County",
              text: "Bjelovar-Bilogora County",
            },
            {
              value: "Primorje-Gorski Kotar County",
              text: "Primorje-Gorski Kotar County",
            },
            { value: "Lika-Senj County", text: "Lika-Senj County" },
            {
              value: "Virovitica-Podravina County",
              text: "Virovitica-Podravina County",
            },
            { value: "Požega-Slavonia County", text: "Požega-Slavonia County" },
            { value: "Brod-Posavina County", text: "Brod-Posavina County" },
            { value: "Zadar County", text: "Zadar County" },
            { value: "Osijek-Baranja County", text: "Osijek-Baranja County" },
            { value: "Šibenik-Knin County", text: "Šibenik-Knin County" },
            { value: "Vukovar-Syrmia County", text: "Vukovar-Syrmia County" },
            { value: "Split-Dalmatia County", text: "Split-Dalmatia County" },
            { value: "Istria County", text: "Istria County" },
            {
              value: "Dubrovnik-Neretva County",
              text: "Dubrovnik-Neretva County",
            },
            { value: "Međimurje County", text: "Međimurje County" },
            { value: "Grad Zagreb", text: "Grad Zagreb" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Karakter 21)`,
          type: "text",
          name: "account_number",
          min: 21,
          max: 21,
          required: true,
          placeholder: "HR1210010051863000160",
        },
        {
          label: `Valuta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Cyprus",
      countryName: "CY",
      fields: [
        {
          label: `Κράτος*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Nicosia", text: "Nicosia" },
            { value: "Limassol", text: "Limassol" },
            { value: "Larnaca", text: "Larnaca" },
            { value: "Famagusta", text: "Famagusta" },
            { value: "Paphos", text: "Paphos" },
            { value: "Kyrenia", text: "Kyrenia" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Χαρακτήρας 28)`,
          type: "text",
          name: "account_number",
          min: 28,
          max: 28,
          required: true,
          placeholder: "CY17002001280000001200527600",
        },
        {
          label: `Νόμισμα*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Czech Republic",
      countryName: "CZ",
      fields: [
        {
          label: `Stát*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Prague", text: "Prague" },
            {
              value: "Central Bohemian Region",
              text: "Central Bohemian Region",
            },
            { value: "South Bohemian Region", text: "South Bohemian Region" },
            { value: "Plzeň Region", text: "Plzeň Region" },
            { value: "Karlovy Vary Region", text: "Karlovy Vary Region" },
            { value: "Ústí nad Labem Region", text: "Ústí nad Labem Region" },
            { value: "Liberec Region", text: "Liberec Region" },
            { value: "Hradec Králové Region", text: "Hradec Králové Region" },
            { value: "Pardubice Region", text: "Pardubice Region" },
            { value: "Vysočina Region", text: "Vysočina Region" },
            { value: "South Moravian Region", text: "South Moravian Region" },
            { value: "Olomouc Region", text: "Olomouc Region" },
            { value: "Zlín Region", text: "Zlín Region" },
            {
              value: "Moravian-Silesian Region",
              text: "Moravian-Silesian Region",
            },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 24)`,
          type: "text",
          name: "account_number",
          min: 24,
          max: 24,
          required: true,
          placeholder: "CZ6508000000192000145399",
        },
        {
          label: `Měna*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Denmark",
      countryName: "DK",
      fields: [
        {
          label: `Stat*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            {
              value: "Capital Region of Denmark",
              text: "Capital Region of Denmark",
            },
            { value: "Region of Zealand", text: "Region of Zealand" },
            {
              value: "Region of Southern Denmark",
              text: "Region of Southern Denmark",
            },
            { value: "Central Denmark Region", text: "Central Denmark Region" },
            { value: "North Denmark Region", text: "North Denmark Region" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}*`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "DK5000400440116243",
        },
        {
          label: `Valuta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Estonia",
      countryName: "EE",
      fields: [
        {
          label: `Riik*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Harju County", text: "Harju County" },
            { value: "Hiiu County", text: "Hiiu County" },
            { value: "Ida-Viru County", text: "Ida-Viru County" },
            { value: "Järva County", text: "Järva County" },
            { value: "Jõgeva County", text: "Jõgeva County" },
            { value: "Lääne County", text: "Lääne County" },
            { value: "Lääne-Viru County", text: "Lääne-Viru County" },
            { value: "Põlva County", text: "Põlva County" },
            { value: "Pärnu County", text: "Pärnu County" },
            { value: "Rapla County", text: "Rapla County" },
            { value: "Saare County", text: "Saare County" },
            { value: "Tartu County", text: "Tartu County" },
            { value: "Valga County", text: "Valga County" },
            { value: "Viljandi County", text: "Viljandi County" },
            { value: "Võru County", text: "Võru County" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Iseloom 20)`,
          type: "text",
          name: "account_number",
          min: 20,
          max: 20,
          required: true,
          placeholder: "EE382200221020145685",
        },
        {
          label: `Valuuta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Finland",
      countryName: "FI",
      fields: [
        {
          label: `Valtio*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Åland Islands", text: "Åland Islands" },
            { value: "Central Finland", text: "Central Finland" },
            { value: "Central Ostrobothnia", text: "Central Ostrobothnia" },
            { value: "Eastern Finland", text: "Eastern Finland" },
            { value: "Kainuu", text: "Kainuu" },
            { value: "Kanta-Häme", text: "Kanta-Häme" },
            { value: "Kymenlaakso", text: "Kymenlaakso" },
            { value: "Lapland", text: "Lapland" },
            { value: "North Karelia", text: "North Karelia" },
            { value: "Northern Ostrobothnia", text: "Northern Ostrobothnia" },
            { value: "Northern Savonia", text: "Northern Savonia" },
            { value: "Ostrobothnia", text: "Ostrobothnia" },
            { value: "Päijät-Häme", text: "Päijät-Häme" },
            { value: "Pirkanmaa", text: "Pirkanmaa" },
            { value: "Satakunta", text: "Satakunta" },
            { value: "South Karelia", text: "South Karelia" },
            { value: "South Ostrobothnia", text: "South Ostrobothnia" },
            { value: "South Savo", text: "South Savo" },
            { value: "Southwest Finland", text: "Southwest Finland" },
            { value: "Uusimaa", text: "Uusimaa" },
          ],
        },
        {
          label: `Luonne*`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "FI2112345600000785",
        },
        {
          label: `Valuutta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "France",
      countryName: "FR",
      fields: [
        {
          label: `État*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Auvergne-Rhône-Alpes", text: "Auvergne-Rhône-Alpes" },
            {
              value: "Bourgogne-Franche-Comté",
              text: "Bourgogne-Franche-Comté",
            },
            { value: "Brittany", text: "Brittany" },
            { value: "Centre-Val de Loire", text: "Centre-Val de Loire" },
            { value: "Corsica", text: "Corsica" },
            { value: "Grand Est", text: "Grand Est" },
            { value: "Hauts-de-France", text: "Hauts-de-France" },
            { value: "Île-de-France", text: "Île-de-France" },
            { value: "Normandy", text: "Normandy" },
            { value: "Nouvelle-Aquitaine", text: "Nouvelle-Aquitaine" },
            { value: "Occitanie", text: "Occitanie" },
            { value: "Pays de la Loire", text: "Pays de la Loire" },
            {
              value: "Provence-Alpes-Côte d'Azur",
              text: "Provence-Alpes-Côte d'Azur",
            },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Caractère 27)`,
          type: "text",
          name: "account_number",
          min: 27,
          max: 27,
          required: true,
          placeholder: "FR1420041010050500013M02606",
        },
        {
          label: `Devise*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Germany",
      countryName: "DE",
      fields: [
        {
          label: `Germany*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Baden-Württemberg", text: "Baden-Württemberg" },
            { value: "Bavaria", text: "Bavaria" },
            { value: "Berlin", text: "Berlin" },
            { value: "Brandenburg", text: "Brandenburg" },
            { value: "Bremen", text: "Bremen" },
            { value: "Hamburg", text: "Hamburg" },
            { value: "Hesse", text: "Hesse" },
            { value: "Lower Saxony", text: "Lower Saxony" },
            { value: "Mecklenburg-Vorpommern", text: "Mecklenburg-Vorpommern" },
            { value: "North Rhine-Westphalia", text: "North Rhine-Westphalia" },
            { value: "Rhineland-Palatinate", text: "Rhineland-Palatinate" },
            { value: "Saarland", text: "Saarland" },
            { value: "Saxony", text: "Saxony" },
            { value: "Saxony-Anhalt", text: "Saxony-Anhalt" },
            { value: "Schleswig-Holstein", text: "Schleswig-Holstein" },
            { value: "Thuringia", text: "Thuringia" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 22)`,
          type: "text",
          name: "account_number",
          min: 22,
          max: 22,
          required: true,
          placeholder: "DE89370400440532013000",
        },
        {
          label: `Währung*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Gibraltar",
      countryName: "GI",
      fields: [
        {
          label: `Ehtao*`,
          type: "select",
          name: "state",
          required: true,
          options: [{ value: "Gibraltar", text: "Gibraltar" }],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Pessanaje 23)`,
          type: "text",
          name: "account_number",
          min: 23,
          max: 23,
          required: true,
          placeholder: "GI75NWBK000000007099453",
        },
        {
          label: `Moni*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Greece",
      countryName: "GR",
      fields: [
        {
          label: `Κράτος*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Attica", text: "Attica" },
            { value: "Central Greece", text: "Central Greece" },
            { value: "Central Macedonia", text: "Central Macedonia" },
            { value: "Crete", text: "Crete" },
            {
              value: "Eastern Macedonia and Thrace",
              text: "Eastern Macedonia and Thrace",
            },
            { value: "Epirus", text: "Epirus" },
            { value: "Ionian Islands", text: "Ionian Islands" },
            { value: "North Aegean", text: "North Aegean" },
            { value: "Peloponnese", text: "Peloponnese" },
            { value: "South Aegean", text: "South Aegean" },
            { value: "Thessaly", text: "Thessaly" },
            { value: "Western Greece", text: "Western Greece" },
            { value: "Western Macedonia", text: "Western Macedonia" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Χαρακτήρας 27)`,
          type: "text",
          name: "account_number",
          min: 27,
          max: 27,
          required: true,
          placeholder: "GR1601101250000000012300695",
        },
        {
          label: `Νόμισμα*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    // {
    //   countryName: "HK",
    //   fields: [
    //     {
    //       label: `${t("profile.account_number")}* (${t("profile.characters")} 6-9)`,
    //       type: "text",
    //       name: "account_number",
    //       min: 6,
    //       max: 10,
    //       required: true,
    //       placeholder: String(t("profile.hk_account_placeholder")),
    //     },
    //     {
    //       label: `${t("profile.clearing_code")}* (${t("profile.characters")} 3)`,
    //       type: "text",
    //       name: "routing_number",
    //       min: 3,
    //       max: 10,
    //       required: true,
    //       placeholder: String(t("profile.hk_clearing_code_placeholder")),
    //     },
    //     {
    //       label: `${t("profile.currency")}*`,
    //       type: "text",
    //       name: "currency",
    //       default: "hkd",
    //       required: true,
    //       readOnly: true,
    //     },
    //   ],
    // },
    {
      countryNameExtended: "Hungary",
      countryName: "HU",
      fields: [
        {
          label: `Állapot*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Budapest", text: "Budapest" },
            { value: "Baranya", text: "Baranya" },
            { value: "Bács-Kiskun", text: "Bács-Kiskun" },
            { value: "Békés", text: "Békés" },
            { value: "Borsod-Abaúj-Zemplén", text: "Borsod-Abaúj-Zemplén" },
            { value: "Csongrád", text: "Csongrád" },
            { value: "Fejér", text: "Fejér" },
            { value: "Győr-Moson-Sopron", text: "Győr-Moson-Sopron" },
            { value: "Hajdú-Bihar", text: "Hajdú-Bihar" },
            { value: "Heves", text: "Heves" },
            { value: "Jász-Nagykun-Szolnok", text: "Jász-Nagykun-Szolnok" },
            { value: "Komárom-Esztergom", text: "Komárom-Esztergom" },
            { value: "Nógrád", text: "Nógrád" },
            { value: "Pest", text: "Pest" },
            { value: "Somogy", text: "Somogy" },
            { value: "Szabolcs-Szatmár-Bereg", text: "Szabolcs-Szatmár-Bereg" },
            { value: "Tolna", text: "Tolna" },
            { value: "Vas", text: "Vas" },
            { value: "Veszprém", text: "Veszprém" },
            { value: "Zala", text: "Zala" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Jellem 28)`,
          type: "text",
          name: "account_number",
          min: 28,
          max: 28,
          required: true,
          placeholder: "HU42117730161111101800000000",
        },
        {
          label: `Penznem*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Ireland",
      countryName: "IE",
      fields: [
        {
          label: `State*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Co. Carlow", text: "Co. Carlow" },
            { value: "Co. Cavan", text: "Co. Cavan" },
            { value: "Co. Clare", text: "Co. Clare" },
            { value: "Co. Cork", text: "Co. Cork" },
            { value: "Co. Donegal", text: "Co. Donegal" },
            { value: "Co. Dublin", text: "Co. Dublin" },
            { value: "Co. Galway", text: "Co. Galway" },
            { value: "Co. Kerry", text: "Co. Kerry" },
            { value: "Co. Kildare", text: "Co. Kildare" },
            { value: "Co. Kilkenny", text: "Co. Kilkenny" },
            { value: "Co. Laois", text: "Co. Laois" },
            { value: "Co. Leitrim", text: "Co. Leitrim" },
            { value: "Co. Limerick", text: "Co. Limerick" },
            { value: "Co. Longford", text: "Co. Longford" },
            { value: "Co. Louth", text: "Co. Louth" },
            { value: "Co. Mayo", text: "Co. Mayo" },
            { value: "Co. Meath", text: "Co. Meath" },
            { value: "Co. Monaghan", text: "Co. Monaghan" },
            { value: "Co. Offaly", text: "Co. Offaly" },
            { value: "Co. Roscommon", text: "Co. Roscommon" },
            { value: "Co. Sligo", text: "Co. Sligo" },
            { value: "Co. Tipperary", text: "Co. Tipperary" },
            { value: "Co. Waterford", text: "Co. Waterford" },
            { value: "Co. Westmeath", text: "Co. Westmeath" },
            { value: "Co. Wexford", text: "Co. Wexford" },
            { value: "Co. Wicklow", text: "Co. Wicklow" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Characters 22)`,
          type: "text",
          name: "account_number",
          min: 22,
          max: 22,
          required: true,
          placeholder: "IE29AIBK93115212345678",
        },
        {
          label: `Currency*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Latvia",
      countryName: "LV",
      fields: [
        {
          label: `Valsts*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Riga", text: "Riga" },
            { value: "Aizkraukle", text: "Aizkraukle" },
            { value: "Alūksne", text: "Alūksne" },
            { value: "Balvi", text: "Balvi" },
            { value: "Bauska", text: "Bauska" },
            { value: "Cēsis", text: "Cēsis" },
            { value: "Daugavpils", text: "Daugavpils" },
            { value: "Dobele", text: "Dobele" },
            { value: "Gulbene", text: "Gulbene" },
            { value: "Jēkabpils", text: "Jēkabpils" },
            { value: "Jelgava", text: "Jelgava" },
            { value: "Krāslava", text: "Krāslava" },
            { value: "Kuldīga", text: "Kuldīga" },
            { value: "Liepāja", text: "Liepāja" },
            { value: "Limbaži", text: "Limbaži" },
            { value: "Ludza", text: "Ludza" },
            { value: "Madona", text: "Madona" },
            { value: "Ogre", text: "Ogre" },
            { value: "Preiļi", text: "Preiļi" },
            { value: "Rēzekne", text: "Rēzekne" },
            { value: "Saldus", text: "Saldus" },
            { value: "Talsi", text: "Talsi" },
            { value: "Tukums", text: "Tukums" },
            { value: "Valka", text: "Valka" },
            { value: "Valmiera", text: "Valmiera" },
            { value: "Ventspils", text: "Ventspils" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Raksturs 21)`,
          type: "text",
          name: "account_number",
          min: 21,
          max: 21,
          required: true,
          placeholder: "LV80BANK0000435195001",
        },
        {
          label: `Valūta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Liechtenstein",
      countryName: "LI",
      fields: [
        {
          label: ` Valsts *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Balzers", text: "Balzers" },
            { value: "Eschen", text: "Eschen" },
            { value: "Gamprin", text: "Gamprin" },
            { value: "Mauren", text: "Mauren" },
            { value: "Planken", text: "Planken" },
            { value: "Ruggell", text: "Ruggell" },
            { value: "Schaan", text: "Schaan" },
            { value: "Schellenberg", text: "Schellenberg" },
            { value: "Triesen", text: "Triesen" },
            { value: "Triesenberg", text: "Triesenberg" },
            { value: "Vaduz", text: "Vaduz" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Raksturs 21)`,
          type: "text",
          name: "account_number",
          min: 21,
          max: 21,
          required: true,
          placeholder: "LI21088100002324013AA",
        },
        {
          label: `Valūta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Lithuania",
      countryName: "LT",
      fields: [
        {
          label: `Valstybė *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Alytus County", text: "Alytus County" },
            { value: "Kaunas County", text: "Kaunas County" },
            { value: "Klaipėda County", text: "Klaipėda County" },
            { value: "Marijampolė County", text: "Marijampolė County" },
            { value: "Panevėžys County", text: "Panevėžys County" },
            { value: "Šiauliai County", text: "Šiauliai County" },
            { value: "Tauragė County", text: "Tauragė County" },
            { value: "Telšiai County", text: "Telšiai County" },
            { value: "Utena County", text: "Utena County" },
            { value: "Vilnius County", text: "Vilnius County" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Asmuo 20)`,
          type: "text",
          name: "account_number",
          min: 20,
          max: 20,
          required: true,
          placeholder: "LT121000011101001000",
        },
        {
          label: `Valiuta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Luxembourg",
      countryName: "LU",
      fields: [
        {
          label: `Staat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Diekirch", text: "Diekirch" },
            { value: "Grevenmacher", text: "Grevenmacher" },
            { value: "Luxembourg", text: "Luxembourg" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 20)`,
          type: "text",
          name: "account_number",
          min: 20,
          max: 20,
          required: true,
          placeholder: "LU280019400644750000",
        },
        {
          label: `Währung *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Malta",
      countryName: "MT",
      fields: [
        {
          label: `Stat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Attard", text: "Attard" },
            { value: "Balzan", text: "Balzan" },
            { value: "Birkirkara", text: "Birkirkara" },
            { value: "Birżebbuġa", text: "Birżebbuġa" },
            { value: "Cospicua", text: "Cospicua" },
            { value: "Dingli", text: "Dingli" },
            { value: "Fgura", text: "Fgura" },
            { value: "Floriana", text: "Floriana" },
            { value: "Għajnsielem", text: "Għajnsielem" },
            { value: "Għarb", text: "Għarb" },
            { value: "Għargħur", text: "Għargħur" },
            { value: "Għasri", text: "Għasri" },
            { value: "Għaxaq", text: "Għaxaq" },
            { value: "Ħamrun", text: "Ħamrun" },
            { value: "Iklin", text: "Iklin" },
            { value: "Isla", text: "Isla" },
            { value: "Kalkara", text: "Kalkara" },
            { value: "Kerċem", text: "Kerċem" },
            { value: "Kirkop", text: "Kirkop" },
            { value: "Lija", text: "Lija" },
            { value: "Luqa", text: "Luqa" },
            { value: "Marsa", text: "Marsa" },
            { value: "Marsaskala", text: "Marsaskala" },
            { value: "Marsaxlokk", text: "Marsaxlokk" },
            { value: "Mdina", text: "Mdina" },
            { value: "Mellieħa", text: "Mellieħa" },
            { value: "Mġarr", text: "Mġarr" },
            { value: "Mosta", text: "Mosta" },
            { value: "Mqabba", text: "Mqabba" },
            { value: "Msida", text: "Msida" },
            { value: "Mtarfa", text: "Mtarfa" },
            { value: "Munxar", text: "Munxar" },
            { value: "Nadur", text: "Nadur" },
            { value: "Naxxar", text: "Naxxar" },
            { value: "Paola", text: "Paola" },
            { value: "Pembroke", text: "Pembroke" },
            { value: "Pietà", text: "Pietà" },
            { value: "Qala", text: "Qala" },
            { value: "Qormi", text: "Qormi" },
            { value: "Qrendi", text: "Qrendi" },
            { value: "Rabat (Għawdex)", text: "Rabat (Għawdex)" },
            { value: "Rabat (Malta)", text: "Rabat (Malta)" },
            { value: "Safi", text: "Safi" },
            { value: "San Ġiljan", text: "San Ġiljan" },
            { value: "San Ġwann", text: "San Ġwann" },
            { value: "San Lawrenz", text: "San Lawrenz" },
            { value: "San Pawl il-Baħar", text: "San Pawl il-Baħar" },
            { value: "Sannat", text: "Sannat" },
            { value: "Santa Luċija", text: "Santa Luċija" },
            { value: "Santa Venera", text: "Santa Venera" },
            { value: "Siġġiewi", text: "Siġġiewi" },
            { value: "Sliema", text: "Sliema" },
            { value: "Swieqi", text: "Swieqi" },
            { value: "Ta' Xbiex", text: "Ta' Xbiex" },
            { value: "Tarxien", text: "Tarxien" },
            { value: "Valletta", text: "Valletta" },
            { value: "Xagħra", text: "Xagħra" },
            { value: "Xewkija", text: "Xewkija" },
            { value: "Xgħajra", text: "Xgħajra" },
            { value: "Żabbar", text: "Żabbar" },
            { value: "Żebbuġ (Għawdex)", text: "Żebbuġ (Għawdex)" },
            { value: "Żebbuġ (Malta)", text: "Żebbuġ (Malta)" },
            { value: "Żejtun", text: "Żejtun" },
            { value: "Żurrieq", text: "Żurrieq" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Karattru 31)`,
          type: "text",
          name: "account_number",
          min: 31,
          max: 31,
          required: true,
          placeholder: "MT84MALT011000012345MTLCAST001S",
        },
        {
          label: `Munita *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Netherlands",
      countryName: "NL",
      fields: [
        {
          label: `Staat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Drenthe", text: "Drenthe" },
            { value: "Flevoland", text: "Flevoland" },
            { value: "Friesland", text: "Friesland" },
            { value: "Gelderland", text: "Gelderland" },
            { value: "Groningen", text: "Groningen" },
            { value: "Limburg", text: "Limburg" },
            { value: "North Brabant", text: "North Brabant" },
            { value: "North Holland", text: "North Holland" },
            { value: "Overijssel", text: "Overijssel" },
            { value: "South Holland", text: "South Holland" },
            { value: "Utrecht", text: "Utrecht" },
            { value: "Zeeland", text: "Zeeland" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Karakter 18)`,
          type: "text",
          name: "account_number",
          min: 18,
          max: 18,
          required: true,
          placeholder: "NL91ABNA0417164300",
        },
        {
          label: `Valuta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Norway",
      countryName: "NO",
      fields: [
        {
          label: ` Stat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Agder", text: "Agder" },
            { value: "Innlandet", text: "Innlandet" },
            { value: "Møre og Romsdal", text: "Møre og Romsdal" },
            { value: "Nordland", text: "Nordland" },
            { value: "Oslo", text: "Oslo" },
            { value: "Rogaland", text: "Rogaland" },
            { value: "Troms og Finnmark", text: "Troms og Finnmark" },
            { value: "Trøndelag", text: "Trøndelag" },
            { value: "Vestfold og Telemark", text: "Vestfold og Telemark" },
            { value: "Vestland", text: "Vestland" },
            { value: "Viken", text: "Viken" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Karakter 15)`,
          type: "text",
          name: "account_number",
          min: 15,
          max: 15,
          required: true,
          placeholder: "NO9386011117947",
        },
        {
          label: `Valuta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Poland",
      countryName: "PL",
      fields: [
        {
          label: `Stan *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            {
              value: "Greater Poland Voivodeship",
              text: "Greater Poland Voivodeship",
            },
            {
              value: "Kuyavian-Pomeranian Voivodeship",
              text: "Kuyavian-Pomeranian Voivodeship",
            },
            {
              value: "Lesser Poland Voivodeship",
              text: "Lesser Poland Voivodeship",
            },
            { value: "Łódź Voivodeship", text: "Łódź Voivodeship" },
            {
              value: "Lower Silesian Voivodeship",
              text: "Lower Silesian Voivodeship",
            },
            { value: "Lublin Voivodeship", text: "Lublin Voivodeship" },
            { value: "Lubusz Voivodeship", text: "Lubusz Voivodeship" },
            { value: "Masovian Voivodeship", text: "Masovian Voivodeship" },
            { value: "Opole Voivodeship", text: "Opole Voivodeship" },
            {
              value: "Podkarpackie Voivodeship",
              text: "Podkarpackie Voivodeship",
            },
            { value: "Podlaskie Voivodeship", text: "Podlaskie Voivodeship" },
            { value: "Pomeranian Voivodeship", text: "Pomeranian Voivodeship" },
            { value: "Silesian Voivodeship", text: "Silesian Voivodeship" },
            {
              value: "Świętokrzyskie Voivodeship",
              text: "Świętokrzyskie Voivodeship",
            },
            {
              value: "Warmian-Masurian Voivodeship",
              text: "Warmian-Masurian Voivodeship",
            },
            {
              value: "West Pomeranian Voivodeship",
              text: "West Pomeranian Voivodeship",
            },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 28)`,
          type: "text",
          name: "account_number",
          min: 28,
          max: 28,
          required: true,
          placeholder: "PL61109010140000071219812874",
        },
        {
          label: `Waluta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Portugal",
      countryName: "PT",
      fields: [
        {
          label: ` Estado *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Aveiro", text: "Aveiro" },
            { value: "Azores", text: "Azores" },
            { value: "Beja", text: "Beja" },
            { value: "Braga", text: "Braga" },
            { value: "Bragança", text: "Bragança" },
            { value: "Castelo Branco", text: "Castelo Branco" },
            { value: "Coimbra", text: "Coimbra" },
            { value: "Évora", text: "Évora" },
            { value: "Faro", text: "Faro" },
            { value: "Guarda", text: "Guarda" },
            { value: "Leiria", text: "Leiria" },
            { value: "Lisbon", text: "Lisbon" },
            { value: "Madeira", text: "Madeira" },
            { value: "Portalegre", text: "Portalegre" },
            { value: "Porto", text: "Porto" },
            { value: "Santarém", text: "Santarém" },
            { value: "Setúbal", text: "Setúbal" },
            { value: "Viana do Castelo", text: "Viana do Castelo" },
            { value: "Vila Real", text: "Vila Real" },
            { value: "Viseu", text: "Viseu" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Caráter 25)`,
          type: "text",
          name: "account_number",
          min: 25,
          max: 25,
          required: true,
          placeholder: "PT50000201231234567890154",
        },
        {
          label: `Moeda *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Romania",
      countryName: "RO",
      fields: [
        {
          label: `Stat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Alba", text: "Alba" },
            { value: "Arad", text: "Arad" },
            { value: "Argeș", text: "Argeș" },
            { value: "Bacău", text: "Bacău" },
            { value: "Bihor", text: "Bihor" },
            { value: "Bistrița-Năsăud", text: "Bistrița-Năsăud" },
            { value: "Botoșani", text: "Botoșani" },
            { value: "Brașov", text: "Brașov" },
            { value: "Brăila", text: "Brăila" },
            { value: "Bucharest", text: "Bucharest" },
            { value: "Buzău", text: "Buzău" },
            { value: "Caraș-Severin", text: "Caraș-Severin" },
            { value: "Călărași", text: "Călărași" },
            { value: "Cluj", text: "Cluj" },
            { value: "Constanța", text: "Constanța" },
            { value: "Covasna", text: "Covasna" },
            { value: "Dâmbovița", text: "Dâmbovița" },
            { value: "Dolj", text: "Dolj" },
            { value: "Galați", text: "Galați" },
            { value: "Giurgiu", text: "Giurgiu" },
            { value: "Gorj", text: "Gorj" },
            { value: "Harghita", text: "Harghita" },
            { value: "Hunedoara", text: "Hunedoara" },
            { value: "Ialomița", text: "Ialomița" },
            { value: "Iași", text: "Iași" },
            { value: "Ilfov", text: "Ilfov" },
            { value: "Maramureș", text: "Maramureș" },
            { value: "Mehedinți", text: "Mehedinți" },
            { value: "Mureș", text: "Mureș" },
            { value: "Neamț", text: "Neamț" },
            { value: "Olt", text: "Olt" },
            { value: "Prahova", text: "Prahova" },
            { value: "Satu Mare", text: "Satu Mare" },
            { value: "Sălaj", text: "Sălaj" },
            { value: "Sibiu", text: "Sibiu" },
            { value: "Suceava", text: "Suceava" },
            { value: "Teleorman", text: "Teleorman" },
            { value: "Timiș", text: "Timiș" },
            { value: "Tulcea", text: "Tulcea" },
            { value: "Vâlcea", text: "Vâlcea" },
            { value: "Vaslui", text: "Vaslui" },
            { value: "Vrancea", text: "Vrancea" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Caracter 24)`,
          type: "text",
          name: "account_number",
          min: 24,
          max: 24,
          required: true,
          placeholder: "RO49AAAA1B31007593840000",
        },
        {
          label: `Monedă *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Slovakia",
      countryName: "SK",
      fields: [
        {
          label: `Štát *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Banská Bystrica", text: "Banská Bystrica" },
            { value: "Bratislava", text: "Bratislava" },
            { value: "Košice", text: "Košice" },
            { value: "Nitra", text: "Nitra" },
            { value: "Prešov", text: "Prešov" },
            { value: "Trenčín", text: "Trenčín" },
            { value: "Trnava", text: "Trnava" },
            { value: "Žilina", text: "Žilina" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 24)`,
          type: "text",
          name: "account_number",
          min: 24,
          max: 24,
          required: true,
          placeholder: "SK3112000000198742637541",
        },
        {
          label: `Menová jednotka*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Slovenia",
      countryName: "SI",
      fields: [
        {
          label: `Država *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Gorenjska", text: "Gorenjska" },
            { value: "Goriška", text: "Goriška" },
            { value: "Jugovzhodna Slovenija", text: "Jugovzhodna Slovenija" },
            { value: "Koroška", text: "Koroška" },
            { value: "Notranjsko-kraška", text: "Notranjsko-kraška" },
            { value: "Obalno-kraška", text: "Obalno-kraška" },
            { value: "Osrednjeslovenska", text: "Osrednjeslovenska" },
            { value: "Podravska", text: "Podravska" },
            { value: "Pomurska", text: "Pomurska" },
            { value: "Posavska", text: "Posavska" },
            { value: "Primorsko-notranjska", text: "Primorsko-notranjska" },
            { value: "Savinjska", text: "Savinjska" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Značilnost 19)`,
          type: "text",
          name: "account_number",
          min: 19,
          max: 19,
          required: true,
          placeholder: "SI56191000000123438",
        },
        {
          label: `Valuta*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Spain",
      countryName: "ES",
      fields: [
        {
          label: `${t("wallet.earnings.state")}*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Andalucía", text: "Andalucía" },
            { value: "Aragón", text: "Aragón" },
            { value: "Asturias", text: "Asturias" },
            { value: "Canarias", text: "Canarias" },
            { value: "Cantabria", text: "Cantabria" },
            { value: "Castilla-La Mancha", text: "Castilla-La Mancha" },
            { value: "Castilla y León", text: "Castilla y León" },
            { value: "Cataluña", text: "Cataluña" },
            { value: "Ceuta", text: "Ceuta" },
            { value: "Comunidad de Madrid", text: "Comunidad de Madrid" },
            {
              value: "Comunidad Foral de Navarra",
              text: "Comunidad Foral de Navarra",
            },
            { value: "Comunidad Valenciana", text: "Comunidad Valenciana" },
            { value: "Extremadura", text: "Extremadura" },
            { value: "Galicia", text: "Galicia" },
            { value: "Islas Baleares", text: "Islas Baleares" },
            { value: "La Rioja", text: "La Rioja" },
            { value: "Melilla", text: "Melilla" },
            { value: "País Vasco", text: "País Vasco" },
            { value: "Principado de Asturias", text: "Principado de Asturias" },
            { value: "Región de Murcia", text: "Región de Murcia" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (${t(
            "wallet.earnings.characters"
          )} 24)`,
          type: "text",
          name: "account_number",
          min: 24,
          max: 24,
          required: true,
          placeholder: "ES9121000418450200051332",
        },
        {
          label: `${t("wallet.earnings.currency")}*`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Sweden",
      countryName: "SE",
      fields: [
        {
          label: `Stat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Blekinge län", text: "Blekinge län" },
            { value: "Dalarnas län", text: "Dalarnas län" },
            { value: "Gotlands län", text: "Gotlands län" },
            { value: "Gävleborgs län", text: "Gävleborgs län" },
            { value: "Hallands län", text: "Hallands län" },
            { value: "Jämtlands län", text: "Jämtlands län" },
            { value: "Jönköpings län", text: "Jönköpings län" },
            { value: "Kalmar län", text: "Kalmar län" },
            { value: "Kronobergs län", text: "Kronobergs län" },
            { value: "Norrbottens län", text: "Norrbottens län" },
            { value: "Skåne län", text: "Skåne län" },
            { value: "Stockholms län", text: "Stockholms län" },
            { value: "Södermanlands län", text: "Södermanlands län" },
            { value: "Uppsala län", text: "Uppsala län" },
            { value: "Värmlands län", text: "Värmlands län" },
            { value: "Västerbottens län", text: "Västerbottens län" },
            { value: "Västernorrlands län", text: "Västernorrlands län" },
            { value: "Västmanlands län", text: "Västmanlands län" },
            { value: "Västra Götalands län", text: "Västra Götalands län" },
            { value: "Örebro län", text: "Örebro län" },
            { value: "Östergötlands län", text: "Östergötlands län" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Karaktär 24)`,
          type: "text",
          name: "account_number",
          min: 24,
          max: 24,
          required: true,
          placeholder: "SE4550000000058398257466",
        },
        {
          label: `Valuta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Switzerland",
      countryName: "CH",
      fields: [
        {
          label: `Staat *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Aargau", text: "Aargau" },
            { value: "Appenzell Ausserrhoden", text: "Appenzell Ausserrhoden" },
            { value: "Appenzell Innerrhoden", text: "Appenzell Innerrhoden" },
            { value: "Basel-Landschaft", text: "Basel-Landschaft" },
            { value: "Basel-Stadt", text: "Basel-Stadt" },
            { value: "Bern", text: "Bern" },
            { value: "Fribourg", text: "Fribourg" },
            { value: "Geneva", text: "Geneva" },
            { value: "Glarus", text: "Glarus" },
            { value: "Graubünden", text: "Graubünden" },
            { value: "Jura", text: "Jura" },
            { value: "Lucerne", text: "Lucerne" },
            { value: "Neuchâtel", text: "Neuchâtel" },
            { value: "Nidwalden", text: "Nidwalden" },
            { value: "Obwalden", text: "Obwalden" },
            { value: "Schaffhausen", text: "Schaffhausen" },
            { value: "Schwyz", text: "Schwyz" },
            { value: "Solothurn", text: "Solothurn" },
            { value: "St. Gallen", text: "St. Gallen" },
            { value: "Thurgau", text: "Thurgau" },
            { value: "Ticino", text: "Ticino" },
            { value: "Uri", text: "Uri" },
            { value: "Valais", text: "Valais" },
            { value: "Vaud", text: "Vaud" },
            { value: "Zug", text: "Zug" },
            { value: "Zurich", text: "Zurich" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Charakter 21)`,
          type: "text",
          name: "account_number",
          min: 21,
          max: 21,
          required: true,
          placeholder: "CH9300762011623852957",
        },
        {
          label: `Währung *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "San Marino",
      countryName: "SM",
      fields: [
        {
          label: `Stato*`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "Acquaviva", text: "Acquaviva" },
            { value: "Borgo Maggiore", text: "Borgo Maggiore" },
            { value: "Chiesanuova", text: "Chiesanuova" },
            { value: "Domagnano", text: "Domagnano" },
            { value: "Faetano", text: "Faetano" },
            { value: "Fiorentino", text: "Fiorentino" },
            { value: "Montegiardino", text: "Montegiardino" },
            { value: "San Marino", text: "San Marino" },
            { value: "Serravalle", text: "Serravalle" },
          ],
        },
        {
          label: `${t("wallet.earnings.iban")}* (Carattere27)`,
          type: "text",
          name: "account_number",
          min: 27,
          max: 27,
          required: true,
          placeholder: "SM86U0322509800000000270100",
        },
        {
          label: `${t("profile.swift")}* (Carattere 8-11)`,
          type: "text",
          name: "routing_number",
          min: 8,
          max: 11,
          required: true,
          placeholder: "AAAASMSMXXX",
        },
        {
          label: `Valuta *`,
          type: "text",
          name: "currency",
          default: "eur",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "United Kingdom",
      countryName: "GB",
      fields: [
        {
          label: `State *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "England", text: "England" },
            { value: "Scotland", text: "Scotland" },
            { value: "Wales", text: "Wales" },
            { value: "Northern Ireland", text: "Northern Ireland" },
          ],
        },
        {
          label: `Account number *`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "GB29NWBK60161331926819",
        },
        // {
        //   label: `${t("profile.sort_code")}*`,
        //   type: "text",
        //   name: "routing_number",
        //   required: true,
        //   placeholder: String(t("profile.gb_sort_code_placeholder")),
        // },
        {
          label: `Currency *`,
          type: "text",
          name: "currency",
          default: "EUR",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "Jordan",
      countryName: "JO",
      fields: [
        {
          label: `IBAN*`,
          type: "text",
          name: "account_number",
          required: true,
          placeholder: "JO32ABCJ0010123456789012345678",
        },
        {
          label: `SWIFT/BIC*`,
          type: "text",
          name: "routing_number",
          required: true,
          placeholder: "AAAAJOJOXXX",
        },
        {
          label: `عملة (Omleh)*`,
          type: "text",
          name: "currency",
          default: "EUR",
          required: true,
          readOnly: true,
        },
      ],
    },
    {
      countryNameExtended: "United States",
      countryName: "US",
      fields: [
        {
          label: `State *`,
          type: "select",
          name: "state",
          required: true,
          options: [
            { value: "AK", text: "AK - Alaska" },
            { value: "AL", text: "AL - Alabama" },
            { value: "AR", text: "AR - Arkansas" },
            { value: "AZ", text: "AZ - Arizona" },
            { value: "CA", text: "CA - California" },
            { value: "CO", text: "CO - Colorado" },
            { value: "CT", text: "CT - Connecticut" },
            { value: "DC", text: "DC - District of Columbia" },
            { value: "DE", text: "DE - Delaware" },
            { value: "FL", text: "FL - Florida" },
            { value: "GA", text: "GA - Georgia" },
            { value: "HI", text: "HI - Hawaii" },
            { value: "IA", text: "IA - Iowa" },
            { value: "ID", text: "ID - Idaho" },
            { value: "IL", text: "IL - Illinois" },
            { value: "IN", text: "IN - Indiana" },
            { value: "KS", text: "KS - Kansas" },
            { value: "KY", text: "KY - Kentucky" },
            { value: "LA", text: "LA - Louisiana" },
            { value: "MA", text: "MA - Massachusetts" },
            { value: "MD", text: "MD - Maryland" },
            { value: "ME", text: "ME - Maine" },
            { value: "MI", text: "MI - Michigan" },
            { value: "MN", text: "MN - Minnesota" },
            { value: "MO", text: "MO - Missouri" },
            { value: "MS", text: "MS - Mississippi" },
            { value: "MT", text: "MT - Montana" },
            { value: "NC", text: "NC - North Carolina" },
            { value: "ND", text: "ND - North Dakota" },
            { value: "NE", text: "NE - Nebraska" },
            { value: "NH", text: "NH - New Hampshire" },
            { value: "NJ", text: "NJ - New Jersey" },
            { value: "NM", text: "NM - New Mexico" },
            { value: "NV", text: "NV - Nevada" },
            { value: "NY", text: "NY - New York" },
            { value: "OH", text: "OH - Ohio" },
            { value: "OK", text: "OK - Oklahoma" },
            { value: "OR", text: "OR - Oregon" },
            { value: "PA", text: "PA - Pennsylvania" },
            { value: "PR", text: "PR - Puerto Rico" },
            { value: "RI", text: "RI - Rhode Island" },
            { value: "SC", text: "SC - South Carolina" },
            { value: "SD", text: "SD - South Dakota" },
            { value: "TN", text: "TN - Tennessee" },
            { value: "TX", text: "TX - Texas" },
            { value: "UT", text: "UT - Utah" },
            { value: "VA", text: "VA - Virginia" },
            { value: "VT", text: "VT - Vermont" },
            { value: "WA", text: "WA - Washington" },
            { value: "WI", text: "WI - Wisconsin" },
            { value: "WV", text: "WV - West Virginia" },
            { value: "WY", text: "WY - Wyoming" },
          ],
        },
        {
          label: `Account number*`,
          type: "text",
          name: "account_number",
          min: 1,
          max: 30,
          required: true,
          placeholder: "000123456789",
        },
        {
          label: `Routung number* (Characters 9)`,
          type: "text",
          name: "routing_number",
          min: 9,
          max: 9,
          required: true,
          placeholder: "110000000",
        },
        {
          label: `SSN* (last 4)`,
          type: "text",
          name: "ssn_last_4",
          min: 4,
          max: 4,
          required: true,
          placeholder: "0000",
        },
        {
          label: `Currency*`,
          type: "text",
          name: "currency",
          default: "USD",
          required: true,
          readOnly: true,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!accountState.country) return;

    const country = countriesArray.find(
      (el) => el.countryName === accountState.country
    )!;

    country.fields.forEach((field) => {
      accountState[field.name] = field.default;
    });
  }, [accountState.country]);

  const submit = async () => {
    setErrorAccountNumber(false);
    setErrorAge(false);
    setPhoneError(false);
    setErrorPostalCode(false);

    setIsLoading(true);
    try {
      const stripeAccountDetailSubmitted =
        user.stripeDetailsSubmitted && user.stripeChargesEnabled;

      if (stripeAccountDetailSubmitted)
        await StripeController.createBankAccount(accountState);
      else await StripeController.configureStripeAccount(accountState);

      await UserController.initLoggedUser();

      // if (!stripeAccountDetailSubmitted)
      //   toast(t("wallet.earnings.configuring"));

      //closeWalletPages();

      fireTagManagerEvent("add_bank_account");

      closeModal();
    } catch (error: any) {
      console.log(error?.response?.data?.message);

      apiErrorToast(error);

      fireTagManagerEvent("add_bank_account_error");

      switch (error?.response?.data?.error?.param) {
        case "bank_account[account_number]":
          setErrorAccountNumber(true);
          scrollTo("account_number");
          break;
        case "individual[dob][year]":
          setErrorAge(true);
          scrollTo("age");
          break;
        case "individual[address][postal_code]":
          setErrorPostalCode(true);
          scrollTo("postal_code");
          break;
        case "individual[phone]":
          setPhoneError(true);
          scrollTo("phone");
          break;
        default:
          setError(String(error?.response?.data?.message));
          break;
      }
    }
    setIsLoading(false);
  };

  return (
    <section style={{ width: "100%", overflowY: "scroll" }} className="p-4">
      <div className="height-8"></div>
      <p className="m-0 h4 medium">{t("wallet.earnings.registertitle")}</p>
      <div className="height-4"></div>
      <p className="m-0 bodytext-sm regular dark-grey-color">
        {t("wallet.earnings.registertext")}
      </p>
      <div className="horizontal-divider" style={{ margin: "24px 0" }}></div>

      {/* step 0  */}
      <div className="d-flex align-items-center justify-content-between">
        <p className="m-0 bodytext medium">
          {t("wallet.earnings.personalinfo")}
        </p>
      </div>
      <div className="height-16"></div>
      <form>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.firstname")}*</Form.Label>
          <Input
            status={accountState.first_name ? "success" : "normal"}
            placeholder={t("wallet.earnings.firstnameph")}
            value={accountState.first_name ?? ""}
            name="first_name"
            onChange={(e) => handleInputChange(e, setAccountState)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.lastname")}*</Form.Label>
          <Input
            status={accountState.last_name ? "success" : "normal"}
            placeholder={t("wallet.earnings.lastnameph")}
            value={accountState.last_name ?? ""}
            name="last_name"
            onChange={(e) => handleInputChange(e, setAccountState)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.birth")}*</Form.Label>
          <DatePicker
            id="age"
            defaultValue={accountState.dob}
            error={errorAge ? true : false}
            status={accountState.dob_day && !errorAge ? "success" : "normal"}
            onChange={(e) => {
              if (e) {
                setErrorAge(false);
                setAccountState({
                  ...accountState,
                  dob_day: e.getDate(),
                  dob_month: e.getMonth() + 1,
                  dob_year: e.getFullYear(),
                });
              }

              if (!e)
                setAccountState({
                  ...accountState,
                  dob_day: undefined,
                  dob_month: undefined,
                  dob_year: undefined,
                });
            }}
          />
          {errorAge && (
            <p className="text-danger mt-1" style={{ fontSize: "12px" }}>
              {t("wallet.earnings.errorAge")}
            </p>
          )}
        </Form.Group>
      </form>
      <div className="height-8"></div>
      <div className="horizontal-divider" style={{ margin: "24px 0" }}></div>

      {/* step 1 */}
      <div className="d-flex align-items-center justify-content-between">
        <p className="m-0 bodytext medium">{t("wallet.earnings.contact")}</p>
      </div>
      <div className="height-16"></div>
      <form>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.email")}*</Form.Label>
          <Input readOnly status={"success"} value={user.email} name="email" />
        </Form.Group>

        <div className="d-flex w100 gap-2">
          <Form.Group className="w100">
            <Form.Label>{t("auth.phone")}*</Form.Label>
            <Input
              id="phone"
              className={phoneError ? "error" : ""}
              status={
                isPhoneNumber(accountState.phone) && !phoneError
                  ? "success"
                  : "normal"
              }
              placeholder="00 00 0000"
              required
              value={accountState.phone}
              onChange={(e) => {
                setPhoneError(false);
                handleInputChange(e, setAccountState);
              }}
              onBlur={() => {
                if (!isPhoneNumber(accountState.phone)) setPhoneError(true);
              }}
              type="text"
              name="phone"
            />
            {phoneError ? (
              <Form.Text className="error">{t("auth.phoneerror")}</Form.Text>
            ) : null}
          </Form.Group>
        </div>

        <Form.Group>
          <Form.Label>{t("wallet.earnings.city")}*</Form.Label>
          <Input
            status={accountState.city ? "success" : "normal"}
            placeholder={t("wallet.earnings.cityph")}
            value={accountState.city}
            name="city"
            onChange={(e) => handleInputChange(e, setAccountState)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.zip")}*</Form.Label>
          <Input
            id="postal_code"
            type="text"
            className={errorPostalCode ? "error" : ""}
            status={
              accountState.postal_code && !errorPostalCode
                ? "success"
                : "normal"
            }
            placeholder={t("wallet.earnings.zipph")}
            value={accountState.postal_code}
            name="postal_code"
            onChange={(e) => {
              setErrorPostalCode(false);
              handleInputChange(e, setAccountState);
            }}
          />
          {errorPostalCode && (
            <p className="text-danger mt-1" style={{ fontSize: "12px" }}>
              {t("wallet.earnings.errorZipCode")}
            </p>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.address")}*</Form.Label>
          <Input
            status={accountState.line1 ? "success" : "normal"}
            // placeholder={t("wallet.earnings.addressph")}
            value={accountState.line1}
            name="line1"
            onChange={(e) => handleInputChange(e, setAccountState)}
          />
        </Form.Group>
      </form>
      <div className="height-8"></div>
      <div className="horizontal-divider" style={{ margin: "24px 0" }}></div>

      {/* step 2 */}
      <div className="d-flex align-items-center justify-content-between">
        <p className="m-0 bodytext medium">{t("wallet.earnings.account")}</p>
      </div>
      <div className="height-16"></div>
      <form>
        <Form.Group>
          <Form.Label>{t("wallet.earnings.country")}*</Form.Label>
          <Select
            status={accountState.country ? "success" : "normal"}
            onChange={(e) => handleInputChange(e, setAccountState)}
            name="country"
            value={accountState.country}
            options={[
              { text: t("wallet.earnings.countryph"), value: "" },
              ...Object.values(countriesArray)
                .map((c) => {
                  return {
                    text: `${c.countryName} - ${c.countryNameExtended}`,
                    value: c.countryName,
                  };
                })
                .sort((a, b) => a.text.localeCompare(b.text)),
            ]}
          />
        </Form.Group>

        {countriesArray
          .find((el) => el.countryName === accountState.country)
          ?.fields.map((field, key) => {
            return (
              <Form.Group key={"field_" + key}>
                <Form.Label>{field.label}</Form.Label>

                {field.type === "number" || field.type === "text" ? (
                  <>
                    <Input
                      id="account_number"
                      status={
                        accountState[field.name] && !errorAccountNumber
                          ? "success"
                          : "normal"
                      }
                      minLength={field.min}
                      maxLength={field.max}
                      readOnly={field.readOnly}
                      placeholder={field.placeholder}
                      type={field.type}
                      min={field.min}
                      name={field.name}
                      value={accountState[field.name] ?? field.default}
                      required={field.required}
                      className={
                        field.name === "account_number" && errorAccountNumber
                          ? "error"
                          : ""
                      }
                      onChange={(e) => {
                        if (e) {
                          setErrorAccountNumber(false);
                        }
                        if (
                          (field.name === "account_number" ||
                            field.name === "routing_number") &&
                          account?.bankAccountList?.some(
                            (acc: iStripeBankAccount) =>
                              acc.last4 === e.target.value.slice(-4) &&
                              acc.country === accountState.country
                          )
                        ) {
                          setAccountState({
                            ...accountState,
                            [field.name]: e.target.value,
                          });
                          // setAccountExists(true);
                          setError(t("wallet.earnings.accountexists"));
                        } else {
                          setError("");
                          // setAccountExists(false);
                          handleInputChange(e, setAccountState);
                        }
                      }}
                    />
                    {field.name === "account_number" && errorAccountNumber && (
                      <p
                        className="text-danger mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        {t("wallet.earnings.errorAccountNumber")}
                      </p>
                    )}
                  </>
                ) : null}

                {field.type === "select" ? (
                  <Select
                    status={accountState[field.name] ? "success" : "normal"}
                    value={accountState[field.name] ?? field.default}
                    name={field.name}
                    required={field.required}
                    options={
                      field.options
                        ? [{ value: "", text: "-" }, ...field.options]
                        : []
                    }
                    onChange={(e) => handleInputChange(e, setAccountState)}
                  />
                ) : null}
              </Form.Group>
            );
          })}
      </form>
      <div className="height-32"></div>
      <div className="d-flex justify-content-end">
        <Button
          disabled={
            !accountState.first_name ||
            !accountState.last_name ||
            !accountState.dob_day ||
            !accountState.country ||
            !accountState.email ||
            !accountState.phone ||
            !accountState.city ||
            !accountState.postal_code ||
            !accountState.line1 ||
            !isPhoneNumber(accountState.phone) ||
            !accountState.country ||
            Number(
              countriesArray
                .find((el) => el.countryName === accountState.country)
                ?.fields.filter((field) => {
                  return field.required && !accountState[field.name];
                }).length
            ) > 0
          }
          onClick={submit}
          text={t("wallet.earnings.action")}
          loading={isLoading}
        />
      </div>
      {error ? (
        <>
          <br />
          <div className="red-color text-center">{error}</div>
        </>
      ) : null}
    </section>
  );
}

function WalletDeleteBankAccount({
  bankAccount,
}: {
  bankAccount: iStripeBankAccount;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteAccount = async (id: string) => {
    setIsLoading(true);
    try {
      await StripeController.deleteBankAccount({ bankAccountId: id });
      await UserController.initLoggedUser();

      fireTagManagerEvent("delete_bank_account");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column p-4">
      <span className="bodytext medium">
        {t("wallet.earnings.deletebanlaccount")}
      </span>
      <div className="height-16"></div>
      <span>{t("wallet.earnings.deletebankaccountconfirmation")}</span>
      <div className="height-16"></div>

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            deleteAccount(bankAccount.id);
            closeWalletPages();
          }}
          text={t("wallet.earnings.deletebankaccount")}
        />
      </div>
    </div>
  );
}

export default function WalletBalance() {
  const [account, setAccount] = useState<iStripeAccountData>();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser)!;

  const { t } = useTranslation();

  const loadStripeAccount = async () => {
    setIsLoading(true);
    try {
      const account = await StripeController.getStripeAccountData();
      setAccount(account);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadStripeAccount();
  }, [user]);

  const setDefault = async (id: string) => {
    setIsLoading(true);
    try {
      await StripeController.setDefaultForCurrency({ bankAccountId: id });
      await UserController.initLoggedUser();

      fireTagManagerEvent("default_bank_account");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  const needToConfigureStripe =
    (!user.stripeDetailsSubmitted || !user.stripeChargesEnabled) &&
    !account?.stripeAccount;

  const isBeingVerified =
    user.stripeDetailsSubmitted &&
    account?.stripeAccount?.individual.requirements?.pending_verification
      .length !== 0;

  const isStatusUnverified =
    (!user.stripeDetailsSubmitted || !user.stripeChargesEnabled) &&
    account?.stripeAccount?.individual?.verification?.status === "unverified";

  const isVerified = user.stripeDetailsSubmitted && user.stripeChargesEnabled;

  if (isLoading)
    return (
      <section className="p-3">
        <Skeleton />
        <div className="height-12"></div>
        <Skeleton count={2} height={100} />
        <div className="height-12"></div>
        <Skeleton width={150} height={25} />
      </section>
    );

  return (
    <section className="p-3">
      {needToConfigureStripe ? (
        <>
          <p className="m-0 bodytext medium">
            {t("wallet.earnings.nobanktitle")}
          </p>
          <div className="height-4"></div>
          <p className="m-0 bodytext regular dark-grey-color">
            {t("wallet.earnings.nobanktext")}
          </p>
          <div className="height-24"></div>

          <Button
            onClick={() =>
              openWalletPage({
                id: "RegisterBankAccountPage",
                content: <RegisterBankAccountPage />,
              })
            }
            text={t("wallet.earnings.nobankregister")}
          />
        </>
      ) : null}

      {isBeingVerified ||
      (!needToConfigureStripe && !isStatusUnverified && !isVerified) ? (
        <>
          <p className="m-0 bodytext medium">
            {t("wallet.earnings.verifying")}
          </p>
          <div className="height-4"></div>
          <p className="m-0 bodytext regular dark-grey-color">
            {t("wallet.earnings.verifyingtext")}
          </p>
        </>
      ) : null}

      {isStatusUnverified ? (
        <>
          <p className="m-0 bodytext medium">
            {t("wallet.earnings.verifyingerror")}
          </p>
          <div className="height-4"></div>
          <p className="m-0 bodytext regular dark-grey-color">
            {t("wallet.earnings.verifyingerrortext1")}{" "}
            <a href="mailto:support@takyon.io">
              <u>support@takyon.io</u>
            </a>{" "}
            {t("wallet.earnings.verifyingerrortext2")}
          </p>

          <ul>
            {account.stripeAccount.individual.requirements.errors.map(
              (error: any, index) => (
                <li className="font-color" key={index}>
                  Error: {error.reason}
                </li>
              )
            )}
          </ul>
          <ul>
            {account.stripeAccount.individual.requirements.currently_due.map(
              (error: any, index) => (
                <li className="font-color" key={index}>
                  Currently due: {error}
                </li>
              )
            )}
          </ul>
        </>
      ) : null}

      {isVerified ? (
        <>
          <p className="m-0 h3 medium">
            {t("wallet.earnings.yourearnings")} €
            {Number(account?.balance).toFixed(2)}
          </p>
          <div className="height-8"></div>

          <p className="m-0 bodytext regular dark-grey-color">
            {t("wallet.earnings.earningstext")}
          </p>

          <div
            className="horizontal-divider"
            style={{ margin: "24px 0" }}
          ></div>

          <p className="m-0 bodytext medium">
            {t("wallet.earnings.bankaccount")}
          </p>
          <div className="height-10"></div>

          {account?.bankAccountList?.map((bankAccount: iStripeBankAccount) => {
            return (
              <div
                key={"bankAccount_" + Math.random()}
                style={{ padding: "24px", margin: "6px 0" }}
                className="d-flex justify-content-between align-items-end border rounded"
              >
                <div>
                  <p className="m-0 bodytext medium">
                    {bankAccount.account_holder_name}
                  </p>
                  <div className="height-7"></div>
                  <p className="m-0 bodytext regular">
                    ****{bankAccount.last4}
                  </p>
                  <div className="height-7"></div>
                  <p className="m-0 bodytext regular dark-grey-color">
                    {bankAccount.country} / {bankAccount.currency}
                  </p>
                </div>

                {bankAccount.default_for_currency ? (
                  <div>
                    <p className="m-0 bodytext-xs light-black-color">
                      {t("wallet.earnings.default")}
                    </p>
                  </div>
                ) : null}

                {!bankAccount.default_for_currency ? (
                  <div className="d-flex flex-column gap-2">
                    <Button
                      className="w100"
                      onClick={() => setDefault(bankAccount.id)}
                      text={t("wallet.earnings.setdefault")}
                    />
                    <Button
                      className="w100"
                      onClick={() =>
                        openWalletPage({
                          id:
                            "wallet_offer_page_delete_bank_account" +
                            bankAccount.id,
                          content: (
                            <WalletDeleteBankAccount
                              bankAccount={bankAccount}
                            />
                          ),
                        })
                      }
                      // onClick={() => deleteAccount(bankAccount.id)}
                      variant="secondary"
                      text={t("wallet.earnings.deleteaccount")}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}

          <div className="height-16"></div>

          <Button
            onClick={() =>
              openWalletPage({
                id: "RegisterBankAccountPage",
                content: <RegisterBankAccountPage />,
              })
            }
            text={t("wallet.earnings.addaccount")}
          />
        </>
      ) : null}
    </section>
  );
}
