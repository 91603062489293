import { Form } from "react-bootstrap";
import UserController from "../../../../Controllers/UserController";
import { closeWalletPages, openWalletPage } from "../../../../Utils/wallet";
import { handleInputChange } from "../../../../Utils/input";
import { PHONE_CODES } from "../../../../../config/PhoneCodes";
import { FormEvent, ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { apiErrorToast } from "../../../../Utils/errors";
import { LANG } from "../../../../../config/Lang";
import { setLocaleLanguage } from "../../../../Services/i18next";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../../../config/Links";
import Switch from "../../Switch/Switch";
import Input from "../../Input/Input";
import Select from "../../Select/Select";
import { useTranslation } from "react-i18next";
import Support from "../../../../../_Web/pages/Support/Support";

function DeleteTakyonAccount() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteAccount = async () => {
    setIsLoading(true);
    try {
      await UserController.deleteAccount();
      UserController.logout();
      closeWalletPages();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column p-4">
      <span className="bodytext medium">
        {t("wallet.settings.deleteaccount")}
      </span>
      <div className="height-16"></div>
      <span>{t("wallet.settings.deleteaccountconfirmation")}</span>
      <div className="height-16"></div>

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => deleteAccount()}
          text={t("wallet.settings.deleteaccount")}
        />
      </div>
    </div>
  );
}

function AccountSettingsPage() {
  const user = useSelector(selectUser)!;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [userState, setUserState] = useState<{
    firstName: string;
    secondName: string;
    phonePrefix: string;
    phone: string;
    email: string;
  }>({
    firstName: user.firstName,
    secondName: user.secondName,
    phonePrefix: user.phonePrefix,
    phone: user.phone,
    email: user.email,
  });

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(userState);
      await UserController.refreshUserInfo();

      closeWalletPages("AccountSettingsPage");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="d-flex flex-column gap-2 p-3">
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>{t("wallet.settings.email")}</Form.Label>
          <Input
            required
            disabled
            value={user.email}
            type="text"
            name="email"
            placeholder={t("wallet.settings.email")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.settings.firstname")}</Form.Label>
          <Input
            required
            value={userState.firstName}
            onChange={(e) => handleInputChange(e, setUserState)}
            type="text"
            name="firstName"
            placeholder={t("wallet.settings.firstnameph")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.settings.secondname")}</Form.Label>
          <Input
            required
            value={userState.secondName}
            onChange={(e) => handleInputChange(e, setUserState)}
            type="text"
            name="secondName"
            placeholder={t("wallet.settings.secondnameph")}
          />
        </Form.Group>
        <div className="d-flex w100 gap-2">
          <Form.Group>
            <Form.Label>{t("wallet.settings.prefix")}</Form.Label>
            <Form.Control
              required
              as="select"
              name="phonePrefix"
              value={userState.phonePrefix}
              onChange={(e) => handleInputChange(e, setUserState)}
            >
              {PHONE_CODES.map((e, key) => {
                return (
                  <option key={"prefix_" + key} value={e.code}>
                    {e.country} {e.code}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group className="w100">
            <Form.Label>{t("wallet.settings.phone")}</Form.Label>
            <Input
              required
              value={userState.phone}
              onChange={(e) => handleInputChange(e, setUserState)}
              type="text"
              name="phone"
              placeholder="phone"
            />
          </Form.Group>
        </div>

        <div className="d-flex justify-content-end">
          <Button
            loading={isLoading}
            size="sm"
            type="submit"
            text={t("wallet.settings.save")}
          ></Button>
        </div>
      </Form>
      <span
        onClick={() =>
          openWalletPage({
            id: "wallet_offer_page_delete_takyon_account",
            content: <DeleteTakyonAccount />,
          })
        }
        className="cursor-pointer danger-color"
      >
        {t("wallet.settings.delete")}
      </span>
    </section>
  );
}

// function PaymentSettingsPage({ id }: SettingsPageProps) {
//   return <section>payment settings</section>;
// }

function PrivacySettingsPage() {
  const PASSWORD_MIN_LENGHT = 6;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [userState, setUserState] = useState<{
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }>({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const [error, setError] = useState("");

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.changePassword(userState);
      await UserController.refreshUserInfo();

      closeWalletPages("PrivacySettingsPage");
    } catch (error: any) {
      // apiErrorToast(error);

      if (
        String(error?.response?.data?.message)
          .toLowerCase()
          .includes("not correct")
      ) {
        setError("Old password is not correct");
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="d-flex flex-column gap-2 p-3">
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>{t("wallet.settings.currentpass")}</Form.Label>
          <Input
            status={
              userState.oldPassword.length >= PASSWORD_MIN_LENGHT
                ? error
                  ? "error"
                  : "success"
                : "normal"
            }
            value={userState.oldPassword}
            name="oldPassword"
            onChange={(e) => {
              handleInputChange(e, setUserState);
              setError("");
            }}
            type="password"
            placeholder={t("wallet.settings.currentpassph")}
          />
          {error ? <Form.Text className="error">{error}</Form.Text> : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.settings.newpassword")}</Form.Label>
          <Input
            status={
              userState.newPassword.length >= PASSWORD_MIN_LENGHT
                ? "success"
                : "normal"
            }
            value={userState.newPassword}
            name="newPassword"
            onChange={(e) => handleInputChange(e, setUserState)}
            type="password"
            placeholder={t("wallet.settings.newpasswordph")}
          />
          <Form.Text>{t("wallet.settings.newpasswordtext")}</Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>{t("wallet.settings.confirmnewpass")}</Form.Label>
          <Input
            status={
              userState.newPasswordConfirm.length < PASSWORD_MIN_LENGHT
                ? "normal"
                : userState.newPassword === userState.newPasswordConfirm
                ? "success"
                : "error"
            }
            value={userState.newPasswordConfirm}
            name="newPasswordConfirm"
            onChange={(e) => handleInputChange(e, setUserState)}
            type="password"
            placeholder={t("wallet.settings.confirmnewpassph")}
          />
          {userState.newPasswordConfirm.length >= PASSWORD_MIN_LENGHT &&
          userState.newPassword !== userState.newPasswordConfirm ? (
            <Form.Text className="text-danger">
              {t("wallet.settings.nopasswordmatch")}
            </Form.Text>
          ) : null}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            loading={isLoading}
            size="sm"
            type="submit"
            text={t("wallet.settings.save")}
            disabled={
              userState.oldPassword.length < PASSWORD_MIN_LENGHT ||
              userState.newPassword.length < PASSWORD_MIN_LENGHT
            }
          ></Button>
        </div>
      </Form>
    </section>
  );
}

function NotificationsSettingsPage() {
  const user = useSelector(selectUser)!;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [userState, setUserState] = useState<{
    marketingEmails: "on" | "off";
  }>({
    marketingEmails: user.marketingEmails ? "on" : "off",
  });

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(userState);
      await UserController.refreshUserInfo();

      closeWalletPages("NotificationsSettingsPage");
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="d-flex flex-column gap-2 p-3">
      <Form onSubmit={submit}>
        <Form.Group className="mb-3 d-flex gap-3">
          <Switch
            checked={userState.marketingEmails === "on"}
            onChange={(e) => {
              e.target.checked
                ? setUserState({ marketingEmails: "on" })
                : setUserState({ marketingEmails: "off" });
            }}
          />
          <Form.Label className="light">
            {t("wallet.settings.notifications")}
          </Form.Label>
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            loading={isLoading}
            size="sm"
            type="submit"
            text={t("wallet.settings.save")}
          ></Button>
        </div>
      </Form>
    </section>
  );
}

function LanguageSettingsPage() {
  const { t } = useTranslation();

  const user = useSelector(selectUser)!;
  const [isLoading, setIsLoading] = useState(false);

  const [userState, setUserState] = useState<{
    lang: LANG;
  }>({
    lang: user.lang,
  });

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await UserController.patchUser(userState);
      await UserController.refreshUserInfo();

      closeWalletPages("LanguageSettingsPage");

      setLocaleLanguage(userState.lang);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <section className="d-flex flex-column gap-2 p-3">
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>{t("wallet.settings.lang")}</Form.Label>

          <Select
            required
            name="lang"
            value={userState.lang}
            onChange={(e) => handleInputChange(e, setUserState)}
            options={Object.values(LANG).map((lang) => {
              return { text: String(lang).toUpperCase(), value: lang };
            })}
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button
            loading={isLoading}
            size="sm"
            type="submit"
            text={t("wallet.settings.save")}
          ></Button>
        </div>
      </Form>
    </section>
  );
}

export default function WalletSettings() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const settingsSections: {
    name: string;
    id: string;
    component: ReactNode;
  }[] = [
    {
      name: t("wallet.settings.account"),
      id: "AccountSettingsPage",
      component: <AccountSettingsPage />,
    },
    {
      name: t("wallet.settings.privacy"),
      id: "PrivacySettingsPage",
      component: <PrivacySettingsPage />,
    },
    {
      name: t("wallet.settings.notifications"),
      id: "NotificationsSettingsPage",
      component: <NotificationsSettingsPage />,
    },
    {
      name: t("wallet.settings.language"),
      id: "LanguageSettingsPage",
      component: <LanguageSettingsPage />,
    },
    {
      name: t("wallet.settings.support"),
      id: "SupportSettingsPage",
      component: <Support wallet />,
    },
  ];

  return (
    <section className="d-flex flex-column">
      {settingsSections.map((section, key) => {
        return (
          <div
            onClick={() => {
              openWalletPage({ id: section.id, content: section.component });
            }}
            key={"section_" + key}
            className="cursor-pointer settings-menu-item w100"
          >
            <div className="py-3 px-4 d-flex justify-content-between hover-mid-grey-hover">
              <span className="dark-grey-color">{section.name}</span>
              <span>{">"}</span>
            </div>
          </div>
        );
      })}

      <div
        className="horizontal-divider only-desktop"
        style={{ margin: "25px 0" }}
      ></div>

      <div
        onClick={() => {
          UserController.logout();
          closeWalletPages();

          navigate(getLink(LINK_TYPE.LOGIN));
        }}
        className="cursor-pointer settings-menu-item"
      >
        <div className="py-3 px-4 d-flex justify-content-between hover-mid-grey-hover">
          <span className="dark-grey-color">{t("wallet.settings.logout")}</span>
        </div>
      </div>
    </section>
  );
}
