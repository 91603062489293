import { useEffect, useState } from "react";
import iCredits from "../../../../models/iCredit";
import { apiErrorToast } from "../../../../Utils/errors";
import UserController from "../../../../Controllers/UserController";
import Button from "../../Button/Button";
import { SMALL_SCREEN, datetimeToString } from "../../../../Utils/generic";
import { closeWalletPages, openWalletPage } from "../../../../Utils/wallet";
import iReferralCode from "../../../../models/iReferral";
import ReferralController from "../../../../Controllers/ReferralController";
import getLink, { LINK_TYPE } from "../../../../../config/Links";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Input from "../../Input/Input";
import { Form } from "react-bootstrap";

import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { fireTagManagerEvent } from "../../../../Services/tagmanager";
import Share from "../../../../../_App/components/Share/Share";
import { openModal } from "../../../../Utils/modal";
import Icon from "../../Icon/Icon";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { useMediaQuery } from "react-responsive";

interface iStepProps {
  title: string;
  text: string | JSX.Element;
}

function InviteFriendPage() {
  const [referral, setReferral] = useState<iReferralCode>();
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState("");
  const user = useSelector(selectUser);
  const [accordionOpen, setAccordionOpen] = useState<{
    [key: number]: boolean;
  }>({});
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const { t } = useTranslation();

  const steps: iStepProps[] = [
    {
      title: t("wallet.credits.step1title"),
      text: (
        <div className="text-wrap">
          <span>{t("wallet.credits.step1text1")}</span>{" "}
          <span className="black-color">{t("wallet.credits.step1text2")}</span>{" "}
          <span>{t("wallet.credits.step1text3")}</span>{" "}
          <span className="black-color">{t("wallet.credits.step1text4")}</span>{" "}
          <span>{t("wallet.credits.step1text5")}</span>
        </div>
      ),
    },
    {
      title: t("wallet.credits.step2title"),
      text: t("wallet.credits.step2text"),
    },
    {
      title: t("wallet.credits.step3title"),
      text: t("wallet.credits.step3text"),
    },
  ];

  const loadReferral = async () => {
    setIsLoading(true);
    try {
      const data = await ReferralController.getReferralCode();
      setReferral(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const createCode = async () => {
    setIsLoading(true);
    try {
      await ReferralController.createReferralCode();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

    loadReferral();
  };

  const copy = () => {
    try {
      const copyText = document.getElementById(
        "referral-link-input"
      ) as HTMLInputElement;
      copyText?.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);
      setCopied(true);

      fireTagManagerEvent("invite_friend", { mode: "copy" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadReferral();
  }, []);

  useEffect(() => {
    if (referral)
      setLink(
        getLink(LINK_TYPE.REFERRAL, "EXCHANGE_FULL", {
          __CODE__: referral.code,
        })
      );
  }, [referral]);

  const toggleAccordion = (index: number) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <section className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.credits.invitefriend")}</p>
      <hr />

      <div className="height-16"></div>
      {!referral ? (
        <Button
          loading={isLoading}
          onClick={createCode}
          text="Create referral code"
        />
      ) : null}

      {referral ? (
        <>
          <div className="d-flex flex-column flex-md-row align-items-center gap-4">
            <div style={{ width: 155, height: 155, minWidth: "155px" }}>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  trailColor: "#fff",
                  pathColor: "#b6fca0",
                  strokeLinecap: "square",
                })}
                value={referral.timesUsed + 0.01}
                maxValue={referral.maxUses}
                strokeWidth={10}
              >
                <p className="m-0 h4 medium text-nowrap">{`${referral.timesUsed}/${referral.maxUses}`}</p>
                <div className="height-4"></div>
                <p className="m-0 bodytext-xs regular text-nowrap">
                  {t("wallet.credits.invitedfriends")}
                </p>
                <div className="height-8"></div>

                {/* {referral.timesUsed ? (
                  <p className="m-0 bodytext-sm regular text-nowrap">
                    €{referral.timesUsed * referral.amountForOwner}/€
                    {referral.maxUses * referral.amountForOwner}
                  </p>
                ) : null} */}
              </CircularProgressbarWithChildren>
            </div>
            <div className="d-flex flex-column w100">
              <p className="bodytext-lg m-0 text-center tex-md-left medium">
                {t("wallet.credits.invitefriendsubtitle")}
              </p>
              <p className="bodytext m-0 text-center tex-md-left light dark-grey-color">
                {t("wallet.credits.invitefriendtext")}
              </p>
              <div className="height-16"></div>
            </div>
          </div>
          <div className="height-16 only-desktop"></div>
          <div className="d-flex align-items-start gap-3">
            <Form.Group className="mb-3 w100" controlId="formBasicEmail">
              <Input
                id="referral-link-input"
                readOnly
                status={copied ? "success" : "normal"}
                value={link}
                className="cursor-pointer"
                onClick={copy}
              />
              <Form.Text className="text-muted bodytext-xs">
                {copied
                  ? t("wallet.credits.copied")
                  : t("wallet.credits.clickcopy")}
              </Form.Text>
            </Form.Group>

            <Button
              size="sm"
              onClick={() => {
                fireTagManagerEvent("click_share", {
                  item_id: referral.code,
                  side: "front",
                  auth: user ? true : false,
                });

                openModal({
                  content: (
                    <Share
                      id={referral.code}
                      link={getLink(LINK_TYPE.REFERRAL, "EXCHANGE_FULL", {
                        __CODE__: referral.code,
                      })}
                    />
                  ),
                });
              }}
              icon="ios_share"
              text={isSmallScreen ? undefined : t("app.nft.share")}
            />
          </div>
          <hr />
          <div>
            <div
              onClick={() => toggleAccordion(1)}
              className="cursor-pointer medium bodytext d-flex align-items-start justify-content-between"
            >
              <span
                className={
                  isSmallScreen ? `bodytext-lg medium` : "bodytext medium"
                }
              >
                {t("wallet.credits.howitworks")}
              </span>
              <span>
                <Icon
                  icon={`${accordionOpen[1] ? "expand_less" : "expand_more"}`}
                />
              </span>
            </div>
            <div className="height-12"></div>
            <div
              className={`${
                accordionOpen[1] ? "show" : "hide"
              } white-background p-3`}
            >
              <div className="d-flex flex-column gap-4">
                {steps.map((step, index) => (
                  <div key={index} className="d-flex flex-column gap-1">
                    <div className="bodytext-sm d-flex align-items-center justify-content-center primary-background width-25 height-25 rounded">
                      {index + 1}
                    </div>
                    <span className="bodytext medium">{step.title}</span>
                    <div className="bodytext light dark-grey-color d-flex flex-wrap">
                      {step.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </section>
  );
}

function PromoCodeSuccess({ credits }: { credits: iCredits }) {
  const { t } = useTranslation();

  return (
    <section className="p-3">
      <p className="text-center m-0 h3 medium">
        {t("wallet.credits.promosuccesstitle")}
      </p>
      <p className="text-center m-0 bodytext medium">
        {t("wallet.credits.promosuccesstext1")} €{credits.amount}{" "}
        {t("wallet.credits.promosuccesstext2")}
      </p>
      <div className="height-16"></div>
      <p className="text-center m-0 bodytext regular dark-grey-color">
        {t("wallet.credits.promosuccesstext")}
      </p>
      <div className="height-16"></div>
      <div className="d-flex justify-content-center">
        <div>
          <Button
            text={t("wallet.credits.explore")}
            onClick={() => closeWalletPages()}
          />
        </div>
      </div>
    </section>
  );
}

function PromoCodePage() {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const [status, setStatus] = useState<"error" | "success" | "normal">(
    "normal"
  );

  const submit = async () => {
    setIsLoading(true);
    try {
      const credits = await UserController.redeemCredits(code);
      setCode("");

      openWalletPage({
        id: "PromoCodeSuccess",
        content: <PromoCodeSuccess credits={credits} />,
        hideGoBack: true,
      });

      fireTagManagerEvent("use_promo_code", {
        code: code,
        amount: credits.amount,
      });
    } catch (error) {
      // apiErrorToast(error);
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (code) setStatus("success");
    if (!code) setStatus("normal");
    setError(false);
  }, [code]);

  useEffect(() => {
    if (error) setStatus("error");
  }, [error]);

  return (
    <section className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.credits.addpromotitle")}</p>

      <div className="height-16"></div>

      <form>
        <Form.Group
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <Form.Label>{t("wallet.credits.promocode")}</Form.Label>
          <Input
            status={status}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="text"
            placeholder="000 000"
          />
          {error ? (
            <Form.Text className="danger-color">
              {t("wallet.credits.promoerror")}
            </Form.Text>
          ) : null}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            onClick={submit}
            loading={isLoading}
            disabled={!code}
            text={t("wallet.credits.addpromo")}
            type="button"
          />
        </div>
      </form>
    </section>
  );
}

function CreditsBox({ credits }: { credits: iCredits }) {
  const expired = new Date(credits.expiresAt) <= new Date();

  const { t } = useTranslation();

  return (
    <div
      className="d-flex gap-3 border rounded align-items-center"
      style={{ padding: "20px 32px", opacity: expired ? 0.7 : 1 }}
    >
      <div>
        <p className="m-0 bodytext-sm regular dark-grey-color">
          {t("wallet.credits.quantity")}
        </p>
        <p className="m-0 bodytext medium">€{credits.amount.toFixed(2)}</p>
      </div>

      <div
        style={{
          margin: "0 32px",
          alignSelf: "stretch",
          justifySelf: "stretch",
        }}
        className="d-flex border only-desktop"
      ></div>

      <div
        style={{
          margin: "0 16px",
          alignSelf: "stretch",
          justifySelf: "stretch",
        }}
        className="d-flex border only-mobile"
      ></div>

      <div className="w100 d-flex flex-column">
        <div>
          <p className="m-0 bodytext-sm regular dark-grey-color">
            {t("wallet.credits.usedamount")}
          </p>
          <p className="m-0 bodytext medium text-nowrap">
            €{credits.usedAmount.toFixed(2)} {t("wallet.credits.of")} €
            {credits.amount.toFixed(2)}
          </p>
          <progress
            className="inverted"
            value={credits.usedAmount}
            max={credits.amount}
          ></progress>
        </div>
        <div className="w100 d-flex flex-column">
          <p className="m-0 bodytext-sm regular dark-grey-color">
            {t("wallet.credits.expiredate")}
          </p>
          <p className="m-0 bodytext medium text-nowrap">
            {datetimeToString(credits.expiresAt)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default function WalletCredits() {
  const [creditsActive, setCreditsActive] = useState<iCredits[]>([]);
  const [creditsNotActive, setCreditsNotActive] = useState<iCredits[]>([]);

  const [creditsBalance, setCreditsBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [viewAll, setViewAll] = useState(false);

  const { t } = useTranslation();

  const loadCredits = async () => {
    setIsLoading(true);
    try {
      const data = await UserController.getCreditsPaginated({
        page: 1,
        size: 500,
      });

      setCreditsActive(
        data.data.filter(
          (c) => new Date(c.expiresAt) > new Date() && c.usedAmount < c.amount
        )
      );
      setCreditsNotActive(
        data.data.filter(
          (c) => new Date(c.expiresAt) <= new Date() || c.usedAmount >= c.amount
        )
      );

      const balance = await UserController.getCreditsBalance();
      setCreditsBalance(balance);
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCredits();
  }, []);

  const openInviteFriendPage = () => {
    openWalletPage({ id: "InviteFriendPage", content: <InviteFriendPage /> });
  };
  const openPromoCodePage = () => {
    openWalletPage({ id: "PromoCodePage", content: <PromoCodePage /> });
  };

  if (isLoading)
    return (
      <section className="p-3">
        <Skeleton />
        <div className="height-12"></div>
        <Skeleton count={2} height={100} />
        <div className="height-12"></div>
        <Skeleton width={150} height={25} />
      </section>
    );

  return (
    <section className="p-3">
      <>
        <div
          style={{ padding: "12px 0px 12px 24px" }}
          className="w100 border rounded d-flex gap-1"
        >
          <p className="m-0 h2 medium">€{creditsBalance}</p>
          <p className="m-0 h2 regular dark-grey-color">
            {t("wallet.credits.total")}
          </p>
        </div>
        <div className="height-16"></div>

        <p className="m-0 bodytext regular dark-grey-color">
          {t("wallet.credits.creditstext")}
        </p>

        <div className="height-8"></div>

        <div className="d-flex flex-column flex-md-row flex-md-wrap w100 gap-3">
          <Button
            className="w100"
            onClick={openPromoCodePage}
            text={t("wallet.credits.addpromo")}
            variant="secondary"
          />
          <Button
            className="w100"
            onClick={openInviteFriendPage}
            text={t("wallet.credits.invitefriend")}
          />
        </div>

        {creditsActive.length ? (
          <>
            <div
              className="horizontal-divider"
              style={{ margin: "24px 0" }}
            ></div>

            <div className="d-flex justify-content-end">
              <Button
                text={`${
                  viewAll
                    ? t("wallet.credits.hidedetails")
                    : t("wallet.credits.viewdetails")
                }`}
                onClick={() => setViewAll(!viewAll)}
                size="sm"
                variant="secondary"
              />
            </div>
          </>
        ) : null}

        {viewAll ? (
          <>
            {creditsActive.length ? (
              <>
                <p className="m-0 bodytext medium">
                  {t("wallet.credits.activecredits")}
                </p>
                <div className="height-12"></div>

                <div className="d-flex flex-column" style={{ gap: "12px" }}>
                  {creditsActive.map((c, key) => {
                    return (
                      <CreditsBox key={"credits_active_" + key} credits={c} />
                    );
                  })}
                </div>
              </>
            ) : null}

            {creditsNotActive.length ? (
              <>
                <div
                  className="horizontal-divider"
                  style={{ margin: "24px 0" }}
                ></div>

                <p className="m-0 bodytext medium">
                  {t("wallet.credits.pastcredits")}
                </p>
                <div className="height-12"></div>

                <div className="d-flex flex-column" style={{ gap: "12px" }}>
                  {creditsNotActive.map((c, key) => {
                    return (
                      <CreditsBox
                        key={"credits_not_active_" + key}
                        credits={c}
                      />
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </>
    </section>
  );
}
