import { useState } from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { BID_STATUS, iBid } from "../../../../../assets/models/iBid";
import { useOfferNavigation } from "../../../../hooks/hooks";
import _OffersActionsLayout from "./_OffersActionsLayout";
import { openModal } from "../../../../../assets/Utils/modal";
import helpers from "../../../../helpers/helpers";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import { useNavigate } from "react-router-dom";

export default function CounterofferDecline() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { offer } = useOfferNavigation();
  const navigate = useNavigate();

  const currentOffer = offer.amount;
  const counterOfferReceived = offer.amount;

  const declineAction =
    (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
      offer._side === "buyer") ||
    (offer.status === BID_STATUS.BUYER_COUNTEROFFER && offer._side === "seller")
      ? t("wallet.offers.decline")
      : t("wallet.offers.cancel");

  const declineCounterofferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.declineCounterOffer({ bidId: offer._id });

      fireTagManagerEvent("decline_counter_offer", {
        item_id: offer.nftId,
        offer_id: offer._id,
      });

      openModal({
        content: (
          <ActionFeedbackModal
            icon="check_circle"
            title={t("wallet.offers.youcanceled1")}
            message={`${t("wallet.offers.youcanceled2")} ${helpers.price(
              offer.amount
            )} ${t("wallet.offers.youcanceled")}`}
            continueClickCallback={() => navigate("/profile/offers")}
            continueBtnText={t("app.checkout.viewoffers")}
          />
        ),
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_OffersActionsLayout title={declineAction}>
      <div className="mt-3 d-flex bodytext">
        <div className="flex-grow-1 light">
          {t("wallet.offers.offer_amount")}:
        </div>
        <div>{helpers.price(currentOffer)}</div>
      </div>

      <hr className="my-3" />

      <div className="d-flex bodytext">
        <div className="flex-grow-1">
          {t("wallet.offers.receivedofcouter")}:
        </div>
        <div>{helpers.price(counterOfferReceived)}</div>
      </div>

      <hr className="my-3" />

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            declineCounterofferClick();
          }}
          text={declineAction}
          loading={isLoading}
        />
      </div>
    </_OffersActionsLayout>
  );
}
