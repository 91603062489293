import { useEffect, useRef, useState } from "react";
import { iNft } from "../../../assets/models/iNft";
import { apiErrorToast } from "../../../assets/Utils/errors";
import SwipeController, {
  iSwipeCategory,
} from "../../../assets/Controllers/SwipeController";
import { SWIPE_OUTCOME } from "../../../assets/models/iSwipe";
import {
  refreshSwipePage,
  refreshSwipes,
  useForceUpdate,
} from "../../../assets/Utils/app";

import { useParams } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { debounce, throttle } from "lodash";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { openWallet, shakeWallet } from "../../../assets/Utils/wallet";
import {
  selectIsWalletOpen,
  selectRefreshSwipePage,
  selectResizeHook,
  selectSwipeFilter,
  setIsFilterOpen,
  setSwipeFilter,
} from "../../../redux/slices/appSlice";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

import { loadNfts3 } from "./loadNts";
import { useAppDispatch } from "../../../redux/redux";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import Filters from "../../../assets/TakyonDesignSystem/components/Filters/Filters";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import NftCard2, {
  NftCardProps,
} from "../../../assets/TakyonDesignSystem/components/NftCard/NftCard2";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import Share from "../Share/Share";
import CollectionController from "../../../assets/Controllers/CollectionController";
import { openNftPage } from "../../../assets/Utils/nftpage";
import { selectPendingOrders } from "../../../redux/slices/orderSlice";
import { openNftCheckout } from "../../../assets/Utils/nftcheckout";
import { openNftOffer } from "../../../assets/Utils/nftoffer";
import { Form } from "react-bootstrap";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { handleInputChange } from "../../../assets/Utils/input";
import DatePicker from "../../../assets/TakyonDesignSystem/components/DatePicker/DatePicker";
import verifiedPng from "../../../assets/img/offerbuy/verified.png";
import Select from "../../../assets/TakyonDesignSystem/components/Select/Select";

const TELEGRAM_CARD_HIDE_KEY = "n847b36v25c52v163b274n83";
const ASK_CARD_HIDE_KEY = "c26v7b348n459m5680b6";

const GAP = 16;
const MOBILE_DISTANCE = 75;
const NFT_WIDE = 450;

type SwipingCard = iNft | "telegram" | "ask";

function hideTelegramCard() {
  localStorage.setItem(TELEGRAM_CARD_HIDE_KEY, "true");
}

function isTelegramCardHidden() {
  return localStorage.getItem(TELEGRAM_CARD_HIDE_KEY) ? true : false;
}

function TelegramCard(props: { selected: boolean }) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const hide = () => {
    hideTelegramCard();
    refreshSwipePage();
  };

  const JoinTelegram = async () => {
    window.open("https://t.me/takyonexchange", "_blank");
  };

  const copy = () => {
    fireTagManagerEvent("share", {
      item_id: "telegram-link-input",
      mode: "copy",
    });

    try {
      const copyText = document.getElementById(
        "telegram-link-input"
      ) as HTMLInputElement;
      copyText?.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);
      setCopied(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="p-4 d-flex flex-column justify-content-between"
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        color: "white",
        borderRadius: "15px",
        opacity: props.selected ? 1 : 0.4,
        transitionDuration: "0.7s",
      }}
    >
      <div>
        <h3>{t("swipe.telegramcard.title")}</h3>
        <p className="dark-grey-color">{t("swipe.telegramcard.text")}</p>
      </div>
      <div>
        <Input
          id="telegram-link-input"
          className="mb-2"
          type="text"
          placeholder={t("swipe.telegramcard.input")}
          value={t("swipe.telegramcard.input")}
          name="place"
          readOnly
          icon="content_copy"
          status={copied ? "success" : "normal"}
          onClick={copy}
          onIconClick={copy}
        />
        <Button
          className="w-100 mb-2"
          text={t("swipe.telegramcard.join")}
          onClick={JoinTelegram}
        />
        <a
          className="dark-grey-color"
          target="_blank"
          rel="noreferrer"
          onClick={hide}
        >
          {t("swipe.dontshowitagain")}
        </a>
      </div>
    </div>
  );
}

function TellUsMoreModal() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const [choosenSpots, setChoosenSpots] = useState<Array<string>>([]);
  const [choosenGuests, setChoosenGuests] = useState<Array<string>>([]);
  const [howManyAdults, setHowManyAdults] = useState("0");
  const [howManyKids, setHowManyKids] = useState("0");
  const [howManyInfants, setHowManyInfants] = useState("0");
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const peopleList: Array<number> = [0, 1, 2, 3, 4];

  const spotsList: Array<string> = [
    t("swipe.askcard.mountain"),
    t("swipe.askcard.sea"),
    t("swipe.askcard.lake"),
    t("swipe.askcard.city"),
    t("swipe.askcard.wellness"),
    t("swipe.askcard.hidden_gems"),
  ];

  const guestsList: Array<string> = [
    `1 ${t("swipe.askcard.guests")}`,
    `2 ${t("swipe.askcard.guests")}`,
    `3 ${t("swipe.askcard.guests")}`,
    `4 ${t("swipe.askcard.guests")}`,
    `5 ${t("swipe.askcard.guests")}`,
    `6 ${t("swipe.askcard.guests")}`,
    `7+ ${t("swipe.askcard.guests")}`,
  ];

  const [request, setRequest] = useState<{
    spots: Array<string>;
    place: string;
    guests: Array<string>;
    checkin?: string | undefined;
    checkout?: string | undefined;
    request: string;
    email: string | undefined;
    howMuch: string;
    howManyAdults: string | undefined;
    howManyKids: string | undefined;
    howManyInfants: string | undefined;
  }>({
    spots: [],
    place: "",
    guests: [],
    checkin: undefined,
    checkout: undefined,
    request: "",
    email: user?.email,
    howMuch: "",
    howManyAdults: "0",
    howManyKids: "0",
    howManyInfants: "0",
  });

  const SendForm = async () => {
    setIsLoading(true);
    try {
      await SwipeController.requestStay({
        ...request,
        guests: choosenGuests.join(", "),
        spots: choosenSpots.join(", "),
        howManyAdults: howManyAdults,
        howManyKids: howManyKids,
        howManyInfants: howManyInfants,
      });
      setIsFormSent(true);
      refreshSwipePage();
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="d-flex flex-column" style={{ maxHeight: "80dvh" }}>
      {isFormSent ? (
        <div className="p-2 p-md-5 d-flex flex-column align-items-center justify-content-center h100 w100">
          <img style={{ width: "180px" }} src={verifiedPng} alt="" />

          <div className="height-10"></div>

          <span className="h3 text-center">
            {t("swipe.askcard.modal.thanks_title")}
          </span>
          <div className="height-10"></div>

          <span className="bodytext regular text-center">
            {t("swipe.askcard.modal.thanks_text")}
          </span>
        </div>
      ) : null}

      {!isFormSent ? (
        <>
          <div style={{ overflowX: "scroll" }}>
            <div className="height-30 only-mobile"></div>

            <Form.Group>
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.6")}*
              </Form.Label>
              <Input
                type="email"
                placeholder={t("swipe.askcard.modal.61")}
                name="email"
                value={request.email}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>

            <div className="height-30"></div>

            <div>
              <span className="bodytext medium">
                {t("swipe.askcard.modal.1")}
              </span>
              <div className="height-8"></div>
              <div className="d-flex gap-2 flex-wrap">
                {spotsList.map((spot, key) => (
                  <Button
                    size="sm"
                    key={key}
                    text={spot}
                    selected={choosenSpots.includes(spot)}
                    onClick={() => {
                      if (choosenSpots.includes(spot))
                        setChoosenSpots((arr) => {
                          const arr2 = arr.filter((e) => e !== spot);
                          return arr2;
                        });
                      else setChoosenSpots([...choosenSpots, spot]);
                    }}
                    variant="secondary"
                  />
                ))}
              </div>
            </div>

            <div className="height-30"></div>

            <Form.Group>
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.2")}
              </Form.Label>
              <Input
                type="text"
                placeholder={t("swipe.askcard.modal.21")}
                name="place"
                value={request.place}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>

            <div className="height-30"></div>

            <div>
              <span className="bodytext medium">
                {t("swipe.askcard.modal.3")}
              </span>

              <div className="height-8"></div>

              <div className="d-flex gap-2 flex-wrap">
                {guestsList.map((guest, key) => (
                  <Button
                    key={key}
                    text={guest}
                    size="sm"
                    selected={choosenGuests.includes(guest)}
                    onClick={() => {
                      if (choosenGuests.includes(guest))
                        setChoosenGuests((arr) => {
                          const arr2 = arr.filter((e) => e !== guest);
                          return arr2;
                        });
                      else setChoosenGuests([...choosenGuests, guest]);
                    }}
                    variant="secondary"
                  />
                ))}
              </div>
            </div>

            <div className="height-30"></div>
            <Form.Group className="mb-3">
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.7")}
              </Form.Label>
              <Form.Select
                value={request.howMuch}
                onChange={(e) =>
                  setRequest({ ...request, howMuch: e.target.value })
                }
              >
                <option value=""></option>
                <option value="0-150">0-150</option>
                <option value="150-300">150-300</option>
                <option value="300-500">300-500</option>
                <option value="500-1000">500-1000</option>
                <option value="1000+">1000+</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.71")}
              </Form.Label>
              <div className="d-flex flex-column flex-md-row gap-3">
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.72")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={howManyAdults}
                    onChange={(e) => setHowManyAdults(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.73")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={request.howManyKids}
                    onChange={(e) => setHowManyKids(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
                <div className="w100">
                  <Form.Label className="bodytext">
                    {t("swipe.askcard.modal.74")}
                  </Form.Label>
                  <Select
                    name="phonePrefix"
                    value={request.howManyInfants}
                    onChange={(e) => setHowManyInfants(e.target.value)}
                    options={peopleList.map((item) => ({
                      value: item,
                      text: item,
                    }))}
                  />
                </div>
              </div>
            </Form.Group>

            <div className="height-30"></div>

            <Form.Group>
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.4")}
              </Form.Label>
              <div className="d-flex flex-column flex-md-row gap-3">
                <DatePicker
                  placeholder="Check in"
                  className="w100"
                  status={request.checkin ? "success" : "normal"}
                  onChange={(e) => {
                    if (e) {
                      setRequest({
                        ...request,
                        checkin: e.toISOString(),
                      });
                    }

                    if (!e)
                      setRequest({
                        ...request,
                        checkin: undefined,
                      });
                  }}
                />
                <DatePicker
                  placeholder="Check out"
                  className="w100"
                  status={request.checkout ? "success" : "normal"}
                  onChange={(e) => {
                    if (e) {
                      setRequest({
                        ...request,
                        checkout: e.toISOString(),
                      });
                    }

                    if (!e)
                      setRequest({
                        ...request,
                        checkout: undefined,
                      });
                  }}
                />
              </div>
            </Form.Group>

            <div className="height-30"></div>

            <Form.Group>
              <Form.Label className="bodytext medium">
                {t("swipe.askcard.modal.5")}
              </Form.Label>
              <Input
                type="text"
                placeholder={t("swipe.askcard.modal.51")}
                name="request"
                value={request.request}
                onChange={(e) => handleInputChange(e, setRequest)}
              />
            </Form.Group>

            <div className="height-15"></div>
          </div>

          <div className="d-flex column justify-content-end gap-2 white-background py-2">
            <Button
              type="button"
              text={t("swipe.askcard.modal.cancel")}
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              disabled={!request.email}
              type="button"
              loading={isLoading}
              text={t("swipe.askcard.modal.send")}
              onClick={() => SendForm()}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

function hideAskCard() {
  localStorage.setItem(ASK_CARD_HIDE_KEY, "true");
}

function isAskCardHidden() {
  return localStorage.getItem(ASK_CARD_HIDE_KEY) ? true : false;
}

function AskCard(props: { selected: boolean }) {
  const { t } = useTranslation();

  const hide = () => {
    hideAskCard();
    refreshSwipePage();
  };

  return (
    <div
      className="d-flex flex-column justify-content-between black-background p-4"
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid white",
        borderRadius: "15px",
        opacity: props.selected ? 1 : 0.4,
        transitionDuration: "0.7s",
      }}
    >
      <div className="d-flex flex-column white-color">
        <span className="h3 medium">{t("swipe.askcard.title")}</span>
        <span className="dark-grey-color">{t("swipe.askcard.text")}</span>
      </div>
      <div>
        <Button
          className="w-100 mb-2"
          text={t("swipe.askcard.tellus")}
          onClick={() =>
            openModal({
              content: <TellUsMoreModal />,
              style: { maxWidth: "700px" },
            })
          }
        />
        <a
          className="dark-grey-color"
          target="_blank"
          rel="noreferrer"
          onClick={hide}
        >
          {t("swipe.dontshowitagain")}
        </a>
      </div>
    </div>
  );
}

interface NftCardWrapperProps extends NftCardProps {
  opacity?: number;
  selected: boolean;
}

function NftCardWrapper({ nft, ...props }: NftCardWrapperProps) {
  const user = useSelector(selectUser);

  const [currentOutcome, setCurrentOutcome] = useState(nft._swipeOutcome);

  const filter = useSelector(selectSwipeFilter);

  useEffect(() => {
    if (props.selected)
      fireTagManagerEvent("swipe", {
        item_id: nft._id,
        category: filter.category,
      });
  }, [props.selected]);

  const swipe = async (nft: iNft, outcome: SWIPE_OUTCOME) => {
    setCurrentOutcome(outcome);
    try {
      await SwipeController.swipe({ nftId: nft._id, outcome });
    } catch (error) {
      apiErrorToast(error);
    }
    refreshSwipes();
  };

  const link = String(nft._collectionCensus?.website).includes("http")
    ? String(nft._collectionCensus?.website)
    : "http://" + String(nft._collectionCensus?.website);

  return (
    <>
      <a target="_blank" rel="noreferrer" href={link} id={link} hidden></a>
      <NftCard2
        showOwner
        style={{ opacity: props.opacity ?? 1 }}
        partnerMode={nft._fake}
        nft={nft}
        hideDataOpacity={props.hideDataOpacity}
        liked={currentOutcome === SWIPE_OUTCOME.GOOD}
        onClick={() => {
          fireTagManagerEvent("open_card", {
            item_id: nft._id,
            page: "explore",
            match: nft._score,
          });

          if (nft._fake) return;

          props.onClick?.(nft);

          if (props.selected) openNftPage(nft._id);
        }}
        onOffer={() => {
          if (!user) {
            openWallet();
            shakeWallet();
            fireTagManagerEvent("click_offer", {
              item_id: nft._id,
              auth: false,
              side: "front",
              page: "explore",
            });
            return;
          }

          fireTagManagerEvent("click_offer", {
            item_id: nft._id,
            auth: true,
            side: "front",
            page: "explore",
          });

          openNftOffer(nft._id);
        }}
        onBuy={() => {
          if (!user) {
            openWallet();
            shakeWallet();
            fireTagManagerEvent("click_purchase", {
              item_id: nft._id,
              auth: false,
              side: "front",
              page: "explore",
            });
            return;
          }

          fireTagManagerEvent("click_purchase", {
            item_id: nft._id,
            auth: true,
            side: "front",
            page: "explore",
          });

          openNftCheckout(nft._id);
        }}
        onLike={() => {
          if (!user) {
            openWallet();
            shakeWallet();

            fireTagManagerEvent("like", {
              item_id: nft._id,
              auth: false,
              outcome: SWIPE_OUTCOME.GOOD,
              side: "front",
              page: "explore",
            });
            return;
          }

          if (currentOutcome === SWIPE_OUTCOME.GOOD) {
            swipe(nft, SWIPE_OUTCOME.NEUTRAL);
            setCurrentOutcome(SWIPE_OUTCOME.NEUTRAL);
            fireTagManagerEvent("like", {
              item_id: nft._id,
              auth: true,
              outcome: SWIPE_OUTCOME.NEUTRAL,
              side: "front",
              page: "explore",
            });

            return;
          }

          swipe(nft, SWIPE_OUTCOME.GOOD);
          setCurrentOutcome(SWIPE_OUTCOME.GOOD);
          fireTagManagerEvent("like", {
            item_id: nft._id,
            auth: true,
            outcome: SWIPE_OUTCOME.GOOD,
            side: "front",
            page: "explore",
          });
        }}
        onShare={() => {
          fireTagManagerEvent("click_share", {
            item_id: nft._id,
            side: "front",
            auth: user ? true : false,
          });

          openModal({
            content: (
              <Share
                id={nft._id}
                link={getLink(LINK_TYPE.APP_EXPLORE_NFT_SHARE, "API", {
                  __NFTID__: nft._id,
                })}
              />
            ),
          });
        }}
        onPartner={() => {
          try {
            CollectionController.clickOnCollection(String(nft.collectionId), {
              source: "card",
            });
          } catch (error) {}

          document.getElementById(link)?.click();

          fireTagManagerEvent("click_on_book", {
            url: nft._collectionCensus?.website,
          });
        }}
      />
    </>
  );
}

function LoadingCardSkeleton() {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <div className="d-flex flex-column align-items-center pt-3 w100">
      <div
        style={{ opacity: 0.8 }}
        className="d-flex flex-column align-items-center gap-1"
      >
        {/* <div
          className="d-flex align-items-center gap-2"
          style={{ width: `${NFT_WIDE}px` }}
        >
          <Skeleton circle height={50} width={50} />
          <div>
            <Skeleton width={150} height={20} />
            <Skeleton width={200} height={10} />
          </div>
        </div> */}
        <Skeleton
          height={isSmallScreen ? "60dvh" : "70vh"}
          width={NFT_WIDE - 100}
        />
        <div className="d-flex gap-1">
          <Skeleton width={(NFT_WIDE - 100) / 2} height={50} />
          <Skeleton width={(NFT_WIDE - 100) / 2} height={50} />
        </div>
      </div>
    </div>
  );
}

function CarouselMobile({
  nfts,
  isLoading,
}: {
  nfts: SwipingCard[];
  isLoading: boolean;
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselScrollMobile = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentIndex(0);
  }, [isLoading]);

  const onScroll = (e: any, nfts: SwipingCard[], setCurrentIndex: Function) => {
    const scrollTop = e.target.scrollTop;
    const index = Math.round(
      scrollTop / (e.target.offsetHeight - MOBILE_DISTANCE)
    );

    setCurrentIndex(index);

    const id = (nfts[index] as any)._id;
    if (!id) return;

    if (!window.location.href.includes(id)) {
      window.history.replaceState(
        null,
        "",
        getLink(LINK_TYPE.APP_EXPLORE_NFT, undefined, {
          __NFTID__: id,
        })
      );
    }
  };

  const handleScrollCallbackDebounced = useRef(debounce(onScroll, 50));

  return (
    <div
      ref={carouselScrollMobile}
      style={{
        flexGrow: 1,
        overflow: "hidden",
      }}
      className="w100 d-flex"
    >
      {isLoading ? <LoadingCardSkeleton /> : null}

      {!isLoading ? (
        <div
          onScroll={(e) =>
            handleScrollCallbackDebounced.current(e, nfts, setCurrentIndex)
          }
          className="snap-container-column"
          id="swipe-container-mobile"
        >
          {nfts.map((nft, key) => {
            return (
              <div
                className="snap-element-column"
                style={{
                  minHeight: `${
                    Number(carouselScrollMobile.current?.offsetHeight) -
                    MOBILE_DISTANCE
                  }px`,
                  height: `${
                    Number(carouselScrollMobile.current?.offsetHeight) -
                    MOBILE_DISTANCE
                  }px`,
                  maxWidth: `${NFT_WIDE}px`,
                  padding: `${GAP}px ${GAP / 2}px 0 ${GAP / 2}px`,
                  width: "100%",
                }}
                key={key}
              >
                {nft === "telegram" ? (
                  <TelegramCard selected={currentIndex === key} />
                ) : null}

                {nft === "ask" ? (
                  <AskCard selected={currentIndex === key} />
                ) : null}

                {nft !== "ask" && nft !== "telegram" ? (
                  <NftCardWrapper
                    selected={currentIndex === key}
                    hideDataOpacity={currentIndex !== key}
                    nft={nft}
                  />
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

function CarouselDesktop({
  nfts,
  isLoading,
  style,
  onClick,
}: {
  nfts: SwipingCard[];
  isLoading: boolean;
  style: object;
  onClick?(nft: iNft): void;
}) {
  const carouselScrollDesktop = useRef<HTMLDivElement>(null);
  const carouselScrollDesktopSnap = useRef<HTMLDivElement>(null);
  const isOpen = useSelector(selectIsWalletOpen);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lockBtn, setLockBtn] = useState(false);

  const [carouselScrollDesktopW, setCarouselScrollDesktopW] = useState(0);

  const pendingOrders = useSelector(selectPendingOrders);
  const forceUpdate = useForceUpdate();

  useEffect(() => forceUpdate, [pendingOrders]);

  useEffect(() => {
    // workaround to scroll to the start on load
    setTimeout(() => {
      const swipe = document.getElementById("swipe-container-desktop");
      swipe?.scrollTo({
        left: 0,
      });
      setCurrentIndex(0);
    }, 100);
  }, [isLoading]);

  useEffect(() => {
    setCarouselScrollDesktopW(
      Number(carouselScrollDesktop.current?.offsetWidth)
    );
  }, [carouselScrollDesktop.current?.offsetWidth, isOpen]);

  const onScroll = (e: any, nfts: SwipingCard[], setCurrentIndex: Function) => {
    const width = NFT_WIDE;
    const scrollLeft = e.target.scrollLeft;

    const index = Math.round(scrollLeft / width);

    setCurrentIndex(index);

    const id = (nfts[index] as any)?._id;
    if (!id) return;

    if (!window.location.href.includes(id)) {
      window.history.replaceState(
        null,
        "",
        getLink(LINK_TYPE.APP_EXPLORE_NFT, undefined, {
          __NFTID__: id,
        })
      );
    }
  };

  const handleScrollCallbackDebounced = useRef(throttle(onScroll, 50));

  const offsetDiv = (
    <div
      style={{
        width: `${(carouselScrollDesktopW - NFT_WIDE) / 2}px`,
        minWidth: `${(carouselScrollDesktopW - NFT_WIDE) / 2}px`,
      }}
      className="snap-element-row"
    ></div>
  );
  return (
    <div
      ref={carouselScrollDesktop}
      style={{ flexGrow: 1, overflow: "hidden", ...style }}
      className="position-relative"
    >
      {isLoading ? <LoadingCardSkeleton /> : null}

      {!isLoading ? (
        <>
          <div
            ref={carouselScrollDesktopSnap}
            onScroll={(e) =>
              handleScrollCallbackDebounced.current(e, nfts, setCurrentIndex)
            }
            className="snap-container-row"
            id="swipe-container-desktop"
          >
            {offsetDiv}
            {nfts.map((nft, key) => {
              return (
                <div
                  className="snap-element-row"
                  style={{
                    minHeight: `${Number(
                      carouselScrollDesktop.current?.offsetHeight
                    )}px`,
                    height: `${Number(
                      carouselScrollDesktop.current?.offsetHeight
                    )}px`,
                    maxWidth: `${NFT_WIDE}px`,
                    padding: `${GAP}px ${GAP / 2}px`,
                    width: "100%",
                  }}
                  key={key}
                >
                  {nft === "telegram" ? (
                    <TelegramCard selected={currentIndex === key} />
                  ) : null}
                  {nft === "ask" ? (
                    <AskCard selected={currentIndex === key} />
                  ) : null}
                  {nft !== "telegram" && nft !== "ask" ? (
                    <NftCardWrapper
                      selected={currentIndex === key}
                      onClick={() => {
                        const swipe = document.getElementById(
                          "swipe-container-desktop"
                        );
                        if (currentIndex > key)
                          swipe?.scrollTo({
                            left: swipe.scrollLeft - (NFT_WIDE + GAP),
                            behavior: "smooth",
                          });
                        if (currentIndex < key)
                          swipe?.scrollTo({
                            left: swipe.scrollLeft + (NFT_WIDE + GAP),
                            behavior: "smooth",
                          });
                      }}
                      opacity={currentIndex !== key ? 0.4 : 1}
                      hideDataOpacity={currentIndex !== key}
                      nft={nft}
                    />
                  ) : null}
                </div>
              );
            })}
            {offsetDiv}
          </div>

          <div
            style={{
              borderRadius: "100%",
              top: "50%",
              left: "20px",
              zIndex: 50,
              opacity: 0.8,
            }}
            onClick={() => {
              if (lockBtn) return;

              setLockBtn(true);
              setTimeout(() => {
                setLockBtn(false);
              }, 750);

              const swipe = document.getElementById("swipe-container-desktop");
              swipe?.scrollTo({
                left: swipe.scrollLeft - (NFT_WIDE + GAP),
                behavior: "smooth",
              });
            }}
            className="cursor-pointer position-absolute height-45 width-45 white-background d-flex align-items-center justify-content-center p-3"
          >
            <Icon icon="arrow_back" />
          </div>
          <div
            style={{
              borderRadius: "100%",
              top: "50%",
              right: "20px",
              zIndex: 50,
              opacity: 0.8,
            }}
            onClick={() => {
              if (lockBtn) return;

              setLockBtn(true);
              setTimeout(() => {
                setLockBtn(false);
              }, 750);

              const swipe = document.getElementById("swipe-container-desktop");
              swipe?.scrollTo({
                left: swipe.scrollLeft + NFT_WIDE + GAP,
                behavior: "smooth",
              });
            }}
            className="cursor-pointer position-absolute height-45 width-45 white-background d-flex align-items-center justify-content-center p-3"
          >
            <Icon icon="arrow_forward" />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default function Swipes() {
  const { t } = useTranslation();

  const [allNfts, setAllNfts] = useState<SwipingCard[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const filter = useSelector(selectSwipeFilter);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const dispatch = useAppDispatch();

  const [btnSelected, setBtnSelected] = useState(0);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [showArrows, setShowArrows] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const resizeHook = useSelector(selectResizeHook);
  const isWalletOpen = useSelector(selectIsWalletOpen);

  const refreshSwipePageHook = useSelector(selectRefreshSwipePage);

  const [finishLoading, setFinishLoading] = useState(false);

  useEffect(() => {
    const mydata = allNfts.filter((nft) => {
      if (nft === "ask" && isAskCardHidden()) return false;
      if (nft === "telegram" && isTelegramCardHidden()) return false;

      return true;
    });

    setAllNfts(mydata);
  }, [refreshSwipePageHook]);

  useEffect(() => {
    const handle = () => {
      const container = document.getElementById("cat-buttons-container");
      if (!container) return;
      setShowArrows(container.scrollWidth > window.innerWidth - 200);
    };
    handle();
  }, [resizeHook]);

  useEffect(() => {
    const container = document.getElementById("cat-buttons-container");
    if (!container) return;

    container.addEventListener("scroll", (e) => {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft + container.offsetWidth + 5 <=
          container.scrollWidth
      );
    });
  }, []);

  const buttons: {
    icon: string;
    text: string;
    action: Function;
    category: iSwipeCategory | undefined;
  }[] = [
    {
      icon: "bed",
      text: "app.categories.all",
      action: () => {
        const filterCopy = structuredClone(filter);
        delete filterCopy.category;
        dispatch(setSwipeFilter({ ...filterCopy }));
      },
      category: undefined,
    },
    {
      icon: "star",
      text: "app.categories.top",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "top" }));
      },
      category: "top",
    },
    {
      icon: "alarm_on",
      text: "app.categories.lastminute",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "lastminute" }));
      },
      category: "lastminute",
    },
    {
      icon: "sailing",
      text: "app.categories.sea",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "sea" }));
      },
      category: "sea",
    },
    {
      icon: "landscape",
      text: "app.categories.mountain",
      action: () => {
        dispatch(
          setSwipeFilter({
            ...filter,
            category: "mountain",
          })
        );
      },
      category: "mountain",
    },
    {
      icon: "spa",
      text: "app.categories.wellness",
      action: () => {
        dispatch(
          setSwipeFilter({
            ...filter,
            category: "wellness",
          })
        );
      },
      category: "wellness",
    },
    {
      icon: "kayaking",
      text: "app.categories.lake",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "lake" }));
      },
      category: "lake",
    },
    {
      icon: "account_balance",
      text: "app.categories.rome",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "rome" }));
      },
      category: "rome",
    },
    {
      icon: "houseboat",
      text: "app.categories.venice",
      action: () => {
        dispatch(
          setSwipeFilter({
            ...filter,
            category: "venice",
          })
        );
      },
      category: "venice",
    },
    {
      icon: "brush",
      text: "app.categories.florence",
      action: () => {
        dispatch(
          setSwipeFilter({
            ...filter,
            category: "florence",
          })
        );
      },
      category: "florence",
    },
    {
      icon: "apartment",
      text: "app.categories.milan",
      action: () => {
        dispatch(setSwipeFilter({ ...filter, category: "milan" }));
      },
      category: "milan",
    },
    {
      icon: "travel_explore",
      text: "app.categories.hiddencities",
      action: () => {
        dispatch(
          setSwipeFilter({
            ...filter,
            category: "othercities",
          })
        );
      },
      category: "othercities",
    },
  ];

  const load = async (index: number, reset: boolean, firstNftId?: string) => {
    if (index === 0) setIsLoading(true);

    if (index === 0) fireTagManagerEvent("explore", filter);

    try {
      const filterCopy = structuredClone(filter);
      delete filterCopy.category;
      const showMatch = Object.keys(filterCopy).length > 0;

      const nft = await loadNfts3(
        {
          showMatch,
          filter,
          firstNftId,
          includePartners:
            filter.category !== "lastminute" && filter.category !== "top",
          callback: () => {
            if (index === 0 || index === 1) {
              setTimeout(() => {
                document
                  .getElementById("swipe-container-desktop")
                  ?.scrollTo(0, 0);

                document
                  .getElementById("swipe-container-mobile")
                  ?.scrollTo(0, 0);
              }, 200);
            }
          },
        },
        index
      );

      let mydata = [];
      if (reset && index === 0) mydata = [nft];
      else mydata = [...allNfts, nft];

      if (index !== 0) {
        if (
          !filter.category ||
          (filter.category !== "top" && filter.category !== "lastminute")
        ) {
          if (index % 16 === 0 && !isTelegramCardHidden())
            mydata.push("telegram");
          if ((index + 8) % 16 === 0 && !isAskCardHidden()) mydata.push("ask");
        }
      }

      setAllNfts(mydata);

      if (index === 0)
        window.history.replaceState(
          null,
          "",
          getLink(LINK_TYPE.APP_EXPLORE_NFT, undefined, {
            __NFTID__: nft._id,
          })
        );
    } catch (error) {
      console.log(error);
      setFinishLoading(true);

      if (filter.category === "top" || filter.category === "lastminute") {
        if (!allNfts.includes("telegram") && !isTelegramCardHidden())
          setAllNfts([...allNfts, "telegram"]);
      }
    }

    if (index === 0)
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
  };

  useEffect(() => {
    if (isFirstLoad) {
      dispatch(setSwipeFilter({}));
      setIsFirstLoad(false);

      const container = document.getElementById("cat-buttons-container");
      if (container) container.scrollLeft = 0;
    }
  }, []);

  useEffect(() => {
    load(allNfts.length, false, id);
  }, [allNfts]);

  useEffect(() => {
    if (!isFirstLoad) {
      load(0, true, id);
    }
  }, [filter]);

  useEffect(() => {
    const params = new URL(window.location.toString()).searchParams;
    const shareBooking = params.get("shareBooking");
    if (shareBooking && id) openNftPage(id);
  }, []);

  return (
    <section className="w100 h100 d-flex flex-column">
      <div
        style={isWalletOpen ? { maxWidth: "calc(100vw - 500px)" } : {}}
        className="w100 d-flex align-items-center px-2 px-md-4 gap-3 pb-2 pt-1"
      >
        <Filters
          onOpen={() => dispatch(setIsFilterOpen(true))}
          onClose={() => dispatch(setIsFilterOpen(false))}
          dark
        />
        {showArrows ? (
          <Button
            style={{ opacity: showLeftArrow ? 1 : 0 }}
            onClick={() => {
              const container = document.getElementById(
                "cat-buttons-container"
              );
              container?.scrollBy({ left: -200, behavior: "smooth" });
            }}
            icon="arrow_left_alt"
            variant="blank"
            size="sm"
            className="white-color only-desktop"
          />
        ) : null}

        <div
          id="cat-buttons-container"
          className="d-flex gap-md-2 w100"
          style={{
            overflowX: "scroll",
          }}
        >
          {buttons.map((btn, key) => (
            <Button
              disabled={isLoading}
              style={
                isSmallScreen
                  ? { transform: "scale(0.9)", marginRight: "-5px" }
                  : {}
              }
              size="sm"
              key={"btn_" + key}
              selected={key === btnSelected}
              chip
              variant="blank-light"
              text={t(btn.text)}
              icon={btn.icon}
              onClick={() => {
                setBtnSelected(key);
                btn.action();

                fireTagManagerEvent("click_category", {
                  category: btn.category,
                });
              }}
            />
          ))}
        </div>

        {showArrows ? (
          <Button
            style={{ opacity: showRightArrow ? 1 : 0 }}
            size="sm"
            icon="arrow_right_alt"
            variant="blank"
            className="white-color only-desktop"
            onClick={() => {
              const container = document.getElementById(
                "cat-buttons-container"
              );
              container?.scrollBy({ left: 200, behavior: "smooth" });
            }}
          />
        ) : null}
      </div>

      {isSmallScreen ? (
        <CarouselMobile isLoading={isLoading} nfts={allNfts} />
      ) : (
        <CarouselDesktop
          style={isWalletOpen ? { maxWidth: "calc(100vw - 500px)" } : {}}
          isLoading={isLoading}
          nfts={allNfts}
        />
      )}
    </section>
  );
}
