import { useNavigate } from "react-router-dom";
import mallorcaBG from "../../../assets/img/mallorca/mallorca_bg.png";
import takImg from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";
import hotelmssngp from "../../../assets/img/mallorca/hotel_mssngp.png";
import br from "../../../assets/img/mallorca/br.png";
import quicktext from "../../../assets/img/mallorca/quicktext.png";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import Animate from "../../components/Animate/Animate";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";

const agenda = [
  { ora: "11:45 - 12:00", testo: "Registro de asistentes" },
  {
    ora: "12:00 - 12:15",
    testo:
      "Bienvenida por parte de <span class='fw-medium'>Jaume Monserrat</span> - Presidente de Turistec",
  },
  {
    ora: "12:15 - 12:30",
    testo:
      'Ponencia de <span class="fw-medium">Quicktext:</span> "Navegando el Futuro: IA y Estrategias de Datos para Hoteles en Mallorca"',
  },
  {
    ora: "12:30 - 12:45",
    testo:
      "Ponencia de <span class='fw-medium'>Hotel Mssngr:</span> “Optimización de la Comunicación hotel-huésped a través de la tecnología”",
  },
  {
    ora: "12:45 - 13:00",
    testo:
      'Ponencia de <span class="fw-medium">Takyon:</span> "Evolución hacía la Web 3 y caso de uso"',
  },
  {
    ora: "13:00 - 13:30",
    testo:
      'Dinámica de Grupo: "Ideas sobre ¿Cómo será la Experiencia del Cliente del Futuro?"',
  },
  { ora: "13:30 - 15:30", testo: "Networking y Almuerzo" },
];

const link = "https://share-eu1.hsforms.com/1fxBsjyz3Q7SkQpYigBgP0Qg4jt5";

export default function LandingMallorca() {
  const navigate = useNavigate();
  return (
    <main id="web-page">
      <a hidden href={link} target="_blank" id="landing-mallorca-link"></a>
      <section
        style={{
          height: "100dvh",
          minHeight: "800px",
          backgroundImage: `url(${mallorcaBG})`,
          backgroundSize: "cover",
          backgroundPosition: "center cetnter",
        }}
        className="web-section-container w100 light-grey-background justify-content-center position-relative"
      >
        <Animate
          automatic
          element={
            <div
              style={{ zIndex: 2 }}
              className="h100 web-section-content px-3"
            >
              <div className="h100 d-flex flex-column gap-1 gamp-md-4 gap-md-1 align-items-center justify-content-center">
                <img
                  className="only-mobile"
                  style={{ width: "10%", minWidth: "100px" }}
                  src={takImg}
                  alt=""
                />
                <div className="p-2 d-flex align-items-center justify-content-center">
                  <img
                    className="only-desktop"
                    style={{ width: "10%", minWidth: "100px" }}
                    src={takImg}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4 only-desktop"></div>
                  <img
                    style={{ width: "12%", minWidth: "100px" }}
                    src={quicktext}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4"></div>
                  <img
                    style={{ width: "12%", minWidth: "100px" }}
                    src={hotelmssngp}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4"></div>
                  <img
                    style={{ width: "5%", minWidth: "50px" }}
                    src={br}
                    alt=""
                  />
                </div>
                <div style={{ height: "30px" }} className="only-mobile"></div>
                <div style={{ height: "80px" }} className="only-desktop"></div>
                <span
                  className="bodytext-lg regular text-center white-color"
                  style={{ marginBottom: "" }}
                >
                  Mallorca, 28 de Mayo
                </span>
                <h1
                  className="h1-large regular text-center white-color"
                  style={{ marginBottom: "48px" }}
                >
                  Innovación en Hospitalidad:
                  <br />
                  Transformando la Experiencia del Cliente
                </h1>
                <Button
                  text="Inscribete al evento"
                  onClick={() =>
                    document.getElementById("landing-mallorca-link")?.click()
                  }
                ></Button>
              </div>
            </div>
          }
        />
      </section>
      <section
        style={{ minHeight: "100vh" }}
        className="web-section-container w100"
      >
        <div style={{ height: "130 px" }}></div>

        <div className="h100 web-section-content">
          <Animate
            element={
              <div>
                <div style={{ height: "80px" }} className="only-mobile"></div>
                <div style={{ height: "120px" }} className="only-desktop"></div>
                <div className="border rounded p-3 mb-5">
                  <div className="d-flex align-items-center">
                    <div className="width-50">
                      <Icon size={30} icon="event" />
                    </div>
                    <h2 className="bodytext-lg medium mb-1 text-center text-md-left">
                      28 de Mayo, 2024
                    </h2>
                  </div>
                  <br />
                  <div className="d-flex align-items-center">
                    <div className="width-50">
                      <Icon size={30} icon="pin_drop" />
                    </div>
                    <h2 className="bodytext-lg medium mb-1 text-center text-md-left">
                      PORTIXOL HOTEL Y RESTAURANTE <br /> Calle Sirena | 07006
                      Palma de Mallorca | Mallorca, España
                    </h2>
                  </div>
                </div>
                <p className="text-center text-md-left">
                  Durante esta jornada, se hablará sobre cómo la innovación está
                  transformando la forma en que interactuamos con nuestros
                  clientes. Desde la optimización de la comunicación hotelera
                  hasta la automatización de la interacción con el cliente,
                  pasando por la evolución hacia la Web 3.0, se explorarán
                  diversas perspectivas y casos de estudio que nos ayudarán a
                  comprender mejor cómo adaptarnos y prosperar en este nuevo
                  panorama digital.
                </p>
                <div className="height-40"></div>
                <h2 className="medium mb-4 text-center text-md-left">Agenda</h2>
                {agenda.map((item, index) => (
                  <div
                    key={index}
                    className={
                      index !== agenda.length - 1 ? "row border-bottom" : "row"
                    }
                  >
                    <div className="col-12 col-md-4 py-3 py-md-4 text-center text-md-left">
                      <h4 className="medium">{item.ora}</h4>
                    </div>
                    <div className="col-12 col-md-8 pb-3 py-md-4 text-center text-md-left">
                      <div dangerouslySetInnerHTML={{ __html: item.testo }} />
                    </div>
                  </div>
                ))}
                <div style={{ height: "70px" }} className="only-mobile"></div>
                <div style={{ height: "100px" }} className="only-desktop"></div>
                <p className="text-center text-md-left">
                  Evento moderado por:{" "}
                  <span className="fw-semibold">
                    Ana Alonso, CRO de BR Group
                  </span>
                </p>
                <h4 className="text-center text-md-left mt-4">
                  ¡No te lo puedes perder!
                  <br />
                  Inscribete al evento, plazas limitadas, en el siguiente link
                </h4>
                <div className="d-flex justify-content-center justify-content-md-start mt-5 mt-md-4">
                  <Button
                    onClick={() =>
                      document.getElementById("landing-mallorca-link")?.click()
                    }
                    text="Inscribete al evento"
                  ></Button>
                </div>
                <div style={{ height: "80px" }} className="only-mobile"></div>
                <div style={{ height: "120px" }} className="only-desktop"></div>
              </div>
            }
          />
        </div>
      </section>
    </main>
  );
}
