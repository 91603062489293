import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import {
  iWallConfig,
  iWallConfigObject,
  iWallSlot,
  WALL_SLOT_TYPE,
} from "../../../config/wall";
import TakContainer from "../../../assets/TakyonDesignSystem/components/TakContainer/TakContainer";
import LastMinute from "../../../assets/TakyonDesignSystem/components/LastMinute/LastMinute";
import Promo from "../../../assets/TakyonDesignSystem/components/Promo/Promo";
import Partner from "../../../assets/TakyonDesignSystem/components/Partner/PartnerContainer";
import Offer from "../../../assets/TakyonDesignSystem/components/Offer/Offer";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StayRequest from "../../../assets/TakyonDesignSystem/components/StayRequest/StayRequest";
import { useSelector } from "react-redux";
import { selectHeroVisibility } from "../../../redux/slices/appSlice";

export default function Wall(props: {
  menuCategories: iWallConfig;
  category: iWallSlot[];
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [showArrows, setShowArrows] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const { pathname } = useLocation();
  const currentRoute = pathname.slice(1);
  const isHeroVisible = useSelector(selectHeroVisibility);
  const buttonsContainer = useRef<HTMLDivElement>(null);

  const onButtonClick = (area: any, key: any) => {
    if (area === "all") {
      navigate("/");
    } else {
      navigate("/" + area);
    }
  };

  useEffect(() => {
    const container = document.getElementById("cat-buttons-container");
    if (!container) return;

    container.addEventListener("scroll", (e) => {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft + container.offsetWidth + 5 <=
          container.scrollWidth
      );
    });
  }, []);

  useEffect(() => {
    const run = () => {
      if (!buttonsContainer.current) return;

      setShowArrows(
        buttonsContainer.current.scrollWidth >
          buttonsContainer.current.clientWidth
      );
    };

    setInterval(() => {
      run();
    }, 150);
  }, []);

  return (
    <main style={{ marginTop: "55px" }}>
      <section className="w-100 black-background position-relative">
        {/* top menu */}
        <div
          className="w100 position-fixed black-background"
          style={{ zIndex: 199 }}
        >
          <div className="container w100 mx-auto d-flex align-items-center gap-3 pb-2 pt-3 margin-menu">
            {showArrows && showLeftArrow ? (
              <Button
                style={{ opacity: showLeftArrow ? 1 : 0 }}
                onClick={() => {
                  const container = document.getElementById(
                    "cat-buttons-container"
                  );
                  container?.scrollBy({ left: -200, behavior: "smooth" });
                }}
                icon="arrow_left_alt"
                variant="blank"
                size="sm"
                className="white-color "
              />
            ) : null}
            <div
              id="cat-buttons-container"
              className="d-flex gap-md-2 w100"
              style={{
                overflowX: "scroll",
              }}
              ref={buttonsContainer}
            >
              {props.menuCategories.map((btn: iWallConfigObject, key) => (
                <React.Fragment key={"btn_" + key}>
                  <Button
                    style={{
                      ...(isSmallScreen && {
                        transform: "scale(0.9)",
                        marginRight: "-5px",
                      }),
                    }}
                    size="sm"
                    chip
                    variant="blank"
                    selected={
                      currentRoute === btn.area ||
                      (currentRoute === "" && btn.area === "all")
                    }
                    text={t(btn.categoryName)}
                    icon={btn.icon}
                    onClick={() => {
                      onButtonClick(btn.area, key);
                    }}
                  />
                  {btn.separator && <div className="v-separator"></div>}
                </React.Fragment>
              ))}
            </div>

            {showArrows ? (
              <Button
                style={{ opacity: showRightArrow ? 1 : 0 }}
                size="sm"
                icon="arrow_right_alt"
                variant="blank"
                className="white-color "
                onClick={() => {
                  const container = document.getElementById(
                    "cat-buttons-container"
                  );
                  container?.scrollBy({ left: 200, behavior: "smooth" });
                }}
              />
            ) : null}
          </div>
        </div>
        {props.category ? (
          <div
            className="container d-flex flex-column"
            style={{ paddingTop: "50px" }}
          >
            {props.category.map((slot: iWallSlot, key) => {
              switch (slot.config.type) {
                case WALL_SLOT_TYPE.CLASSIC:
                  return <TakContainer key={`tak_${key}`} slot={slot} />;
                case WALL_SLOT_TYPE.LAST_MINUTE:
                  return (
                    <LastMinute key={`last_${key}`} slot={slot}></LastMinute>
                  );
                case WALL_SLOT_TYPE.PROMO:
                  return <Promo key={`promo_${key}`} slot={slot}></Promo>;
                case WALL_SLOT_TYPE.PARTNER:
                  return <Partner key={`partner_${key}`} slot={slot}></Partner>;
                case WALL_SLOT_TYPE.OFFER:
                  return <Offer key={`offer_${key}`} slot={slot}></Offer>;
                case WALL_SLOT_TYPE.STAY_REQUEST:
                  return <StayRequest slot={slot} />;
              }
            })}
          </div>
        ) : null}
      </section>
    </main>
  );
}
