import { t } from "i18next";
import Icon from "../Icon/Icon";
import Stars from "../Stars/Stars";
import iCollection from "../../../models/iCollection";
import { openModal } from "../../../Utils/modal";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../../../config/Lang";
import { Key, useState } from "react";
import useMediaQuery from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";
import {
  getCityFromLocation,
  getCityShortFromLocation,
  getRegionFromLocation,
  getCountryFromLocation,
} from "../../../Utils/location";

interface ChevronButtonProps {
  direction: "left" | "right";
  onClick: Function;
}

function ChevronButton({ direction, onClick }: ChevronButtonProps) {
  return (
    <div
      className="height-40 width-40 d-flex align-items-center justify-content-center m-3 cursor-pointer"
      style={{
        border: "1px solid white",
        borderRadius: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
      onClick={() => onClick()}
    >
      <Icon className="white-color" icon={`chevron_${direction}`} size={30} />
    </div>
  );
}

interface Props {
  partnerDatas: iCollection;
}

function PartnerDetail(props: { datas: iCollection }) {
  const collection: iCollection = props.datas;

  const { i18n } = useTranslation();
  let description = "";
  let services = [];

  const [imgIndex, setImgIndex] = useState(0);

  if (!collection || !collection.census) return null;

  try {
    if (
      collection.census.info &&
      (collection.census?.info as any)[i18n.language]
    ) {
      description = (collection.census?.info as any)[i18n.language].description;
    } else {
      description = (collection.census.info as any)[DEFAULT_LANGUAGE]
        .description;
    }
  } catch (error) {
    console.log("Error getting description", error);
  }

  try {
    if (
      collection.census.info &&
      (collection.census?.info as any)[i18n.language]
    ) {
      services = (collection.census?.info as any)[i18n.language].services;
    } else {
      services = (collection.census.info as any)[DEFAULT_LANGUAGE].services;
    }
  } catch (error) {
    console.log("Error getting description", error);
  }

  return (
    <>
      <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
        <span className="bodytext medium">{collection.census.name}</span>
      </div>
      <div className="modal-body">
        <div className="height-14"></div>
        {collection.images ? (
          <div
            className="w100 position-relative"
            style={{
              objectFit: "cover",
              minWidth: "100%",
              height: "300px",
              backgroundImage: `url(${collection.images[imgIndex]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{ top: "40%", zIndex: 3 }}
              className="d-flex justify-content-between position-absolute w100"
            >
              <ChevronButton
                direction="left"
                onClick={() => {
                  setImgIndex(Math.max(0, imgIndex - 1));
                }}
              />
              <ChevronButton
                direction="right"
                onClick={() => {
                  setImgIndex(
                    Math.min(collection.images.length - 1, imgIndex + 1)
                  );
                }}
              />
            </div>
          </div>
        ) : null}

        {description ? (
          <div className="p-4 border-bottom">
            <p className="bodytext-lg medium black-color">
              {t("wall.partner.description")}
            </p>
            <p className="bodytext regular dark-grey-color">{description}</p>
          </div>
        ) : null}
        {services && services.length > 0 ? (
          <>
            <div className="p-4 border-bottom">
              <span className="bodytext-lg medium black-color">
                {t("app.nft.hotelservice")}
              </span>
              <div className="height-8"></div>

              <div className="d-flex flex-wrap bodytext regular dark-grey-color">
                {services?.map((service: any, key: Key | null | undefined) => {
                  return (
                    <div
                      key={key}
                      style={{ width: "50%" }}
                      className="d-flex gap-1 my-1"
                    >
                      <Icon fill icon={service.icon} />
                      <span>{service.value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : null}

        {collection.census?.location?.label ? (
          <>
            <div className="p-4 pb-5">
              <p className="bodytext-lg medium black-color">
                {t("wall.partner.position")}
              </p>
              <p className="bodytext regular dark-grey-color">
                {String(collection.census?.location?.label)}
              </p>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default function Partner(props: {
  partnerDatas: iCollection;
  month?: string;
}) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <div className="panel partner d-flex flex-row w-100 align-items-center mb-3">
      {props.partnerDatas?.images?.length > 0 ? (
        <div
          className="picture cursor-pointer"
          style={{ backgroundImage: `url(${props.partnerDatas?.images[0]})` }}
          onClick={(e) => {
            e.stopPropagation();
            openModal({
              content: <PartnerDetail datas={props.partnerDatas} />,
            });
          }}
        />
      ) : (
        <div className="picture" />
      )}
      <div className="w-100 d-flex flex-column flex-md-row align-items-md-center">
        <div className="d-flex col-12 col-md-8 col-lg-8 col-xxl-10 flex-column flex-lg-row align-items-lg-center">
          <div
            className={`col-10 col-md-6 position-relative ${
              props.month ? "col-xxl-4" : "col-xxl-5"
            }`}
          >
            <p
              className="mb-0 bodytext regular linktext cursor-pointer text-nowrap text-ellipsis"
              onClick={(e) => {
                e.stopPropagation();
                openModal({
                  content: <PartnerDetail datas={props.partnerDatas} />,
                });
              }}
            >
              {props.partnerDatas?.census?.name}
            </p>
            <Stars stars={props.partnerDatas?.census?.stars || 0} />
          </div>
          <div
            className={`col-10 col-md-5 col-lg-7 d-flex flex-column flex-xxl-row align-items-xxl-center justify-content-xxl-between gap-xxl-5 position-relative col-xxl-6 `}
          >
            <div
              className="d-flex align-items-center gap-1"
              style={{
                minWidth: isSmallScreen ? "initial" : "250px",
                maxWidth: isSmallScreen ? "90%" : "250px",
              }}
            >
              <Icon icon={"location_on"}></Icon>
              <p
                className="mb-0 bodytext light text-nowrap text-ellipsis"
                title={
                  props.partnerDatas?.census?.location?.value
                    ?.structured_formatting?.secondary_text
                }
              >
                {getCityFromLocation(props.partnerDatas?.census?.location)} (
                {getCityShortFromLocation(props.partnerDatas?.census?.location)}
                , {getRegionFromLocation(props.partnerDatas?.census?.location)},{" "}
                {getCountryFromLocation(props.partnerDatas?.census?.location)})
              </p>
            </div>

            <p className="mb-0 bodytext light text-nowrap text-ellipsis">
              {props.month && (
                <div className="d-flex align-items-center gap-1 align-items-lg-center mt-2 mt-xxl-0">
                  {/* <Icon icon={"calendar_today"}></Icon> */}
                  <p className="mb-0 bodytext light text-nowrap text-ellipsis">
                    {props.month}
                  </p>
                </div>
              )}
              {props.partnerDatas?.averagePrice && (
                <>
                  <span className="bodytext medium">
                    € {Number(props.partnerDatas?.averagePrice).toFixed(2)}
                  </span>{" "}
                  ({t("wall.partner.averageprice")})
                </>
              )}
            </p>
          </div>
        </div>
        <div className="d-flex col-12 col-md-4 col-xxl-2 justify-content-md-end">
          <Icon
            style={{ marginRight: "10px" }}
            className="primary-color"
            icon="arrow_outward"
            type="symbol"
          />
          <a
            className="mb-0 linktext text-nowrap primary-color"
            href={
              props.partnerDatas?.census?.website?.includes("http")
                ? props.partnerDatas?.census?.website
                : "https://" + props.partnerDatas?.census?.website
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("wall.partner.shoponweb")}
          </a>
        </div>
      </div>
    </div>
  );
}
